import { Route, Redirect } from 'react-router-dom'

import useAppPusher from 'utils/customHooks/useAppPusher'
import { datadogLogs } from 'utils/helpers/dataDogLog'
import FreezePage from 'components/organisms/FreezePage'
import { useAppSelector, shallowEqual } from 'store'
import {
  AUTH_HO_CS_SHIFT_LEAD,
  AUTH_HO_CS_SPV,
  AUTH_HO_IA_HEAD,
  AUTH_HO_IA_MANAGER,
  AUTH_HO_IA_SPV,
  AUTH_HO_IA_STAFF,
  AUTH_WH_OUTBOUND_ADMIN,
  AUTH_WH_OUTBOUND_PICKER,
  AUTH_WH_OUTBOUND_PACKER_CHECKER,
  AUTH_WH_OUTBOUND_LOADER_SHIFT_LEAD,
  AUTH_WH_OUTBOUND_LOADER_STAFF,
  AUTH_WH_INV_ADMIN,
  AUTH_HO_PRICING_HEAD,
  AUTH_HO_PRICING_MANAGER,
  AUTH_HO_PRICING_SPV,
  AUTH_HO_PRICING_STAFF,
  AUTH_ASTRO_GO_MANAGER,
  AUTH_ASTRO_GO_STAFF,
  AUTH_WH_SECURITY,
} from 'constant/roles'

export default function PrivateRoute(props) {
  const { isLogin } = useAppSelector(({ auth: { isLogin } }) => ({ isLogin }), shallowEqual)

  return <Route {...props}>{isLogin ? props.children : <Redirect to="/" />}</Route>
}

export const AUTH_ADMIN = 'AUTH_ADMIN'

export const AUTH_QUALITY_ENGINEERING = 'AUTH_QUALITY_ENGINEERING'
export const AUTH_PRODUCT_MANAGER = 'AUTH_PRODUCT_MANAGER'
export const AUTH_ADMIN_VIEW_ONLY = 'AUTH_ADMIN_VIEW_ONLY'

//WAREHOUSE LIST
export const AUTH_CENTRAL_SUPERVISOR = 'AUTH_CENTRAL_SUPERVISOR'
export const AUTH_INBOUND_STAFF = 'AUTH_INBOUND_STAFF'
export const AUTH_OUTBOUND_STAFF = 'AUTH_OUTBOUND_STAFF'
export const AUTH_CUSTOMER_SERVICE = 'AUTH_CUSTOMER_SERVICE'
export const AUTH_CS_CAPTAIN = 'AUTH_CS_CAPTAIN'
export const AUTH_MERCHANDISING_ADMIN = 'AUTH_MERCHANDISING_ADMIN'
export const AUTH_FINANCE_ADMIN = 'AUTH_FINANCE_ADMIN'
export const AUTH_HO_FINANCE = 'AUTH_HO_FINANCE'
export const AUTH_MARKETING = 'AUTH_MARKETING'
export const AUTH_MERCHANDISING_WAREHOUSE = 'AUTH_MERCHANDISING_WAREHOUSE'
export const AUTH_HO_CS_LEADER = 'AUTH_HO_CS_LEADER'

//HUB LIST
export const AUTH_PICKER = 'AUTH_PICKER'
export const AUTH_PACKER = 'AUTH_PACKER'
export const AUTH_SUPER_PICKER = 'AUTH_SUPER_PICKER'
export const AUTH_SUPER_PACKER = 'AUTH_SUPER_PACKER'
export const AUTH_SUPER_DRIVER = 'AUTH_SUPER_DRIVER'
export const AUTH_CAPTAIN = 'AUTH_CAPTAIN'
export const AUTH_MERCHANDISING_HUB = 'AUTH_MERCHANDISING_HUB'
export const AUTH_DRIVER_COORDINATOR = 'AUTH_DRIVER_COORDINATOR'
export const AUTH_SPV_HUB = 'AUTH_SPV_HUB'

//GLOBAL LIST
export const AUTH_PRICING = 'AUTH_PRICING'
export const AUTH_SUPPLY_CHAIN = 'AUTH_SUPPLY_CHAIN'
export const AUTH_FRAUD = 'AUTH_FRAUD'
export const AUTH_SOURCING = 'AUTH_SOURCING'
export const AUTH_EXPANSION = 'AUTH_EXPANSION'
export const AUTH_INVENTORY_CONTROLLER = 'AUTH_INVENTORY_CONTROLLER'
export const AUTH_SOURCING_MANAGER = 'AUTH_SOURCING_MANAGER'
export const AUTH_PROCUREMENT = 'AUTH_PROCUREMENT'
export const AUTH_QA_FRESH_INBOUND = 'AUTH_QA_FRESH_INBOUND'
export const AUTH_QA_FRESH_OUTBOUND = 'AUTH_QA_FRESH_OUTBOUND'
export const AUTH_FRESH_PROCESSING = 'AUTH_FRESH_PROCESSING'
export const AUTH_LP = 'AUTH_LP'
export const AUTH_SHIFT_LEAD = 'AUTH_SHIFT_LEAD'
export const AUTH_STOCK_OPNAME = 'AUTH_STOCK_OPNAME'
export const AUTH_ADJUSTMENT = 'AUTH_ADJUSTMENT'
export const AUTH_AREA_MANAGER = 'AUTH_AREA_MANAGER'
export const AUTH_STO_VIEW_ONLY = 'AUTH_STO_VIEW_ONLY'
export const AUTH_CENTRAL_MANAGER = 'AUTH_CENTRAL_MANAGER'
export const AUTH_WH_MANAGER = 'AUTH_WH_MANAGER'
export const AUTH_INBOUND_CHECKER = 'AUTH_INBOUND_CHECKER'
export const AUTH_DRIVER = 'AUTH_DRIVER'
export const AUTH_STOCK_COUNT_AUDITOR = 'AUTH_STOCK_COUNT_AUDITOR'
export const AUTH_TL_FLEET = 'AUTH_TL_FLEET'
export const AUTH_ADMIN_FLEET = 'AUTH_ADMIN_FLEET'

export const AUTH_CHECKER = 'AUTH_CHECKER'
export const AUTH_OUTBOUND_CHECKER = 'AUTH_OUTBOUND_CHECKER'
export const AUTH_OUTBOUND_PACKER = 'AUTH_OUTBOUND_PACKER'
export const AUTH_OUTBOUND_PICKER = 'AUTH_OUTBOUND_PICKER'
export const AUTH_HO_CS_REFUND = 'AUTH_HO_CS_REFUND'

export const AUTH_HO_TAX = 'AUTH_HO_TAX'

export const AUTH_HO_HR = 'AUTH_HO_HR'
export const AUTH_CS_AGENT = 'AUTH_HO_CS_AGENT'

export const AUTH_HUB_AREA_MANAGER = 'AUTH_HUB_AREA_MANAGER'
export const AUTH_HUB_AREA_MANAGER_VIEWONLY = 'AUTH_HUB_AREA_MANAGER_VIEWONLY'
export const AUTH_HUB_INV_MANAGER = 'AUTH_HUB_INV_MANAGER'
export const AUTH_HUB_INV_MANAGER_VIEWONLY = 'AUTH_HUB_INV_MANAGER_VIEWONLY'
export const AUTH_HUB_INV_SPV = 'AUTH_HUB_INV_SPV'
export const AUTH_HUB_INV_STAFF = 'AUTH_HUB_INV_STAFF'
export const AUTH_QA_MANAGER = 'AUTH_QA_MANAGER'
export const AUTH_QA_SUPERVISOR = 'AUTH_QA_SUPERVISOR'
export const AUTH_QA_STAFF = 'AUTH_QA_STAFF'
export const AUTH_SUPER_AREA_MANAGER = 'AUTH_SUPER_AREA_MANAGER'
export const AUTH_WH_MANAGER_VIEWONLY = 'AUTH_WH_MANAGER_VIEWONLY'
export const AUTH_WH_INBOUND_SPV = 'AUTH_WH_INBOUND_SPV'
export const AUTH_WH_INBOUND_STAFF = 'AUTH_WH_INBOUND_STAFF'
export const AUTH_WH_OUTBOUND_SPV = 'AUTH_WH_OUTBOUND_SPV'
export const AUTH_WH_INV_MANAGER = 'AUTH_WH_INV_MANAGER'
export const AUTH_WH_INV_MANAGER_VIEWONLY = 'AUTH_WH_INV_MANAGER_VIEWONLY'
export const AUTH_WH_INV_SPV = 'AUTH_WH_INV_SPV'
export const AUTH_WH_INV_STAFF = 'AUTH_WH_INV_STAFF'
export const AUTH_HO_MERCHANDISING = 'AUTH_HO_MERCHANDISING'
export const AUTH_HO_ADJUSTMENT = 'AUTH_HO_ADJUSTMENT'
export const AUTH_HO_STOCK_COUNT_AUDITOR = 'AUTH_HO_STOCK_COUNT_AUDITOR'
export const AUTH_HO_GA = 'AUTH_HO_GA'
export const AUTH_HO_CAMPAIGN = 'AUTH_HO_CAMPAIGN'
export const AUTH_HO_PARTNERSHIP = 'AUTH_HO_PARTNERSHIP'
export const AUTH_HO_ASTRO_KITCHEN_MANAGER = 'AUTH_HO_ASTRO_KITCHEN_MANAGER'
export const AUTH_HO_ASTRO_KITCHEN_STAFF = 'AUTH_HO_ASTRO_KITCHEN_STAFF'
export const AUTH_HO_EXPANSION = 'AUTH_HO_EXPANSION'
export const AUTH_HO_LP = 'AUTH_HO_LP'
export const AUTH_HO_SUPPLY_CHAIN = 'AUTH_HO_SUPPLY_CHAIN'
export const AUTH_HO_PRODUCT_MANAGER = 'AUTH_HO_PRODUCT_MANAGER'
export const AUTH_HO_QUALITY_ENGINEERING = 'AUTH_HO_QUALITY_ENGINEERING'
export const AUTH_HO_OPX_MID_MILE = 'AUTH_HO_OPX_MID_MILE'
export const AUTH_HO_OPS_MID_MILE_ADMIN = 'AUTH_HO_OPS_MID_MILE_ADMIN'
export const AUTH_HO_OPX_HUB = 'AUTH_HO_OPX_HUB'
export const AUTH_HO_OPS_MID_MILE_SPV = 'AUTH_HO_OPS_MID_MILE_SPV'
export const AUTH_HO_FRAUD = 'AUTH_HO_FRAUD'
export const AUTH_HO_PROCUREMENT = 'AUTH_HO_PROCUREMENT'
export const AUTH_HO_ASTRO_KITCHEN_SOURCING = 'AUTH_HO_ASTRO_KITCHEN_SOURCING'
export const AUTH_HO_ASTRO_KITCHEN_SUPPLY_CHAIN = 'AUTH_HO_ASTRO_KITCHEN_SUPPLY_CHAIN'
export const AUTH_HUB_SPV = 'AUTH_HUB_SPV'
export const AUTH_HO_MARKETING = 'AUTH_HO_MARKETING'
export const AUTH_OFFLINE_MARKETING = 'AUTH_OFFLINE_MARKETING'
export const AUTH_SUPER_INBOUND_STAFF = 'AUTH_SUPER_INBOUND_STAFF'
export const AUTH_HUB_INBOUND_STAFF = 'AUTH_HUB_INBOUND_STAFF'
export const AUTH_INTERNAL_SALES = 'AUTH_INTERNAL_SALES'
export const AUTH_HUB_SHIFT_LEAD = 'AUTH_HUB_SHIFT_LEAD'
export const AUTH_SUPER_SPV = 'AUTH_SUPER_SPV'
export const AUTH_SUPER_SHIFT_LEAD = 'AUTH_SUPER_SHIFT_LEAD'
export const AUTH_HO_SOURCING_ADMIN = 'AUTH_HO_SOURCING_ADMIN'
export const AUTH_HO_SOURCING_MANAGERIAL_LEVEL = 'AUTH_HO_SOURCING_MANAGERIAL_LEVEL'
export const AUTH_HR_LEAD = 'AUTH_HR_LEAD'
export const AUTH_HO_OPX_HUB_MANAGER = 'AUTH_HO_OPX_HUB_MANAGER'
export const AUTH_HO_OPX_HUB_SPV = 'AUTH_HO_OPX_HUB_SPV'
export const AUTH_HO_OPX_HUB_STAFF = 'AUTH_HO_OPX_HUB_STAFF'
export const AUTH_HO_OPX_HUB_SHIFT_LEAD = 'AUTH_HO_OPX_HUB_SHIFT_LEAD'
export const AUTH_HO_OPX_WH_MANAGER = 'AUTH_HO_OPX_WH_MANAGER'
export const AUTH_HO_OPX_WH_SPV = 'AUTH_HO_OPX_WH_SPV'
export const AUTH_HO_OPX_WH_STAFF = 'AUTH_HO_OPX_WH_STAFF'
export const AUTH_HO_CATEGORY_HEAD_PL_FOOD = 'AUTH_HO_CATEGORY_HEAD_PL_FOOD'
export const AUTH_HO_CATEGORY_MANAGER_PL_FOOD = 'AUTH_HO_CATEGORY_MANAGER_PL_FOOD'
export const AUTH_HO_CATEGORY_SPV_PL_FOOD = 'AUTH_HO_CATEGORY_SPV_PL_FOOD'
export const AUTH_HO_CATEGORY_STAFF_PL_FOOD = 'AUTH_HO_CATEGORY_STAFF_PL_FOOD'
export const AUTH_HO_CATEGORY_HEAD_PL_NON_FOOD = 'AUTH_HO_CATEGORY_HEAD_PL_NON_FOOD'
export const AUTH_HO_CATEGORY_MANAGER_PL_NON_FOOD = 'AUTH_HO_CATEGORY_MANAGER_PL_NON_FOOD'
export const AUTH_HO_CATEGORY_SPV_PL_NON_FOOD = 'AUTH_HO_CATEGORY_SPV_PL_NON_FOOD'
export const AUTH_HO_CATEGORY_STAFF_PL_NON_FOOD = 'AUTH_HO_CATEGORY_STAFF_PL_NON_FOOD'
export const AUTH_HO_CUSTOMER_GROWTH_CONTROLLER = 'AUTH_HO_CUSTOMER_GROWTH_CONTROLLER'

// Temporary Role sampai AUTH_HUB_AREA_MANAGER selesai di development
export const AUTH_TEMP_MASTER_APPROVAL = 'AUTH_TEMP_MASTER_APPROVAL'

export const AUTH_HO_IT = 'AUTH_HO_IT'
export const AUTH_ADMIN_XDOCK = 'AUTH_ADMIN_XDOCK'

// ASTRO GO ROLES
export const AUTH_ASTRO_GO_SPV = 'AUTH_ASTRO_GO_SPV'
export const AUTH_ASTRO_GO_SHIFT_LEAD = 'AUTH_ASTRO_GO_SHIFT_LEAD'

//AUTH_CENTRAL_SUPERVISOR
export const allowAccess = {
  //DASHBOARD PAGE
  dashboard: [
    //WAREHOUSE
    AUTH_CENTRAL_SUPERVISOR,
    AUTH_INBOUND_STAFF,
    AUTH_OUTBOUND_STAFF,
    AUTH_CUSTOMER_SERVICE,
    AUTH_CS_CAPTAIN,
    AUTH_MERCHANDISING_ADMIN,
    AUTH_FINANCE_ADMIN,
    AUTH_MARKETING,
    AUTH_MERCHANDISING_WAREHOUSE,

    //HUB
    AUTH_PICKER,
    AUTH_PACKER,
    AUTH_SUPER_PICKER,
    AUTH_SUPER_PACKER,
    AUTH_CAPTAIN,
    AUTH_MERCHANDISING_HUB,

    //GLOBAL
    AUTH_SUPPLY_CHAIN,
  ],

  //CATEGORY PAGE
  category_and_position: [
    //HUB
    AUTH_MERCHANDISING_HUB,

    //WAREHOUSE
    AUTH_MERCHANDISING_WAREHOUSE,

    //GLOBAL
    AUTH_SUPPLY_CHAIN,
    AUTH_HO_MERCHANDISING,

    AUTH_ADJUSTMENT,
    AUTH_HO_CAMPAIGN,
    AUTH_HO_PARTNERSHIP,
    AUTH_MARKETING,
    AUTH_HO_MARKETING,
    AUTH_PRODUCT_MANAGER,
    AUTH_HO_PRODUCT_MANAGER,
    AUTH_QUALITY_ENGINEERING,
    AUTH_HO_QUALITY_ENGINEERING,
  ],
  catalogue: [
    //HUB
    AUTH_MERCHANDISING_HUB,

    //WAREHOUSE
    AUTH_MERCHANDISING_WAREHOUSE,

    //GLOBALS
    AUTH_SUPPLY_CHAIN,
    AUTH_HO_MERCHANDISING,
  ],
  category: [
    //HUB
    AUTH_MERCHANDISING_HUB,

    //WAREHOUSE
    AUTH_MERCHANDISING_WAREHOUSE,

    //GLOBALS
    AUTH_SUPPLY_CHAIN,
    AUTH_HO_MERCHANDISING,
  ],
  set_position: [
    //HUB
    AUTH_MERCHANDISING_HUB,

    //WAREHOUSE
    AUTH_MERCHANDISING_WAREHOUSE,

    // GLOBALS
    AUTH_HO_MERCHANDISING,
  ],
  dynamic_channel: [
    //HUB
    AUTH_MERCHANDISING_HUB,

    //WAREHOUSE
    AUTH_MERCHANDISING_WAREHOUSE,

    //GLOBALS
    AUTH_SUPPLY_CHAIN,
    AUTH_HO_MERCHANDISING,
  ],
  //PRODUCT AND STOCK PAGE
  product_and_stock: [
    AUTH_CENTRAL_SUPERVISOR,
    AUTH_MERCHANDISING_ADMIN,
    AUTH_MERCHANDISING_WAREHOUSE,

    //HUB
    AUTH_CAPTAIN,
    AUTH_MERCHANDISING_HUB,

    //GLOBALS
    AUTH_PRICING,
    AUTH_SUPPLY_CHAIN,

    AUTH_CS_CAPTAIN,
    AUTH_CUSTOMER_SERVICE,
    AUTH_INVENTORY_CONTROLLER,
    AUTH_STOCK_COUNT_AUDITOR,
    AUTH_MARKETING,

    AUTH_HUB_AREA_MANAGER,
    AUTH_HUB_AREA_MANAGER_VIEWONLY,
    AUTH_HUB_INV_MANAGER,
    AUTH_HUB_INV_MANAGER_VIEWONLY,
    AUTH_HUB_INV_SPV,
    AUTH_HUB_INV_STAFF,
    AUTH_QA_MANAGER,
    AUTH_QA_SUPERVISOR,
    AUTH_QA_STAFF,
    AUTH_SUPER_AREA_MANAGER,
    AUTH_WH_MANAGER,
    AUTH_WH_MANAGER_VIEWONLY,
    AUTH_WH_INBOUND_SPV,
    AUTH_WH_INBOUND_STAFF,
    AUTH_WH_OUTBOUND_SPV,
    AUTH_WH_INV_MANAGER,
    AUTH_WH_INV_MANAGER_VIEWONLY,
    AUTH_WH_INV_SPV,
    AUTH_WH_INV_STAFF,
    AUTH_HO_MERCHANDISING,
    AUTH_HO_FINANCE,
    AUTH_HO_TAX,
    AUTH_HO_CS_LEADER,
    AUTH_CS_AGENT,
    AUTH_HO_ADJUSTMENT,
    AUTH_HO_STOCK_COUNT_AUDITOR,
    AUTH_HO_GA,
    AUTH_HO_EXPANSION,
    AUTH_HO_LP,
    AUTH_HO_CAMPAIGN,
    AUTH_HO_PARTNERSHIP,
    AUTH_HO_ASTRO_KITCHEN_MANAGER,
    AUTH_HO_ASTRO_KITCHEN_STAFF,
    AUTH_HO_PRODUCT_MANAGER,
    AUTH_HO_QUALITY_ENGINEERING,
    AUTH_HO_SUPPLY_CHAIN,
    AUTH_HO_SOURCING_MANAGERIAL_LEVEL,

    // Old Role
    AUTH_EXPANSION,
    AUTH_LP,
    AUTH_INBOUND_STAFF,
    AUTH_OUTBOUND_STAFF,
    AUTH_QA_FRESH_INBOUND,
    AUTH_QA_FRESH_OUTBOUND,
    AUTH_ADJUSTMENT,
    AUTH_SOURCING,
    AUTH_SOURCING_MANAGER,
    AUTH_PROCUREMENT,
  ],
  product: [
    //WAREHOUSE
    AUTH_CENTRAL_SUPERVISOR,
    AUTH_MERCHANDISING_ADMIN,
    AUTH_MERCHANDISING_WAREHOUSE,

    //HUB
    AUTH_CAPTAIN,
    AUTH_MERCHANDISING_HUB,

    //GLOBAL
    AUTH_PRICING,
    AUTH_SUPPLY_CHAIN,

    AUTH_CS_CAPTAIN,
    AUTH_CUSTOMER_SERVICE,
    AUTH_STOCK_COUNT_AUDITOR,
    AUTH_MARKETING,
    AUTH_HO_TAX,
    AUTH_HO_CS_LEADER,
    AUTH_CS_AGENT,
    AUTH_HO_MERCHANDISING,
    AUTH_HO_FINANCE,
    AUTH_HO_LP,
  ],
  hub: [
    //WAREHOUSE
    AUTH_CENTRAL_SUPERVISOR,
    AUTH_MERCHANDISING_ADMIN,
    AUTH_HO_MERCHANDISING,

    //HUB
    AUTH_MERCHANDISING_HUB,

    //GLOBAL
    AUTH_PRICING,
    AUTH_SUPPLY_CHAIN,

    AUTH_CS_CAPTAIN,
    AUTH_CUSTOMER_SERVICE,
    AUTH_INVENTORY_CONTROLLER,
    AUTH_STOCK_COUNT_AUDITOR,

    AUTH_HUB_AREA_MANAGER,
    AUTH_HUB_AREA_MANAGER_VIEWONLY,
    AUTH_HUB_INV_MANAGER,
    AUTH_HUB_INV_MANAGER_VIEWONLY,
    AUTH_HUB_INV_SPV,
    AUTH_HUB_INV_STAFF,
    AUTH_QA_MANAGER,
    AUTH_QA_SUPERVISOR,
    AUTH_QA_STAFF,
    AUTH_SUPER_AREA_MANAGER,
    AUTH_WH_MANAGER,
    AUTH_WH_MANAGER_VIEWONLY,
    AUTH_WH_INBOUND_SPV,
    AUTH_WH_INBOUND_STAFF,
    AUTH_WH_OUTBOUND_SPV,
    AUTH_WH_INV_MANAGER,
    AUTH_WH_INV_MANAGER_VIEWONLY,
    AUTH_WH_INV_SPV,
    AUTH_WH_INV_STAFF,
    AUTH_HO_MERCHANDISING,
    AUTH_HO_FINANCE,
    AUTH_HO_TAX,
    AUTH_HO_ADJUSTMENT,
    AUTH_HO_STOCK_COUNT_AUDITOR,
    AUTH_HO_GA,
    AUTH_HO_EXPANSION,
    AUTH_HO_LP,
    AUTH_HO_CAMPAIGN,
    AUTH_HO_PARTNERSHIP,
    AUTH_HO_ASTRO_KITCHEN_MANAGER,
    AUTH_HO_ASTRO_KITCHEN_STAFF,
    AUTH_HO_PRODUCT_MANAGER,
    AUTH_HO_QUALITY_ENGINEERING,
    AUTH_HO_SUPPLY_CHAIN,

    // Old Role
    AUTH_EXPANSION,
    AUTH_LP,
    AUTH_INBOUND_STAFF,
    AUTH_OUTBOUND_STAFF,
    AUTH_QA_FRESH_INBOUND,
    AUTH_QA_FRESH_OUTBOUND,
    AUTH_ADJUSTMENT,
  ],
  brandMaster: [
    AUTH_MERCHANDISING_ADMIN,
    AUTH_MERCHANDISING_HUB,
    AUTH_MERCHANDISING_WAREHOUSE,
    AUTH_HO_TAX,
    AUTH_HO_MERCHANDISING,
    AUTH_HO_FINANCE,
  ],
  add_product_hub: [
    //HUB
    AUTH_MERCHANDISING_HUB,
    AUTH_CAPTAIN,
    AUTH_INBOUND_STAFF,

    //GLOBALS
    AUTH_SUPPLY_CHAIN,
    AUTH_HO_PRODUCT_MANAGER,
    AUTH_HO_QUALITY_ENGINEERING,
    AUTH_HO_MERCHANDISING,
    AUTH_HO_SUPPLY_CHAIN,

    // Old Role
    AUTH_MERCHANDISING_ADMIN,
  ],
  stock_product_hub: [
    //stock product hub detail - INVENTORY MANAGEMENT

    //WAREHOUSE
    AUTH_CENTRAL_SUPERVISOR,
    AUTH_MERCHANDISING_ADMIN,
    AUTH_HO_MERCHANDISING,

    //HUB
    AUTH_CAPTAIN,
    AUTH_MERCHANDISING_HUB,

    //GLOBAL
    AUTH_SUPPLY_CHAIN,
    AUTH_INVENTORY_CONTROLLER,
    AUTH_STOCK_COUNT_AUDITOR,

    AUTH_HUB_AREA_MANAGER,
    AUTH_HUB_AREA_MANAGER_VIEWONLY,
    AUTH_HUB_INV_MANAGER,
    AUTH_HUB_INV_MANAGER_VIEWONLY,
    AUTH_HUB_INV_SPV,
    AUTH_HUB_INV_STAFF,
    AUTH_QA_MANAGER,
    AUTH_QA_SUPERVISOR,
    AUTH_QA_STAFF,
    AUTH_SUPER_AREA_MANAGER,
    AUTH_WH_MANAGER,
    AUTH_WH_MANAGER_VIEWONLY,
    AUTH_WH_INBOUND_SPV,
    AUTH_WH_INBOUND_STAFF,
    AUTH_WH_OUTBOUND_SPV,
    AUTH_WH_INV_MANAGER,
    AUTH_WH_INV_MANAGER_VIEWONLY,
    AUTH_WH_INV_SPV,
    AUTH_WH_INV_STAFF,
    AUTH_HO_MERCHANDISING,
    AUTH_HO_FINANCE,
    AUTH_HO_TAX,
    AUTH_HO_ADJUSTMENT,
    AUTH_HO_STOCK_COUNT_AUDITOR,
    AUTH_HO_GA,
    AUTH_HO_EXPANSION,
    AUTH_HO_LP,
    AUTH_HO_CAMPAIGN,
    AUTH_HO_PARTNERSHIP,
    AUTH_HO_ASTRO_KITCHEN_MANAGER,
    AUTH_HO_ASTRO_KITCHEN_STAFF,
    AUTH_HO_PRODUCT_MANAGER,
    AUTH_HO_QUALITY_ENGINEERING,
    AUTH_HO_SUPPLY_CHAIN,

    // Old Role
    AUTH_EXPANSION,
    AUTH_LP,
    AUTH_INBOUND_STAFF,
    AUTH_OUTBOUND_STAFF,
    AUTH_QA_FRESH_INBOUND,
    AUTH_QA_FRESH_OUTBOUND,
    AUTH_ADJUSTMENT,
  ],
  warehouse: [
    //WAREHOUSE
    AUTH_CENTRAL_SUPERVISOR,
    AUTH_MERCHANDISING_ADMIN,
    AUTH_MERCHANDISING_WAREHOUSE,

    //HUB
    AUTH_CAPTAIN,

    //GLOBAL
    AUTH_PRICING,
    AUTH_SUPPLY_CHAIN,

    AUTH_CS_CAPTAIN,
    AUTH_CUSTOMER_SERVICE,
    AUTH_INVENTORY_CONTROLLER,
    AUTH_STOCK_COUNT_AUDITOR,

    AUTH_HUB_AREA_MANAGER,
    AUTH_HUB_AREA_MANAGER_VIEWONLY,
    AUTH_HUB_INV_MANAGER,
    AUTH_HUB_INV_MANAGER_VIEWONLY,
    AUTH_HUB_INV_SPV,
    AUTH_HUB_INV_STAFF,
    AUTH_QA_MANAGER,
    AUTH_QA_SUPERVISOR,
    AUTH_QA_STAFF,
    AUTH_SUPER_AREA_MANAGER,
    AUTH_WH_MANAGER,
    AUTH_WH_MANAGER_VIEWONLY,
    AUTH_WH_INBOUND_SPV,
    AUTH_WH_INBOUND_STAFF,
    AUTH_WH_OUTBOUND_SPV,
    AUTH_WH_INV_MANAGER,
    AUTH_WH_INV_MANAGER_VIEWONLY,
    AUTH_WH_INV_SPV,
    AUTH_WH_INV_STAFF,
    AUTH_HO_MERCHANDISING,
    AUTH_HO_FINANCE,
    AUTH_HO_TAX,
    AUTH_HO_ADJUSTMENT,
    AUTH_HO_STOCK_COUNT_AUDITOR,
    AUTH_HO_GA,
    AUTH_HO_EXPANSION,
    AUTH_HO_LP,
    AUTH_HO_CAMPAIGN,
    AUTH_HO_PARTNERSHIP,
    AUTH_HO_ASTRO_KITCHEN_MANAGER,
    AUTH_HO_ASTRO_KITCHEN_STAFF,
    AUTH_HO_PRODUCT_MANAGER,
    AUTH_HO_QUALITY_ENGINEERING,
    AUTH_HO_SUPPLY_CHAIN,

    // Old Role
    AUTH_EXPANSION,
    AUTH_LP,
    AUTH_INBOUND_STAFF,
    AUTH_OUTBOUND_STAFF,
    AUTH_QA_FRESH_INBOUND,
    AUTH_QA_FRESH_OUTBOUND,
    AUTH_ADJUSTMENT,
  ],
  add_product_warehouse: [
    //WAREHOUSE
    AUTH_CENTRAL_SUPERVISOR,
    AUTH_MERCHANDISING_WAREHOUSE,

    //HUB
    AUTH_CAPTAIN,

    //GLOBAL
    AUTH_SUPPLY_CHAIN,
    AUTH_HO_PRODUCT_MANAGER,
    AUTH_HO_QUALITY_ENGINEERING,
    AUTH_WH_INV_SPV,
    AUTH_WH_INV_STAFF,
    AUTH_HO_SUPPLY_CHAIN,

    // Old Role
    AUTH_INVENTORY_CONTROLLER,
    AUTH_HO_MERCHANDISING,
  ],
  stock_product_warehouse: [
    //WAREHOUSE
    AUTH_CENTRAL_SUPERVISOR,
    AUTH_MERCHANDISING_ADMIN,
    AUTH_MERCHANDISING_WAREHOUSE,

    //HUB
    AUTH_CAPTAIN,

    //GLOBAL
    AUTH_SUPPLY_CHAIN,
    AUTH_INVENTORY_CONTROLLER,
    AUTH_STOCK_COUNT_AUDITOR,

    AUTH_HUB_AREA_MANAGER,
    AUTH_HUB_AREA_MANAGER_VIEWONLY,
    AUTH_HUB_INV_MANAGER,
    AUTH_HUB_INV_MANAGER_VIEWONLY,
    AUTH_HUB_INV_SPV,
    AUTH_HUB_INV_STAFF,
    AUTH_QA_MANAGER,
    AUTH_QA_SUPERVISOR,
    AUTH_QA_STAFF,
    AUTH_SUPER_AREA_MANAGER,
    AUTH_WH_MANAGER,
    AUTH_WH_MANAGER_VIEWONLY,
    AUTH_WH_INBOUND_SPV,
    AUTH_WH_INBOUND_STAFF,
    AUTH_WH_OUTBOUND_SPV,
    AUTH_WH_INV_MANAGER,
    AUTH_WH_INV_MANAGER_VIEWONLY,
    AUTH_WH_INV_SPV,
    AUTH_WH_INV_STAFF,
    AUTH_HO_MERCHANDISING,
    AUTH_HO_FINANCE,
    AUTH_HO_TAX,
    AUTH_HO_ADJUSTMENT,
    AUTH_HO_STOCK_COUNT_AUDITOR,
    AUTH_HO_GA,
    AUTH_HO_EXPANSION,
    AUTH_HO_LP,
    AUTH_HO_CAMPAIGN,
    AUTH_HO_PARTNERSHIP,
    AUTH_HO_ASTRO_KITCHEN_MANAGER,
    AUTH_HO_ASTRO_KITCHEN_STAFF,
    AUTH_HO_PRODUCT_MANAGER,
    AUTH_HO_QUALITY_ENGINEERING,
    AUTH_HO_SUPPLY_CHAIN,

    // Old Role
    AUTH_EXPANSION,
    AUTH_LP,
    AUTH_INBOUND_STAFF,
    AUTH_OUTBOUND_STAFF,
    AUTH_QA_FRESH_INBOUND,
    AUTH_QA_FRESH_OUTBOUND,
    AUTH_ADJUSTMENT,
    AUTH_PRODUCT_MANAGER,
  ],
  product_detail_in_searching: [
    AUTH_HUB_AREA_MANAGER,
    AUTH_HUB_AREA_MANAGER_VIEWONLY,
    AUTH_HUB_INV_MANAGER,
    AUTH_HUB_INV_MANAGER_VIEWONLY,
    AUTH_HUB_INV_STAFF,
    AUTH_WH_INV_MANAGER,
    AUTH_WH_INV_MANAGER_VIEWONLY,
    AUTH_WH_INV_STAFF,
    AUTH_HO_STOCK_COUNT_AUDITOR,
    AUTH_HO_SUPPLY_CHAIN,

    // Old Role
    AUTH_CAPTAIN,
    AUTH_INVENTORY_CONTROLLER,
    AUTH_INBOUND_STAFF,
    AUTH_CENTRAL_SUPERVISOR,
    AUTH_STOCK_COUNT_AUDITOR,
    AUTH_SUPPLY_CHAIN,
  ],
  productLabel: [
    AUTH_MERCHANDISING_ADMIN,
    AUTH_HO_MERCHANDISING,
    AUTH_PRODUCT_MANAGER,
    AUTH_HO_PRODUCT_MANAGER,
  ],

  //CAMPAIGN PAGE
  campaign: [AUTH_MARKETING],
  campaignAdd: [
    AUTH_MARKETING,
    AUTH_HO_CAMPAIGN,
    AUTH_HO_PRODUCT_MANAGER,
    AUTH_HO_QUALITY_ENGINEERING,
  ],
  campaignSegment: [AUTH_MARKETING],

  //BANNER & TICKER PAGE
  banner_and_ticker: [
    AUTH_CENTRAL_SUPERVISOR,
    AUTH_CS_CAPTAIN,
    AUTH_MERCHANDISING_ADMIN,
    AUTH_HO_CS_LEADER,
    AUTH_CS_AGENT,
  ],
  banner: [
    AUTH_CS_CAPTAIN,
    AUTH_CUSTOMER_SERVICE,
    AUTH_CENTRAL_SUPERVISOR,
    AUTH_HO_CS_LEADER,
    AUTH_CS_AGENT,
  ],
  ticker: [
    AUTH_CS_CAPTAIN,
    AUTH_CUSTOMER_SERVICE,
    AUTH_MERCHANDISING_ADMIN,
    AUTH_HO_CS_LEADER,
    AUTH_CS_AGENT,
  ],

  //LOCATION PAGE
  location_main: [
    //main sidebar - opening
    AUTH_CENTRAL_SUPERVISOR,
    AUTH_CAPTAIN,

    //GLOBAL
    AUTH_SUPPLY_CHAIN,
  ],
  location: [AUTH_EXPANSION, AUTH_CS_CAPTAIN, AUTH_CUSTOMER_SERVICE, AUTH_HO_OPX_HUB],
  locationPreview: [AUTH_EXPANSION, AUTH_CS_CAPTAIN, AUTH_CUSTOMER_SERVICE],
  service_level: [AUTH_EXPANSION, AUTH_HO_OPX_HUB],

  //AKUN or ACCOUNT or ROLE MANAGEMENT PAGE
  account: [
    AUTH_CAPTAIN,
    AUTH_CS_CAPTAIN,
    AUTH_DRIVER_COORDINATOR,
    AUTH_PACKER,
    AUTH_ADMIN,
    AUTH_CUSTOMER_SERVICE,
    AUTH_MARKETING,
    AUTH_HO_HR,
    AUTH_HO_CS_LEADER,
    AUTH_CS_AGENT,
    // Hub Staff
    AUTH_HUB_SPV,
    AUTH_SPV_HUB,
    // Central Warehouse
    AUTH_WH_MANAGER,
    AUTH_WH_INBOUND_SPV,
    AUTH_WH_OUTBOUND_SPV,
    AUTH_WH_INV_SPV,
    AUTH_HO_IT,
  ],
  admin: [AUTH_HO_IT],
  driver: [
    AUTH_CAPTAIN,
    AUTH_CS_CAPTAIN,
    AUTH_DRIVER_COORDINATOR,
    AUTH_PACKER,
    AUTH_ADMIN,
    AUTH_CUSTOMER_SERVICE,
  ],
  mpBudget: [
    AUTH_HO_OPX_HUB_MANAGER,
    AUTH_HO_OPX_HUB_SPV,
    AUTH_HO_OPX_HUB_STAFF,
    AUTH_HO_OPX_HUB_SHIFT_LEAD,
    AUTH_HO_OPX_WH_MANAGER,
    AUTH_HO_OPX_WH_SPV,
    AUTH_HO_OPX_WH_STAFF,
  ],
  driverHubStaff: [
    AUTH_CAPTAIN,
    AUTH_CS_CAPTAIN,
    AUTH_DRIVER_COORDINATOR,
    AUTH_PACKER,
    AUTH_ADMIN,
    AUTH_CUSTOMER_SERVICE,
    AUTH_HO_HR,
    AUTH_HUB_SPV,
    AUTH_SPV_HUB,
    AUTH_HO_IT,
  ],
  warehouseStaff: [
    AUTH_CAPTAIN,
    AUTH_CS_CAPTAIN,
    AUTH_DRIVER_COORDINATOR,
    AUTH_PACKER,
    AUTH_ADMIN,
    AUTH_CUSTOMER_SERVICE,
    AUTH_HO_HR,
    AUTH_WH_MANAGER,
    AUTH_WH_INBOUND_SPV,
    AUTH_WH_OUTBOUND_SPV,
    AUTH_WH_INV_SPV,
  ],
  mpBulkUpload: [AUTH_CAPTAIN, AUTH_DRIVER_COORDINATOR, AUTH_MARKETING],
  buyer: [AUTH_CUSTOMER_SERVICE, AUTH_MARKETING, AUTH_HO_CS_LEADER, AUTH_CS_AGENT],
  buyerSaldoAstro: [AUTH_CUSTOMER_SERVICE, AUTH_MARKETING, AUTH_HO_CS_LEADER, AUTH_CS_AGENT],
  hubStaffScheduleBulkUpload: [
    AUTH_DRIVER_COORDINATOR,
    AUTH_MARKETING,
    AUTH_HO_HR,
    AUTH_SPV_HUB,

    // Temp role
    AUTH_TEMP_MASTER_APPROVAL,
  ],
  //SUPPLY ORDER PAGE
  supply_order: [
    AUTH_INBOUND_STAFF,
    AUTH_OUTBOUND_STAFF,
    //WAREHOUSE
    AUTH_MERCHANDISING_WAREHOUSE,

    //HUB
    AUTH_CAPTAIN,
    AUTH_MERCHANDISING_HUB,

    //GLOBAL
    AUTH_SUPPLY_CHAIN,
    AUTH_CENTRAL_SUPERVISOR,
    AUTH_HO_MERCHANDISING,
    AUTH_HO_OPX_MID_MILE,
    AUTH_HO_OPS_MID_MILE_SPV,
    AUTH_HO_OPS_MID_MILE_ADMIN,
    AUTH_HO_LP,
  ],
  supply_order_detail: [
    AUTH_INBOUND_STAFF,
    AUTH_OUTBOUND_STAFF,
    //WAREHOUSE
    AUTH_MERCHANDISING_WAREHOUSE,

    //HUB
    AUTH_CAPTAIN,
    AUTH_MERCHANDISING_HUB,

    //GLOBAL
    AUTH_SUPPLY_CHAIN,
    AUTH_CENTRAL_SUPERVISOR,
    AUTH_HO_MERCHANDISING,
    AUTH_HO_OPX_MID_MILE,
    AUTH_HO_OPS_MID_MILE_SPV,
    AUTH_HO_OPS_MID_MILE_ADMIN,
    AUTH_HO_LP,

    AUTH_WH_OUTBOUND_ADMIN,
    AUTH_WH_OUTBOUND_PICKER,
    AUTH_WH_OUTBOUND_PACKER_CHECKER,
    AUTH_WH_OUTBOUND_LOADER_SHIFT_LEAD,
    AUTH_WH_OUTBOUND_LOADER_STAFF,
    AUTH_WH_INV_ADMIN,
  ],
  supply_order_cek_qty: [
    AUTH_INBOUND_STAFF,
    AUTH_OUTBOUND_STAFF,
    //WAREHOUSE
    AUTH_MERCHANDISING_WAREHOUSE,

    //HUB
    AUTH_CAPTAIN,
    AUTH_MERCHANDISING_HUB,

    //GLOBAL
    AUTH_SUPPLY_CHAIN,
    AUTH_CENTRAL_SUPERVISOR,
    AUTH_HO_MERCHANDISING,
    AUTH_HO_OPX_MID_MILE,
    AUTH_HO_OPS_MID_MILE_SPV,
    AUTH_HO_OPS_MID_MILE_ADMIN,
    AUTH_HO_LP,

    AUTH_WH_INV_ADMIN,
    AUTH_WH_OUTBOUND_ADMIN,
    AUTH_WH_OUTBOUND_PACKER_CHECKER,
  ],
  inbound: [
    AUTH_OUTBOUND_STAFF,
    AUTH_MERCHANDISING_ADMIN,
    //WAREHOUSE
    AUTH_MERCHANDISING_WAREHOUSE,

    //HUB
    AUTH_MERCHANDISING_HUB,
    AUTH_CAPTAIN,

    //GLOBAL
    AUTH_SUPPLY_CHAIN,
    AUTH_INVENTORY_CONTROLLER,
    AUTH_LP,
    AUTH_HO_LP,
    AUTH_SPV_HUB,
    AUTH_HO_OPX_MID_MILE,
  ],
  outbound_supply_order: [
    AUTH_OUTBOUND_STAFF,
    AUTH_MERCHANDISING_ADMIN,
    //WAREHOUSE
    AUTH_MERCHANDISING_WAREHOUSE,

    //HUB
    AUTH_CAPTAIN,
    AUTH_MERCHANDISING_HUB,

    //GLOBAL
    AUTH_SUPPLY_CHAIN,

    AUTH_CENTRAL_SUPERVISOR,
    AUTH_SHIFT_LEAD,
    AUTH_INVENTORY_CONTROLLER,
    AUTH_HO_OPX_MID_MILE,
    AUTH_HO_OPS_MID_MILE_ADMIN,
    AUTH_HO_OPS_MID_MILE_SPV,
    AUTH_HO_LP,
    AUTH_WH_OUTBOUND_ADMIN,
    AUTH_WH_OUTBOUND_PICKER,
    AUTH_WH_OUTBOUND_PACKER_CHECKER,
    AUTH_WH_OUTBOUND_LOADER_SHIFT_LEAD,
    AUTH_WH_OUTBOUND_LOADER_STAFF,
    AUTH_WH_INV_ADMIN,

    AUTH_WH_OUTBOUND_SPV,
    AUTH_WH_MANAGER,
    AUTH_WH_INV_MANAGER,
    AUTH_ADMIN_XDOCK,
  ],
  create_supply_order: [
    AUTH_OUTBOUND_STAFF,
    AUTH_MERCHANDISING_ADMIN,
    //WAREHOUSE
    AUTH_MERCHANDISING_WAREHOUSE,

    //HUB
    AUTH_MERCHANDISING_HUB,
    AUTH_CAPTAIN,

    //GLOBAL
    AUTH_SUPPLY_CHAIN,
    AUTH_CENTRAL_SUPERVISOR,
    AUTH_HO_MERCHANDISING,
    AUTH_HO_LP,
    AUTH_WH_OUTBOUND_ADMIN,
    AUTH_WH_OUTBOUND_PICKER,
    AUTH_WH_OUTBOUND_PACKER_CHECKER,
    AUTH_WH_OUTBOUND_LOADER_SHIFT_LEAD,
    AUTH_WH_OUTBOUND_LOADER_STAFF,
    AUTH_WH_INV_ADMIN,
  ],
  supply_order_picking: [
    AUTH_OUTBOUND_STAFF,
    AUTH_MERCHANDISING_ADMIN,
    //WAREHOUSE
    AUTH_MERCHANDISING_WAREHOUSE,

    //HUB
    AUTH_MERCHANDISING_HUB,
    AUTH_CAPTAIN,

    //GLOBAL
    AUTH_SUPPLY_CHAIN,
    AUTH_CENTRAL_SUPERVISOR,
    AUTH_HO_MERCHANDISING,
    AUTH_HO_LP,
  ],
  supply_order_loading: [
    AUTH_OUTBOUND_STAFF,
    AUTH_MERCHANDISING_ADMIN,
    //WAREHOUSE
    AUTH_MERCHANDISING_WAREHOUSE,

    //HUB
    AUTH_MERCHANDISING_HUB,
    AUTH_CAPTAIN,

    //GLOBAL
    AUTH_SUPPLY_CHAIN,
    AUTH_CENTRAL_SUPERVISOR,
    AUTH_HO_MERCHANDISING,
    AUTH_HO_LP,

    AUTH_WH_OUTBOUND_ADMIN,
    AUTH_WH_INV_ADMIN,
  ],
  add_supply_order: [
    AUTH_OUTBOUND_STAFF,
    AUTH_MERCHANDISING_ADMIN,
    //WAREHOUSE
    AUTH_MERCHANDISING_WAREHOUSE,

    //HUB
    AUTH_MERCHANDISING_HUB,
    AUTH_CAPTAIN,

    //GLOBAL
    AUTH_SUPPLY_CHAIN,
    AUTH_CENTRAL_SUPERVISOR,
    AUTH_HO_MERCHANDISING,
    AUTH_HO_OPS_MID_MILE_ADMIN,
    AUTH_HO_LP,
    AUTH_WH_INV_ADMIN,
  ],
  outbound_supply_order_dashboard: [
    AUTH_OUTBOUND_STAFF,
    AUTH_MERCHANDISING_ADMIN,
    //WAREHOUSE
    AUTH_MERCHANDISING_WAREHOUSE,

    //HUB
    AUTH_MERCHANDISING_HUB,
    AUTH_CAPTAIN,

    //GLOBAL
    AUTH_SUPPLY_CHAIN,
    AUTH_CENTRAL_SUPERVISOR,
    AUTH_HO_OPX_MID_MILE,
    AUTH_HO_OPS_MID_MILE_ADMIN,
    AUTH_HO_OPS_MID_MILE_SPV,
    AUTH_HO_LP,

    AUTH_WH_OUTBOUND_ADMIN,
    AUTH_WH_OUTBOUND_PICKER,
    AUTH_WH_OUTBOUND_PACKER_CHECKER,
    AUTH_WH_OUTBOUND_LOADER_SHIFT_LEAD,
    AUTH_WH_OUTBOUND_LOADER_STAFF,
    AUTH_WH_INV_ADMIN,
  ],
  koliLost: [AUTH_CENTRAL_SUPERVISOR, AUTH_OUTBOUND_STAFF, AUTH_INVENTORY_CONTROLLER, AUTH_HO_LP],
  replenishment: [
    AUTH_CENTRAL_SUPERVISOR,
    AUTH_SHIFT_LEAD,
    AUTH_INVENTORY_CONTROLLER,
    AUTH_SUPPLY_CHAIN,
    AUTH_OUTBOUND_STAFF,
    AUTH_HO_LP,
  ],
  //INBOUND SO
  inbound_v2: [
    AUTH_INBOUND_STAFF,
    AUTH_CAPTAIN,
    AUTH_MERCHANDISING_HUB, //GLOBAL
    AUTH_SUPPLY_CHAIN,
    AUTH_HO_OPX_MID_MILE,

    AUTH_HO_LP,
    AUTH_PRODUCT_MANAGER,
    AUTH_QUALITY_ENGINEERING,
    AUTH_MERCHANDISING_WAREHOUSE,
    AUTH_MERCHANDISING_ADMIN,
    AUTH_HO_MERCHANDISING,
    AUTH_INVENTORY_CONTROLLER,
    AUTH_HUB_INV_STAFF,
    AUTH_HUB_INV_SPV,
    AUTH_HUB_INV_MANAGER,
    AUTH_HO_SUPPLY_CHAIN,
  ], //NEW INBOUND WRHS AND HUB
  inbound_hub: [
    AUTH_INBOUND_STAFF,
    AUTH_CAPTAIN,
    AUTH_MERCHANDISING_HUB, //GLOBAL
    AUTH_SUPPLY_CHAIN,
    AUTH_HO_OPX_MID_MILE,
    AUTH_HO_LP,
  ],
  generate_package_id: [
    AUTH_INBOUND_STAFF,
    AUTH_CAPTAIN,
    AUTH_MERCHANDISING_HUB, //GLOBAL
    AUTH_SUPPLY_CHAIN,
  ],
  generate_koli_id: [
    AUTH_INBOUND_STAFF,
    AUTH_CAPTAIN,
    AUTH_MERCHANDISING_HUB, //GLOBAL
    AUTH_SUPPLY_CHAIN,
  ],
  add_inbound_hub: [
    AUTH_INBOUND_STAFF,
    AUTH_CAPTAIN,

    AUTH_MERCHANDISING_HUB, //GLOBAL
    AUTH_SUPPLY_CHAIN,
    // AUTH_HO_OPX_MID_MILE,
  ],
  inbound_warehouse: [
    AUTH_MERCHANDISING_WAREHOUSE,
    AUTH_INBOUND_STAFF, //GLOBAL
    AUTH_SUPPLY_CHAIN,
    AUTH_HO_OPX_MID_MILE,

    AUTH_HO_LP,
  ],
  module_purchase_order: [
    AUTH_SOURCING,
    AUTH_PROCUREMENT,
    AUTH_INBOUND_STAFF,
    AUTH_SUPPLY_CHAIN,
    AUTH_HO_MERCHANDISING,
    AUTH_SOURCING_MANAGER,
    AUTH_HO_FINANCE,
    AUTH_WH_INV_SPV,
    AUTH_WH_INV_MANAGER,
    AUTH_WH_INV_STAFF,
    AUTH_WH_INBOUND_STAFF,
    AUTH_HO_SUPPLY_CHAIN,
    AUTH_WH_INBOUND_SPV,
    AUTH_WH_INV_MANAGER_VIEWONLY,
    AUTH_HO_PROCUREMENT,
    AUTH_HO_ASTRO_KITCHEN_SOURCING,
    AUTH_HO_ASTRO_KITCHEN_SUPPLY_CHAIN,
    AUTH_HO_PRODUCT_MANAGER,
    AUTH_HO_QUALITY_ENGINEERING,
    AUTH_WH_SECURITY,
  ],
  purchase_order_lite: [
    AUTH_SOURCING,
    // AUTH_SUPPLY_CHAIN,
    AUTH_PROCUREMENT,
    AUTH_SUPPLY_CHAIN,
  ],
  purchase_order_lite_approval: [AUTH_SOURCING, AUTH_PROCUREMENT],
  purchase_order_detail: [
    AUTH_SOURCING,
    AUTH_PROCUREMENT,
    AUTH_INBOUND_STAFF,
    AUTH_SUPPLY_CHAIN,
    AUTH_HO_MERCHANDISING,
    AUTH_WH_SECURITY,
  ],
  create_purchase_order_lite: [
    AUTH_SOURCING,
    AUTH_PROCUREMENT,
    AUTH_SUPPLY_CHAIN,
    AUTH_WH_SECURITY,
  ],
  inbound_warehouse_purchase_order_lite: [
    AUTH_INBOUND_STAFF,
    AUTH_SUPPLY_CHAIN,
    AUTH_HO_MERCHANDISING,
    AUTH_HO_FINANCE,
    AUTH_WH_SECURITY,
  ],
  inbound_hub_purchase_order_lite: [
    AUTH_INBOUND_STAFF,
    AUTH_SUPPLY_CHAIN,
    AUTH_HO_MERCHANDISING,
    AUTH_HO_FINANCE,
    AUTH_WH_SECURITY,
  ],
  purchase_order_lite_check_qty: [AUTH_INBOUND_STAFF],
  fresh_purchase_order: [
    AUTH_SOURCING_MANAGER,
    AUTH_SOURCING,
    AUTH_QA_FRESH_INBOUND,
    AUTH_INBOUND_STAFF,
    AUTH_FRESH_PROCESSING,
  ],
  fresh_purchase_order_list: [
    AUTH_SOURCING_MANAGER,
    AUTH_SOURCING,
    AUTH_QA_FRESH_INBOUND,
    AUTH_FRESH_PROCESSING,
  ],
  fresh_purchase_order_detail: [
    AUTH_SOURCING_MANAGER,
    AUTH_SOURCING,
    AUTH_QA_FRESH_INBOUND,
    AUTH_FRESH_PROCESSING,
  ],
  fresh_purchase_order_bulk_upload: [AUTH_SOURCING_MANAGER, AUTH_SOURCING],
  fresh_purchase_order_process_inbound: [AUTH_QA_FRESH_INBOUND],
  fresh_purchase_order_pricing: [AUTH_SOURCING, AUTH_SOURCING_MANAGER],
  fresh_purchase_order_inbound: [
    AUTH_SOURCING_MANAGER,
    AUTH_SOURCING,
    AUTH_QA_FRESH_INBOUND,
    AUTH_FRESH_PROCESSING,
  ],
  fresh_purchase_order_inbound_list: [
    AUTH_SOURCING_MANAGER,
    AUTH_SOURCING,
    AUTH_QA_FRESH_INBOUND,
    AUTH_FRESH_PROCESSING,
  ],
  fresh_purchase_order_inbound_detail: [
    AUTH_SOURCING_MANAGER,
    AUTH_SOURCING,
    AUTH_QA_FRESH_INBOUND,
    AUTH_FRESH_PROCESSING,
  ],
  fresh_purchase_order_inbound_trimming_and_packing: [AUTH_QA_FRESH_INBOUND, AUTH_FRESH_PROCESSING],
  fresh_purchase_order_trimming_and_packing: [
    AUTH_SOURCING,
    AUTH_SOURCING_MANAGER,
    AUTH_INBOUND_STAFF,
    AUTH_QA_FRESH_INBOUND,
    AUTH_FRESH_PROCESSING,
  ],
  fresh_purchase_order_trimming_and_packing_detail: [
    AUTH_SOURCING,
    AUTH_SOURCING_MANAGER,
    AUTH_INBOUND_STAFF,
    AUTH_QA_FRESH_INBOUND,
    AUTH_FRESH_PROCESSING,
  ],
  fresh_purchase_order_trimming_and_packing_check_quantity: [
    AUTH_INBOUND_STAFF,
    AUTH_QA_FRESH_INBOUND,
    AUTH_FRESH_PROCESSING,
  ],

  //STOCK OPNAME
  stock_opname: [
    AUTH_STOCK_OPNAME,
    AUTH_AREA_MANAGER,
    AUTH_ADJUSTMENT,
    AUTH_STO_VIEW_ONLY,
    AUTH_CENTRAL_MANAGER,
    AUTH_CENTRAL_SUPERVISOR,
    AUTH_WH_MANAGER,
    AUTH_CAPTAIN,
    AUTH_INBOUND_CHECKER,
    AUTH_PICKER,
    AUTH_SUPER_PICKER,
    AUTH_PACKER,
    AUTH_SUPER_PACKER,
    AUTH_DRIVER,
    AUTH_INBOUND_STAFF,
    AUTH_OUTBOUND_STAFF,
    AUTH_INVENTORY_CONTROLLER,
    AUTH_EXPANSION,
    AUTH_STOCK_COUNT_AUDITOR,
    AUTH_HO_FINANCE,
    AUTH_HO_LP,
  ],
  stock_opname_list: [
    AUTH_STOCK_OPNAME,
    AUTH_AREA_MANAGER,
    AUTH_STO_VIEW_ONLY,
    AUTH_CENTRAL_MANAGER,
    AUTH_CENTRAL_SUPERVISOR,
    AUTH_WH_MANAGER,
    AUTH_STOCK_COUNT_AUDITOR,
    AUTH_HO_FINANCE,
    AUTH_HO_LP,
  ],
  stock_opname_check_adjustment: [
    AUTH_STOCK_OPNAME,
    AUTH_AREA_MANAGER,
    AUTH_CENTRAL_MANAGER,
    AUTH_CENTRAL_SUPERVISOR,
    AUTH_WH_MANAGER,
    AUTH_STOCK_COUNT_AUDITOR,
  ],
  stock_opname_check_adjustment_view: [
    AUTH_STOCK_OPNAME,
    AUTH_ADJUSTMENT,
    AUTH_AREA_MANAGER,
    AUTH_CENTRAL_MANAGER,
    AUTH_CENTRAL_SUPERVISOR,
    AUTH_WH_MANAGER,
    AUTH_STOCK_COUNT_AUDITOR,
  ],
  stock_opname_adjustment: [AUTH_ADJUSTMENT, AUTH_HO_FINANCE, AUTH_HO_LP],
  stock_opname_detail: [
    AUTH_STOCK_OPNAME,
    AUTH_AREA_MANAGER,
    AUTH_STO_VIEW_ONLY,
    AUTH_CENTRAL_MANAGER,
    AUTH_CENTRAL_SUPERVISOR,
    AUTH_WH_MANAGER,
    AUTH_STOCK_COUNT_AUDITOR,
    AUTH_HO_FINANCE,
    AUTH_HO_LP,
  ],
  stock_opname_add_sto: [
    AUTH_STOCK_OPNAME,
    AUTH_AREA_MANAGER,
    AUTH_STO_VIEW_ONLY,
    AUTH_CENTRAL_MANAGER,
    AUTH_CENTRAL_SUPERVISOR,
    AUTH_WH_MANAGER,
  ],
  //ORDER or PESANAN PAGE
  order: [
    //WAREHOUSE
    AUTH_CENTRAL_SUPERVISOR,
    AUTH_CUSTOMER_SERVICE,
    AUTH_CS_CAPTAIN,
    AUTH_OUTBOUND_STAFF,
    AUTH_INBOUND_STAFF,

    //HUB
    AUTH_PICKER,
    AUTH_PACKER,
    AUTH_CAPTAIN,
    AUTH_MARKETING,
    AUTH_CS_AGENT,
    AUTH_HO_ASTRO_KITCHEN_STAFF,
    AUTH_HO_ASTRO_KITCHEN_MANAGER,
    AUTH_ASTRO_GO_MANAGER,
    AUTH_ASTRO_GO_SPV,
    AUTH_ASTRO_GO_SHIFT_LEAD,
    AUTH_ASTRO_GO_STAFF,
    AUTH_AREA_MANAGER,

    //GLOBAL
    AUTH_SUPPLY_CHAIN,
    AUTH_HO_CS_LEADER,
    AUTH_HO_FINANCE,

    AUTH_HO_LP,
    AUTH_HO_CAMPAIGN,
    AUTH_HUB_AREA_MANAGER,
    AUTH_HUB_AREA_MANAGER_VIEWONLY,
    AUTH_HUB_SPV,
    AUTH_HUB_SHIFT_LEAD,
    AUTH_HUB_INV_MANAGER,
    AUTH_HUB_INV_MANAGER_VIEWONLY,
    AUTH_HUB_INV_SPV,
    AUTH_HUB_INV_STAFF,
    AUTH_HUB_INBOUND_STAFF,
    AUTH_QA_MANAGER,
    AUTH_QA_SUPERVISOR,
    AUTH_QA_STAFF,
    AUTH_SUPER_SPV,
    AUTH_SUPER_DRIVER,
    AUTH_SUPER_AREA_MANAGER,
    AUTH_SUPER_INBOUND_STAFF,
    AUTH_WH_MANAGER_VIEWONLY,
    AUTH_INTERNAL_SALES,
    AUTH_HO_MARKETING,
    AUTH_HO_PARTNERSHIP,
    AUTH_HO_PRODUCT_MANAGER,
    AUTH_HO_QUALITY_ENGINEERING,
    AUTH_HO_SUPPLY_CHAIN,
    AUTH_PRODUCT_MANAGER,
    AUTH_QUALITY_ENGINEERING,
    AUTH_SHIFT_LEAD,
    AUTH_SPV_HUB,
  ],
  ongoing_order: [
    //WAREHOUSE
    AUTH_CENTRAL_SUPERVISOR,
    AUTH_CUSTOMER_SERVICE,
    AUTH_CS_CAPTAIN,
    AUTH_OUTBOUND_STAFF,
    AUTH_INBOUND_STAFF,

    //HUB
    AUTH_PICKER,
    AUTH_PACKER,
    AUTH_CAPTAIN,

    //GLOBAL
    AUTH_SUPPLY_CHAIN,
    AUTH_MARKETING,
  ],
  pesanan_berlangsung: [
    //WAREHOUSE
    AUTH_CENTRAL_SUPERVISOR,
    AUTH_CUSTOMER_SERVICE,
    AUTH_CS_CAPTAIN,
    AUTH_OUTBOUND_STAFF,
    AUTH_INBOUND_STAFF,

    //HUB
    AUTH_PICKER,
    AUTH_PACKER,
    AUTH_CAPTAIN,
    AUTH_HO_ASTRO_KITCHEN_STAFF,
    AUTH_HO_ASTRO_KITCHEN_MANAGER,

    //GLOBAL
    AUTH_SUPPLY_CHAIN,
    AUTH_HO_CS_LEADER,
    AUTH_CS_AGENT,
    AUTH_HO_ASTRO_KITCHEN_MANAGER,
    AUTH_HO_ASTRO_KITCHEN_STAFF,

    AUTH_HO_LP,
  ],
  order_picker: [
    //HUB
    AUTH_PICKER,
    AUTH_CAPTAIN,
  ],
  order_packer: [AUTH_PACKER, AUTH_CAPTAIN],
  pooling_map: [AUTH_ADMIN, AUTH_PACKER],
  all_order: [
    //WAREHOUSE
    AUTH_CENTRAL_SUPERVISOR,
    //AUTH_CUSTOMER_SERVICE,
    AUTH_CS_CAPTAIN,
    AUTH_INBOUND_STAFF,
    AUTH_OUTBOUND_STAFF,

    //HUB
    AUTH_CAPTAIN,
    AUTH_HO_ASTRO_KITCHEN_STAFF,
    AUTH_HO_ASTRO_KITCHEN_MANAGER,
    AUTH_ASTRO_GO_MANAGER,
    AUTH_ASTRO_GO_SPV,
    AUTH_ASTRO_GO_SHIFT_LEAD,
    AUTH_ASTRO_GO_STAFF,

    //GLOBAL
    AUTH_SUPPLY_CHAIN,
    AUTH_MARKETING,
    AUTH_HO_CS_LEADER,
    AUTH_CS_AGENT,
    AUTH_HO_FINANCE,

    AUTH_HO_LP,
    AUTH_HUB_AREA_MANAGER,
    AUTH_HUB_AREA_MANAGER_VIEWONLY,
    AUTH_HUB_SPV,
    AUTH_HUB_SHIFT_LEAD,
    AUTH_HUB_INV_MANAGER,
    AUTH_HUB_INV_MANAGER_VIEWONLY,
    AUTH_HUB_INV_SPV,
    AUTH_HUB_INV_STAFF,
    AUTH_HUB_INBOUND_STAFF,
    AUTH_QA_MANAGER,
    AUTH_QA_SUPERVISOR,
    AUTH_QA_STAFF,
    AUTH_SUPER_AREA_MANAGER,
    AUTH_SUPER_SPV,
    AUTH_SUPER_SHIFT_LEAD,
    AUTH_SUPER_INBOUND_STAFF,
    AUTH_WH_MANAGER_VIEWONLY,
    AUTH_INTERNAL_SALES,
    AUTH_HO_MARKETING,
    AUTH_HO_CAMPAIGN,
    AUTH_HO_PARTNERSHIP,
    AUTH_HO_PRODUCT_MANAGER,
    AUTH_HO_QUALITY_ENGINEERING,
    AUTH_AREA_MANAGER,
  ],
  driver_monitoring_dashboard: [AUTH_ADMIN],
  //ORDER SUPER or PESANAN PAGE
  order_super: [
    //WAREHOUSE
    AUTH_CENTRAL_SUPERVISOR,
    AUTH_CUSTOMER_SERVICE,
    AUTH_CS_CAPTAIN,
    AUTH_OUTBOUND_STAFF,
    AUTH_INBOUND_STAFF,

    //HUB
    AUTH_SUPER_PICKER,
    AUTH_SUPER_PACKER,
    AUTH_CAPTAIN,

    //GLOBAL
    AUTH_SUPPLY_CHAIN,
    AUTH_HO_CS_LEADER,
    AUTH_CS_AGENT,
    AUTH_HO_FINANCE,

    AUTH_HO_LP,
  ],

  order_super_pesanan: [
    AUTH_SUPER_PICKER,
    AUTH_SUPER_PACKER,
    AUTH_CAPTAIN,
    AUTH_HO_CS_LEADER,
    AUTH_CS_AGENT,
    AUTH_HO_LP,
  ],

  all_order_super: [
    //WAREHOUSE
    AUTH_CENTRAL_SUPERVISOR,
    AUTH_CUSTOMER_SERVICE,
    AUTH_CS_CAPTAIN,
    AUTH_INBOUND_STAFF,
    AUTH_OUTBOUND_STAFF,

    //HUB
    AUTH_CAPTAIN,

    //GLOBAL
    AUTH_SUPPLY_CHAIN,
    AUTH_HO_CS_LEADER,
    AUTH_CS_AGENT,
    AUTH_HO_FINANCE,

    AUTH_HO_LP,
  ],

  //SETTING PAGE
  setting: [AUTH_MARKETING],
  setting_order_detail: [AUTH_MARKETING],

  //PROMOTION PAGE
  promotion: [
    AUTH_CS_CAPTAIN,
    //GLOBAL
    AUTH_PRICING,
    AUTH_SUPPLY_CHAIN,
    AUTH_MARKETING,
    AUTH_HO_CS_LEADER,
    AUTH_HO_CAMPAIGN,
    AUTH_HO_SUPPLY_CHAIN,
    AUTH_HO_PRODUCT_MANAGER,
    AUTH_HO_QUALITY_ENGINEERING,
    AUTH_HO_FRAUD,
    AUTH_MARKETING,
    AUTH_MERCHANDISING_ADMIN,
  ],
  promotion_referral_mission_list: [AUTH_CS_CAPTAIN, AUTH_MARKETING, AUTH_HO_CS_LEADER],
  promotion_referral_mission_add: [AUTH_CS_CAPTAIN, AUTH_MARKETING],
  promotion_referral_setting: [AUTH_CS_CAPTAIN, AUTH_MARKETING, AUTH_HO_CS_LEADER],
  promotion_referral_vip_list: [AUTH_CS_CAPTAIN, AUTH_MARKETING, AUTH_HO_CS_LEADER],
  add_promotion_voucher: [
    AUTH_CS_CAPTAIN,
    //GLOBAL
    AUTH_PRICING,
    AUTH_SUPPLY_CHAIN,
    AUTH_MARKETING,
    AUTH_HO_CS_LEADER,

    //NEW ADJUSTED ROLE
    AUTH_HO_FRAUD,
    AUTH_HO_CAMPAIGN,
    AUTH_HO_PRODUCT_MANAGER,
    AUTH_HO_QUALITY_ENGINEERING,
  ],

  //UNIQUE VOUCHER
  unique_voucher: [
    AUTH_PRICING,
    AUTH_MARKETING,
    AUTH_HO_MARKETING,
    AUTH_HO_CAMPAIGN,
    AUTH_PRODUCT_MANAGER,
    AUTH_HO_PRODUCT_MANAGER,
    AUTH_QUALITY_ENGINEERING,
    AUTH_HO_QUALITY_ENGINEERING,
  ],
  unique_voucher_list: [
    AUTH_PRICING,
    AUTH_MARKETING,
    AUTH_HO_MARKETING,
    AUTH_HO_CAMPAIGN,
    AUTH_PRODUCT_MANAGER,
    AUTH_HO_PRODUCT_MANAGER,
    AUTH_QUALITY_ENGINEERING,
    AUTH_HO_QUALITY_ENGINEERING,
  ],
  unique_voucher_campaign_list: [
    AUTH_PRICING,
    AUTH_MARKETING,
    AUTH_HO_MARKETING,
    AUTH_HO_CAMPAIGN,
    AUTH_PRODUCT_MANAGER,
    AUTH_HO_PRODUCT_MANAGER,
    AUTH_QUALITY_ENGINEERING,
    AUTH_HO_QUALITY_ENGINEERING,
  ],
  unique_voucher_add: [
    AUTH_PRICING,
    AUTH_HO_CAMPAIGN,
    AUTH_PRODUCT_MANAGER,
    AUTH_HO_PRODUCT_MANAGER,
    AUTH_QUALITY_ENGINEERING,
    AUTH_HO_QUALITY_ENGINEERING,
  ],
  //GIFT WITH PURCHASE
  gift_with_purchase: [AUTH_CS_CAPTAIN, AUTH_SUPPLY_CHAIN, AUTH_MARKETING, AUTH_HO_CS_LEADER],
  purchase_with_purchase: [
    AUTH_CS_CAPTAIN,
    AUTH_SUPPLY_CHAIN,
    AUTH_MARKETING,
    AUTH_HO_CS_LEADER,
    AUTH_HO_CAMPAIGN,
    AUTH_HO_SUPPLY_CHAIN,
    AUTH_HO_PRODUCT_MANAGER,
    AUTH_HO_QUALITY_ENGINEERING,
  ],

  //RAK MANAGEMENT
  rak: [
    AUTH_CENTRAL_SUPERVISOR,

    //GLOBALS
    AUTH_SUPPLY_CHAIN,
    AUTH_CAPTAIN,
    AUTH_EXPANSION,
    AUTH_INBOUND_STAFF,
    AUTH_OUTBOUND_STAFF,
    AUTH_INVENTORY_CONTROLLER,
    AUTH_HO_LP,
  ],
  rak_detail: [
    AUTH_CENTRAL_SUPERVISOR,

    //GLOBALS
    AUTH_SUPPLY_CHAIN,
    AUTH_CAPTAIN,
    AUTH_EXPANSION,
    AUTH_INBOUND_STAFF,
    AUTH_OUTBOUND_STAFF,
    AUTH_INVENTORY_CONTROLLER,
  ],
  rak_detail_add_and_edit: [
    AUTH_CENTRAL_SUPERVISOR,
    AUTH_INVENTORY_CONTROLLER,
    AUTH_CAPTAIN,
    AUTH_EXPANSION,
  ],
  reorder_list_sc: [AUTH_SUPPLY_CHAIN],
  reorder_list_sourcing: [AUTH_SOURCING_MANAGER, AUTH_SOURCING],
  purchase_request: [AUTH_SOURCING_MANAGER, AUTH_SOURCING, AUTH_SUPPLY_CHAIN, AUTH_PROCUREMENT],
  direct_putaway: [
    AUTH_INBOUND_STAFF,
    AUTH_WH_INV_SPV,
    AUTH_WH_INV_MANAGER,
    AUTH_WH_INV_STAFF,
    AUTH_WH_INBOUND_STAFF,
    AUTH_HO_SUPPLY_CHAIN,
    AUTH_WH_INBOUND_SPV,
    AUTH_WH_INV_MANAGER_VIEWONLY,
  ],
  //MASS UPLOAD
  mass_uplad: [
    //WAREHOUSE
    AUTH_CENTRAL_SUPERVISOR,
    AUTH_MERCHANDISING_ADMIN,
    AUTH_MERCHANDISING_WAREHOUSE,
    AUTH_CUSTOMER_SERVICE,
    AUTH_CS_CAPTAIN,
    AUTH_PACKER,
    AUTH_HO_MERCHANDISING,

    //HUB
    AUTH_CAPTAIN,
    AUTH_MERCHANDISING_HUB,
    AUTH_SUPPLY_CHAIN,
    AUTH_PRICING,
    AUTH_DRIVER_COORDINATOR,
    AUTH_HO_HR,

    AUTH_HO_PRODUCT_MANAGER,
    AUTH_HO_QUALITY_ENGINEERING,
    AUTH_HO_IT,
  ],
  fraud: [AUTH_FRAUD, AUTH_CUSTOMER_SERVICE, AUTH_CS_CAPTAIN, AUTH_HO_CS_LEADER, AUTH_CS_AGENT],
  fraud_setting: [AUTH_FRAUD],
  fraud_status: [AUTH_FRAUD],
  fraud_black_and_white_list: [AUTH_FRAUD],
  fraud_referral: [
    AUTH_FRAUD,
    AUTH_CUSTOMER_SERVICE,
    AUTH_CS_CAPTAIN,
    AUTH_HO_CS_LEADER,
    AUTH_CS_AGENT,
  ],
  fraud_watch_tower_rule: [AUTH_FRAUD],
  fraud_watch_tower_query_rule: [AUTH_FRAUD],
  fraud_watch_tower_parameters: [AUTH_FRAUD],
  bulkUpload: [AUTH_SOURCING, AUTH_HO_SOURCING_MANAGERIAL_LEVEL, AUTH_HO_SOURCING_ADMIN],
  vendor: [
    AUTH_SOURCING,
    AUTH_SOURCING_MANAGER,
    AUTH_PROCUREMENT,
    AUTH_SUPPLY_CHAIN,
    AUTH_HO_MERCHANDISING,
    AUTH_HO_FINANCE,
    AUTH_MERCHANDISING_HUB,
    AUTH_MERCHANDISING_WAREHOUSE,
    AUTH_HO_SOURCING_ADMIN,
    AUTH_HO_SOURCING_MANAGERIAL_LEVEL,
    AUTH_HO_QUALITY_ENGINEERING,
    AUTH_HO_PRODUCT_MANAGER,
  ],
  schedule: [
    AUTH_DRIVER_COORDINATOR,
    AUTH_CENTRAL_SUPERVISOR,
    AUTH_EXPANSION,
    AUTH_MARKETING,
    AUTH_HO_HR,
    AUTH_HO_CS_LEADER,
    AUTH_SPV_HUB,
    AUTH_PRODUCT_MANAGER,
    AUTH_HO_HR,
    AUTH_WH_MANAGER,
    AUTH_HO_OPX_MID_MILE,
    AUTH_HO_OPS_MID_MILE_SPV,
    AUTH_HO_LP,
    AUTH_HO_OPX_HUB,
    AUTH_HR_LEAD,

    // Temp role
    AUTH_TEMP_MASTER_APPROVAL,
    AUTH_HUB_SPV,
  ],
  hubStaffSchedule: [
    AUTH_DRIVER_COORDINATOR,
    AUTH_CENTRAL_SUPERVISOR,
    AUTH_EXPANSION,
    AUTH_MARKETING,
    AUTH_HO_HR,
    AUTH_HO_CS_LEADER,
    AUTH_SPV_HUB,
    AUTH_HO_OPX_MID_MILE,
    AUTH_HO_OPS_MID_MILE_SPV,
    AUTH_HO_LP,
    AUTH_HO_OPX_HUB,
    AUTH_HR_LEAD,

    // Temp role
    AUTH_TEMP_MASTER_APPROVAL,
    AUTH_HUB_SPV,
  ],
  masterApproval: [
    AUTH_SPV_HUB,
    AUTH_WH_MANAGER,
    AUTH_PRODUCT_MANAGER,
    AUTH_HO_HR,
    AUTH_HO_OPX_MID_MILE,
    AUTH_HO_OPX_HUB,
    AUTH_HO_OPS_MID_MILE_SPV,
    AUTH_HR_LEAD,

    // Temp role
    AUTH_TEMP_MASTER_APPROVAL,
    AUTH_HUB_SPV,
  ],
  masterShift: [
    AUTH_DRIVER_COORDINATOR,
    AUTH_CENTRAL_SUPERVISOR,
    AUTH_MARKETING,
    AUTH_HO_HR,
    AUTH_HO_CS_LEADER,
    AUTH_HO_OPX_MID_MILE,
    AUTH_HO_OPS_MID_MILE_SPV,
    AUTH_PRODUCT_MANAGER,
    AUTH_HO_LP,
    AUTH_HO_OPX_HUB,
    AUTH_HR_LEAD,

    // Temp role
    AUTH_TEMP_MASTER_APPROVAL,
  ],
  masterPayroll: [AUTH_HO_HR, AUTH_HO_PRODUCT_MANAGER, AUTH_HO_QUALITY_ENGINEERING],
  hubStaffAttendance: [
    AUTH_DRIVER_COORDINATOR,
    AUTH_CENTRAL_SUPERVISOR,
    AUTH_EXPANSION,
    AUTH_MARKETING,
    AUTH_HO_HR,
    AUTH_HO_CS_LEADER,
    AUTH_HO_LP,
    AUTH_SPV_HUB,
    AUTH_HO_OPX_MID_MILE,
    AUTH_HO_OPX_HUB,
    AUTH_HO_OPS_MID_MILE_SPV,
    AUTH_HR_LEAD,

    // Temp role
    AUTH_TEMP_MASTER_APPROVAL,
    AUTH_HUB_SPV,
  ],
  loyalty: [
    AUTH_MARKETING,
    AUTH_FRAUD,
    AUTH_CUSTOMER_SERVICE,
    AUTH_CS_CAPTAIN,
    AUTH_MERCHANDISING_HUB,
    AUTH_MERCHANDISING_WAREHOUSE,
    AUTH_SPV_HUB,
    AUTH_CAPTAIN,
    AUTH_SHIFT_LEAD,
    AUTH_HO_CS_LEADER,
    AUTH_CS_AGENT,
  ],
  loyalty_coin_earned: [AUTH_MARKETING],
  loyalty_coin_exchange: [AUTH_MARKETING],
  loyalty_coin_history: [
    AUTH_CUSTOMER_SERVICE,
    AUTH_CS_CAPTAIN,
    AUTH_FRAUD,
    AUTH_MERCHANDISING_HUB,
    AUTH_MERCHANDISING_WAREHOUSE,
    AUTH_SPV_HUB,
    AUTH_CAPTAIN,
    AUTH_SHIFT_LEAD,
    AUTH_HO_CS_LEADER,
    AUTH_CS_AGENT,
  ],
  loyalty_activity: [AUTH_MARKETING],
  loyalty_voucher: [AUTH_MARKETING],
  loyalty_voucher_form: [AUTH_MARKETING],
  refundAndDisburse: [
    AUTH_CS_CAPTAIN,
    AUTH_CUSTOMER_SERVICE,
    AUTH_FINANCE_ADMIN,
    AUTH_HO_CS_REFUND,
    AUTH_HO_CS_LEADER,
    AUTH_CS_AGENT,
    AUTH_HO_CS_SHIFT_LEAD,
    AUTH_HO_CS_SPV,
    AUTH_PRODUCT_MANAGER,
    AUTH_QUALITY_ENGINEERING,
    AUTH_HO_IA_HEAD,
    AUTH_HO_IA_MANAGER,
    AUTH_HO_IA_SPV,
    AUTH_HO_IA_STAFF,
    AUTH_HO_PRODUCT_MANAGER,
    AUTH_HO_QUALITY_ENGINEERING,
  ],
  refund: [
    AUTH_CS_CAPTAIN,
    AUTH_CUSTOMER_SERVICE,
    AUTH_HO_CS_REFUND,
    AUTH_HO_CS_LEADER,
    AUTH_CS_AGENT,
  ],
  disbursement: [
    AUTH_CS_CAPTAIN,
    AUTH_FINANCE_ADMIN,
    AUTH_HO_CS_REFUND,
    AUTH_HO_CS_LEADER,
    AUTH_CS_AGENT,
  ],
  soApproval: [AUTH_INVENTORY_CONTROLLER, AUTH_LP, AUTH_SPV_HUB, AUTH_HO_LP],
  capacityHandler: [AUTH_SHIFT_LEAD, AUTH_CENTRAL_SUPERVISOR, AUTH_HO_LP],
  bulkUploadV2: [
    AUTH_FINANCE_ADMIN,
    AUTH_HO_ADJUSTMENT,
    AUTH_HO_OPX_MID_MILE,
    AUTH_HO_OPS_MID_MILE_SPV,
  ],
  inboundSchedule: [
    AUTH_SOURCING,
    AUTH_MERCHANDISING_WAREHOUSE,
    AUTH_SOURCING_MANAGER,
    AUTH_INVENTORY_CONTROLLER,
    AUTH_MERCHANDISING_HUB,
    AUTH_INBOUND_STAFF,
    AUTH_HO_LP,
  ],
  flagManagement: [AUTH_ADMIN],
  vendorBrandGrouping: [
    AUTH_SOURCING,
    AUTH_PROCUREMENT,
    AUTH_MERCHANDISING_ADMIN,
    AUTH_MERCHANDISING_WAREHOUSE,
    AUTH_MERCHANDISING_HUB,
    AUTH_HO_FINANCE,
  ],
  ordersDetail: [
    AUTH_ADMIN,
    AUTH_CS_CAPTAIN,
    AUTH_CAPTAIN,
    AUTH_SPV_HUB,
    AUTH_CUSTOMER_SERVICE,
    AUTH_HO_CS_LEADER,
    AUTH_HO_LP,
    AUTH_CS_AGENT,
    AUTH_HUB_AREA_MANAGER,
    AUTH_HUB_SPV,
    AUTH_HUB_SHIFT_LEAD,
    AUTH_SUPER_AREA_MANAGER,
    AUTH_SUPER_SPV,
    AUTH_SUPER_SHIFT_LEAD,
    AUTH_INTERNAL_SALES,
    AUTH_HO_ASTRO_KITCHEN_MANAGER,
    AUTH_HO_ASTRO_KITCHEN_STAFF,
    AUTH_HO_PRODUCT_MANAGER,
    AUTH_HO_QUALITY_ENGINEERING,
    AUTH_ASTRO_GO_MANAGER,
    AUTH_ASTRO_GO_SPV,
    AUTH_ASTRO_GO_SHIFT_LEAD,
    AUTH_ASTRO_GO_STAFF,
    AUTH_HUB_AREA_MANAGER_VIEWONLY,
    AUTH_ASTRO_GO_MANAGER,
    AUTH_ASTRO_GO_SPV,
    AUTH_ASTRO_GO_SHIFT_LEAD,
    AUTH_ASTRO_GO_STAFF,
  ],
  superOrdersDetail: [
    AUTH_ADMIN,
    AUTH_CS_CAPTAIN,
    AUTH_CAPTAIN,
    AUTH_SPV_HUB,
    AUTH_CUSTOMER_SERVICE,
    AUTH_HO_CS_LEADER,
    AUTH_HO_LP,
    AUTH_ASTRO_GO_MANAGER,
    AUTH_ASTRO_GO_SPV,
    AUTH_ASTRO_GO_SHIFT_LEAD,
    AUTH_ASTRO_GO_STAFF,
  ],
  promoReferralWidget: [
    AUTH_CS_CAPTAIN,
    AUTH_CUSTOMER_SERVICE,
    AUTH_CENTRAL_SUPERVISOR,
    AUTH_MARKETING,
    AUTH_PRICING,
    AUTH_SUPPLY_CHAIN,
  ],
  routeMaster: [
    AUTH_ADMIN,
    AUTH_WH_MANAGER,
    AUTH_DRIVER_COORDINATOR,
    AUTH_CENTRAL_SUPERVISOR,
    AUTH_TL_FLEET,
    AUTH_ADMIN_FLEET,
    AUTH_HO_OPX_MID_MILE,
    AUTH_HO_OPS_MID_MILE_ADMIN,
    AUTH_HO_OPS_MID_MILE_SPV,
  ],
  routeMasterAddEdit: [
    AUTH_ADMIN,
    AUTH_WH_MANAGER,
    AUTH_DRIVER_COORDINATOR,
    AUTH_CENTRAL_SUPERVISOR,
    AUTH_HO_OPX_MID_MILE,
    AUTH_HO_OPS_MID_MILE_SPV,
  ],
  productAssortment: [AUTH_ADMIN, AUTH_MERCHANDISING_HUB, AUTH_MERCHANDISING_WAREHOUSE],
  // aBAdminList: [
  //   AUTH_MERCHANDISING_ADMIN,
  //   AUTH_HO_MERCHANDISING,
  //   AUTH_PRODUCT_MANAGER,
  //   AUTH_HO_PRODUCT_MANAGER,
  //   AUTH_HO_CAMPAIGN,
  //   AUTH_HO_PRICING_HEAD,
  //   AUTH_HO_PRICING_MANAGER,
  //   AUTH_HO_PRICING_SPV,
  //   AUTH_HO_PRICING_STAFF,
  //   AUTH_PRICING,
  // ],
  aBAdminCreateEdit: [
    AUTH_MERCHANDISING_ADMIN,
    AUTH_HO_MERCHANDISING,
    AUTH_PRODUCT_MANAGER,
    AUTH_HO_PRODUCT_MANAGER,
    AUTH_HO_CAMPAIGN,
    AUTH_HO_PRICING_HEAD,
    AUTH_HO_PRICING_MANAGER,
    AUTH_HO_PRICING_SPV,
    AUTH_HO_PRICING_STAFF,
    AUTH_PRICING,
  ],
  kitting_de_kitting: [
    AUTH_OUTBOUND_STAFF,
    AUTH_MERCHANDISING_ADMIN,
    //WAREHOUSE
    AUTH_MERCHANDISING_WAREHOUSE,

    //HUB
    AUTH_MERCHANDISING_HUB,
    AUTH_CAPTAIN,

    //GLOBAL
    AUTH_SUPPLY_CHAIN,
    AUTH_CENTRAL_SUPERVISOR,
  ],
  createOrder: [AUTH_HO_ASTRO_KITCHEN_MANAGER, AUTH_HO_ASTRO_KITCHEN_STAFF],
  vendor_details: [
    AUTH_SOURCING,
    AUTH_SOURCING_MANAGER,
    AUTH_PROCUREMENT,
    AUTH_SUPPLY_CHAIN,
    AUTH_HO_MERCHANDISING,
    AUTH_HO_FINANCE,
    AUTH_MERCHANDISING_HUB,
    AUTH_MERCHANDISING_WAREHOUSE,
    AUTH_HO_PRODUCT_MANAGER,
    AUTH_HO_QUALITY_ENGINEERING,
    AUTH_HO_SOURCING_MANAGERIAL_LEVEL,
    AUTH_HO_SOURCING_ADMIN,
  ],
  skp: [
    AUTH_SOURCING,
    AUTH_SOURCING_MANAGER,
    AUTH_FINANCE_ADMIN,
    AUTH_MARKETING,
    AUTH_MERCHANDISING_WAREHOUSE,
    AUTH_MERCHANDISING_HUB,
    AUTH_HO_CATEGORY_HEAD_PL_FOOD,
    AUTH_HO_CATEGORY_MANAGER_PL_FOOD,
    AUTH_HO_CATEGORY_SPV_PL_FOOD,
    AUTH_HO_CATEGORY_STAFF_PL_FOOD,
    AUTH_HO_CATEGORY_HEAD_PL_NON_FOOD,
    AUTH_HO_CATEGORY_MANAGER_PL_NON_FOOD,
    AUTH_HO_CATEGORY_SPV_PL_NON_FOOD,
    AUTH_HO_CATEGORY_STAFF_PL_NON_FOOD,
    AUTH_HO_CUSTOMER_GROWTH_CONTROLLER,
  ],
  skp_sourcing: [AUTH_SOURCING, AUTH_SOURCING_MANAGER],
  skp_finance: [AUTH_FINANCE_ADMIN],
  skp_campaign: [AUTH_MARKETING, AUTH_MERCHANDISING_WAREHOUSE, AUTH_MERCHANDISING_HUB],
  skp_campaign_internal: [
    AUTH_HO_CATEGORY_HEAD_PL_FOOD,
    AUTH_HO_CATEGORY_MANAGER_PL_FOOD,
    AUTH_HO_CATEGORY_SPV_PL_FOOD,
    AUTH_HO_CATEGORY_STAFF_PL_FOOD,
    AUTH_HO_CATEGORY_HEAD_PL_NON_FOOD,
    AUTH_HO_CATEGORY_MANAGER_PL_NON_FOOD,
    AUTH_HO_CATEGORY_SPV_PL_NON_FOOD,
    AUTH_HO_CATEGORY_STAFF_PL_NON_FOOD,
    AUTH_HO_CUSTOMER_GROWTH_CONTROLLER,
  ],
  terms_and_condition_skp: [AUTH_SOURCING, AUTH_SOURCING_MANAGER],
  dynamicLandingPage: [AUTH_MERCHANDISING_HUB, AUTH_MERCHANDISING_WAREHOUSE, AUTH_SUPPLY_CHAIN],
  so_automation: [],
}

export const listAccessForOpenAllPage = [
  AUTH_ADMIN,
  AUTH_QUALITY_ENGINEERING,
  AUTH_PRODUCT_MANAGER,
  AUTH_ADMIN_VIEW_ONLY,
]

export function PrivateUserAccess({ children, listButton, ...rest }) {
  const { userData } = useAppSelector(({ auth: { userData } }) => ({ userData }), shallowEqual)
  const { authorities } = userData

  const { isFreezeApp } = useAppPusher(userData)

  const currentUserAuth = authorities.map((items) => items.name)

  const findAllowedAccess = (arr1, arr2) => {
    return arr1?.some((item) => arr2?.includes(item))
  }

  const newChildren = children.filter((items) => {
    //items.props.allow.includes('')
    if (!items) return false
    return findAllowedAccess(items.props.allow, currentUserAuth) || items.props.allowAll
  })

  // set user context datadog log
  datadogLogs.setUser({
    userId: userData.id,
    roles: userData.roles,
    locationRoles: userData.location_roles,
    locationType: userData.location_type,
    phone_number: userData.phone_number
      ? `${userData.phone_number.substring(0, userData.phone_number.length - 4)}****`
      : '',
  })

  return (
    <Route {...rest}>
      {isFreezeApp ? (
        <FreezePage />
      ) : (
        <>
          {listAccessForOpenAllPage.some((each) => currentUserAuth.includes(each))
            ? children
            : newChildren}
        </>
      )}
    </Route>
  )
}

export function authAdminChecker(stateAuthUserData) {
  const listCurrentAuth = stateAuthUserData.authorities.map((item) => item.name)

  return listAccessForOpenAllPage.some((each) => listCurrentAuth.includes(each))
}

export function filterLocationList(
  defaultLocationList,
  stateAuthUserData,
  keyType = 'id', //
  locationType = false,
  config = {
    showDefaultList: false, ///SHOW default FOR OTHER THAN AUTH_ADMIN
  },
  locationTypesList,
) {
  const locationTypes = locationTypesList
    ? locationTypesList
    : ['WAREHOUSE', 'HUB', 'OFFICE', 'VIRTUAL', 'VENDING MACHINE', 'MEGAHUB']

  const listLocationFromAuth = stateAuthUserData.location_roles.map((item) => {
    let newObj

    if (locationTypes.includes(locationType) && locationTypes.includes(item.location_type)) {
      if (keyType === 'id') {
        newObj = {
          id: item.location_id,
          name: item.location_name,
          type: item.location_type,
        }
      } else if (keyType === 'location_id') {
        newObj = {
          name: item.location_name,
          location_id: item.location_id,
          location_name: item.location_name,
          location_type: item.location_type,
        }
      } else {
        return false
      }
    }
    return newObj
  })

  const newList = listLocationFromAuth.filter((val) => val)

  if (authAdminChecker(stateAuthUserData)) {
    return defaultLocationList
  } else {
    if (config.showDefaultList) {
      return defaultLocationList
    } else {
      return newList
    }
    //return newList;
  }
}
