import { lazy } from 'react'
import { roles } from 'constant/roles'
import generateRouterDetail from 'utils/helpers/generateRouterDetail'

const VendorList = lazy(() => import('../pages/VendorList'))
const VendorDetails = lazy(() => import('../pages/VendorDetails'))
const AddEditVendor = lazy(() => import('../pages/AddEditVendor'))

export const approvalLv1 = [roles.AUTH_VENDOR_SUBMISSION_APPROVAL_1]
export const approvalLv2 = [roles.AUTH_VENDOR_SUBMISSION_APPROVAL_2]
export const approvalLv3 = [roles.AUTH_VENDOR_SUBMISSION_APPROVAL_3]
export const approvalLv4 = [roles.AUTH_VENDOR_SUBMISSION_APPROVAL_4]

export const sourcingRoles = [
  roles.AUTH_PROCUREMENT,
  roles.AUTH_SOURCING,
  roles.AUTH_SOURCING_MANAGER,
  roles.AUTH_HO_CATEGORY_HEAD_PL_FOOD,
  roles.AUTH_HO_CATEGORY_HEAD_PL_NON_FOOD,
  roles.AUTH_HO_CATEGORY_MANAGER_PL_FOOD,
  roles.AUTH_HO_CATEGORY_MANAGER_PL_NON_FOOD,
]

const commonRoles = [
  ...approvalLv1,
  ...approvalLv2,
  ...approvalLv3,
  ...approvalLv4,
  roles.AUTH_HO_MERCHANDISING,
  roles.AUTH_MERCHANDISING_ADMIN,
  roles.AUTH_MERCHANDISING_HUB,
  roles.AUTH_MERCHANDISING_WAREHOUSE,
  roles.AUTH_SUPPLY_CHAIN,
  roles.AUTH_HO_LEGAL,
  roles.AUTH_HO_FINANCE,
  roles.AUTH_HO_FINANCE_MANAGER,
  roles.AUTH_HO_FINANCE_AP_SPV,
  roles.AUTH_HO_FINANCE_AR_SPV,
]

export const VendorListRouter: RouterFeatureInterface<'VendorListRouter', '/dashboard/vendor'> = {
  name: 'VendorListRouter',
  Component: VendorList,
  path: '/dashboard/vendor',
  routeType: 'PRIVATE',
  allowedRoles: [...sourcingRoles, ...commonRoles],
  isDashboard: true,
}

const VendorDetailsRouter: RouterFeatureInterface<
  'VendorDetailsRouter',
  '/dashboard/vendor/details/:id'
> = {
  name: 'VendorDetailsRouter',
  Component: VendorDetails,
  path: '/dashboard/vendor/details/:id',
  routeType: 'PRIVATE',
  allowedRoles: [...sourcingRoles, ...commonRoles],
  isDashboard: true,
}

const AddVendorRouter: RouterFeatureInterface<'AddVendorRouter', '/dashboard/vendor/add-vendor'> = {
  name: 'AddVendorRouter',
  Component: AddEditVendor,
  path: '/dashboard/vendor/add-vendor',
  routeType: 'PRIVATE',
  allowedRoles: sourcingRoles,
  isDashboard: true,
}

const EditVendorRouter: RouterFeatureInterface<
  'EditVendorRouter',
  '/dashboard/vendor/edit-vendor/:id'
> = {
  name: 'EditVendorRouter',
  Component: AddEditVendor,
  path: '/dashboard/vendor/edit-vendor/:id',
  routeType: 'PRIVATE',
  allowedRoles: sourcingRoles,
  isDashboard: true,
}

export const EditVendorRequestRouter: RouterFeatureInterface<
  'EditVendorRequestRouter',
  '/dashboard/vendor/edit-request/:id'
> = {
  name: 'EditVendorRequestRouter',
  Component: AddEditVendor,
  path: '/dashboard/vendor/edit-request/:id',
  routeType: 'PRIVATE',
  allowedRoles: sourcingRoles,
  isDashboard: true,
}

export const VendorReviewRouter: RouterFeatureInterface<
  'VendorReviewRouter',
  '/dashboard/vendor/review/:id'
> = {
  name: 'VendorReviewRouter',
  Component: VendorDetails,
  path: '/dashboard/vendor/review/:id',
  routeType: 'PRIVATE',
  allowedRoles: [...sourcingRoles, ...commonRoles],
  isDashboard: true,
}

const VendorsRouter = [
  VendorListRouter,
  VendorDetailsRouter,
  AddVendorRouter,
  EditVendorRouter,
  EditVendorRequestRouter,
  VendorReviewRouter,
]

export const VendorRouterDetail = generateRouterDetail(VendorsRouter)

export default VendorsRouter
