export const SET_PRINT_CONTENT = 'SET_PRINT_CONTENT'
export const SET_TYPE_PRINT_AREA = 'SET_TYPE_PRINT_AREA'
export const SET_IS_START_PRINT_AREA = 'SET_IS_START_PRINT_AREA'
export const SET_IS_FAIL_PRINT_AREA = 'SET_IS_FAIL_PRINT_AREA'
export const SET_DATA_RESI_ORDER_PACKER_WAITING_DRIVER_PRINT_AREA =
  'SET_DATA_RESI_ORDER_PACKER_WAITING_DRIVER_PRINT_AREA'
export const SET_DATA_RESI_ORDER_PICKER_PICKING_AREA = 'SET_DATA_RESI_ORDER_PICKER_PICKING_AREA'
export const SET_IS_READY_PRINT_PRINT_AREA = 'SET_IS_READY_PRINT_PRINT_AREA'
export const SET_IMAGE_BARCODE_BLOB_PRINT_AREA = 'SET_IMAGE_BARCODE_BLOB_PRINT_AREA'
export const SET_DATA_SUPPLY_ORDER_PRINT_PRINT_AREA = 'SET_DATA_SUPPLY_ORDER_PRINT_PRINT_AREA'
export const SET_SELECTED_DATA_SO_PRINT_AREA = 'SET_SELECTED_DATA_SO_PRINT_AREA'
export const SET_DATA_SUPPLY_ORDER_CETAK_SURAT_JALAN_PRINT_AREA =
  'SET_DATA_SUPPLY_ORDER_CETAK_SURAT_JALAN_PRINT_AREA'
export const SET_DATA_INBOUND_SO_PRINT_AREA = 'SET_DATA_INBOUND_SO_PRINT_AREA'
export const SET_SELECTED_DATA_INBOUND_SO_PRINT_AREA = 'SET_SELECTED_DATA_INBOUND_SO_PRINT_AREA'
export const SET_DATA_PO_LITE_QR_PRINT_AREA = 'SET_DATA_PO_LITE_QR_PRINT_AREA'
export const SET_IMAGE_QRCODE_BLOB_PRINT_AREA = 'SET_IMAGE_QRCODE_BLOB_PRINT_AREA'
export const SET_PO_LITE_QR_QTY_PRINT_AREA = 'SET_PO_LITE_QR_QTY_PRINT_AREA'
export const SET_DATA_PO_LITE_CREATE_PO_PRINT_AREA = 'SET_DATA_PO_LITE_CREATE_PO_PRINT_AREA'
export const SET_DATA_DELIVERY_NOTE_BULK = 'SET_DATA_DELIVERY_NOTE_BULK'
export const SET_DATA_RELABEL = 'SET_DATA_RELABEL'
export const SET_DATA_GIFT_NOTE = 'SET_DATA_GIFT_NOTE'
export const SET_DATA_ASTRO_KITCHEN = 'SET_DATA_ASTRO_KITCHEN'
export const SET_VENDOR_ID = 'SET_VENDOR_ID'
