import { AxiosRequestConfig } from 'axios'
import { Get, Put, Post, Delete } from 'config/apiServiceConfig'
import {
  GetRackStorageResponseType,
  GetUomsResponseType,
  LocationType,
  RackItemType,
} from 'features/Rack/@types/rack'

export type GetRackListResponseType = {
  data: {
    content: {
      filledRack: number
      location: LocationType
      racks: RackItemType[]
      utilization: number
    }[]
    empty: boolean
    numberOfElements: number
    size: number
    totalElements: number
    totalPages: number
  }
}

export type GetRackListParamsType = {
  pageIndex: number
  locationName?: string
}

export type GetRackDetailByLocationIdResponseType = {
  data: {
    location: LocationType
    racks: {
      content: RackItemType[]
      numberOfElements: number
    }
  }
}

export type GetRackDetailByLocationIdParamsType = {
  pageIndex?: number
  pageSize: number
  name?: string
  active?: string
}

export type GetLocationListParamsType = {
  name: string
}

type GetLocationListResponseType = { content: LocationType[] }

export type PostRackImportResponseType = {
  data: {
    status: string
    urlDownload: string
    rackTotal: number
    rackFailed: number
    rackSuccess: number
  }
}

type DeleteRackParamsType = {
  id: string
}

type GetRackCsvTemplateResponseType = {
  data: {
    url: string
  }
}

export type GetRackStorageParamsType = {
  type: 'Environment' | 'Type'
}

type GetUomsParamsType = {
  pageSize: number
  group?: 'ITEM' | 'WEIGHT' | 'LENGTH'
}

export const getLocationList = (params?: GetLocationListParamsType) =>
  Get<GetLocationListResponseType>({
    url: '/api/location',
    params,
  })

export const getRackList = (params: GetRackListParamsType) =>
  Get<GetRackListResponseType>({
    url: '/api/racks',
    params,
  })

export const getRackDetailByLocationId = (
  locationId: number,
  params: GetRackDetailByLocationIdParamsType,
) =>
  Get<GetRackDetailByLocationIdResponseType>({
    url: `/api/racks/v2/${locationId}`,
    params,
  })

export const getRacksExport = (locationId: number, params?: GetRackDetailByLocationIdParamsType) =>
  Get({ url: `/api/racks/${locationId}/export`, params })

export const putActivateDeactivateRack = (id: number) =>
  Put({ url: `/api/racks/active-toggle/${id}` })

export const postRackImport = (data: FormData, config: AxiosRequestConfig) =>
  Post<PostRackImportResponseType>({ url: `/api/racks/import`, data, config })

export const deleteRack = (locationId: number, params: DeleteRackParamsType) =>
  Delete({ url: `/api/racks/${locationId}`, params })

export const getRackCsvTemplate = () =>
  Get<GetRackCsvTemplateResponseType>({ url: '/api/racks/csv/template' })

export const getRackStorage = (params: GetRackStorageParamsType) =>
  Get<GetRackStorageResponseType>({ url: '/api/racks/storage', params })

export const getUoms = (params: GetUomsParamsType) =>
  Get<GetUomsResponseType>({ url: '/api/uoms', params })
