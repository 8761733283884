import { AUTH_CUSTOMER_SERVICE, AUTH_HO_CS_LEADER } from 'constant/roles'
import { AUTH_ADMIN } from 'middleware/privateRoute'
import { lazy } from 'react'
import generateRouterDetail from 'utils/helpers/generateRouterDetail'

const HCU = lazy(() => import('../pages/HubCareTable'))

type NamedRoutesType = 'HCURouter'
type PathListsType = '/dashboard/hcu'

type RouterInstanceType = RouterFeatureInterface<NamedRoutesType, PathListsType>

const HCURouterInstance: RouterInstanceType = {
  name: 'HCURouter',
  Component: HCU,
  path: '/dashboard/hcu',
  routeType: 'PRIVATE',
  allowedRoles: [AUTH_ADMIN, AUTH_HO_CS_LEADER, AUTH_CUSTOMER_SERVICE, 'AUTH_CS_AGENT'],
  isDashboard: true,
}

const HCURouter = [HCURouterInstance]

export const HCURouterDetail = generateRouterDetail(HCURouter)

export default HCURouter
