import printJS from 'print-js'
import {
  SET_IS_LOADING_SO,
  SET_PRODUCTS,
  SET_SELECTED_PRODUCTS,
  SET_ORIGIN_HUBS,
  SET_DESTINATION_HUBS,
  SET_SELECTED_ORIGIN_HUB,
  SET_SELECTED_DESTINATION_HUB,
  SET_IS_CHECK_ALL,
  SET_DATA_PAGINATION_ADD,
  SET_SUPPLY_ORDERS,
  SET_DATA_PAGINATION_SO,
  SET_SELECTED_SO,
  SET_IS_POPUP_CHECK_QTY_SHOW,
  SET_LAST_QUERY,
  SET_LIST_LOCATION_RAW,
  SET_REMARKS_SUPPLY_ORDER,
  TOGGLE_MODAL_CONFIRMATION_SUPPLY_ORDER,
  SET_REASONS_SUPPLY_ORDER,
  RESET_MODAL_CONFIRMATION_SUPPLY_ORDER,
  SET_ROUTE_MASTER_LIST,
} from 'storeContext/actionsType/supplyOrder'
import { filterLocationList } from 'middleware/filterLocation'
import { callErrorMsg } from 'helpers/errorMsg'
import {
  getAlllocation,
  postSupplyOrderGrand,
  putSupplyOrderGrand,
  getSupplyOrder,
  getSupplyOrderDetail,
  getPrintSupplyOrder,
  putSupplyOrderConfirm,
  getSupplyOrderGrandProduct,
  getSupplyOrderGrandPacking,
  putSupplyOrderGrandPacking,
  postValidateCheckQty,
  getSupplyOrderRemarks,
  getReasonsForSupplyOrder,
  getRouteMaster,
} from 'utils/api'
import { handleCheckSo } from 'helpers'
import { toastFailed, toastSuccess } from 'utils/toast'

export function setLastQuery(payload) {
  return {
    type: SET_LAST_QUERY,
    payload: payload,
  }
}

export function setIsPopupCheckQty(payload) {
  return {
    type: SET_IS_POPUP_CHECK_QTY_SHOW,
    payload: payload,
  }
}

export function setSelectedSo(payload) {
  return {
    type: SET_SELECTED_SO,
    payload: payload,
  }
}

export function setPaginationDataSo(payload) {
  return {
    type: SET_DATA_PAGINATION_SO,
    payload: payload,
  }
}
export function setSupplyOrders(payload) {
  return {
    type: SET_SUPPLY_ORDERS,
    payload: payload,
  }
}
export function setDataPaginationAdd(payload) {
  return {
    type: SET_DATA_PAGINATION_ADD,
    payload: payload,
  }
}
export function setIsCheckAll(payload) {
  return {
    type: SET_IS_CHECK_ALL,
    payload: payload,
  }
}
export function setIsLoading(payload) {
  return {
    type: SET_IS_LOADING_SO,
    payload: payload,
  }
}
export function setProducts(payload) {
  return {
    type: SET_PRODUCTS,
    payload: payload,
  }
}
export function setSelectedProducts(payload) {
  return {
    type: SET_SELECTED_PRODUCTS,
    payload: payload,
  }
}
export function setOriginHubs(payload) {
  return {
    type: SET_ORIGIN_HUBS,
    payload: payload,
  }
}
export function setDestinationHubs(payload) {
  return {
    type: SET_DESTINATION_HUBS,
    payload: payload,
  }
}
export function setSelectedOriginHub(payload) {
  return {
    type: SET_SELECTED_ORIGIN_HUB,
    payload: payload,
  }
}
export function setSelectedDestinationHub(payload) {
  return {
    type: SET_SELECTED_DESTINATION_HUB,
    payload: payload,
  }
}

export function setlistLocationRaw(payload) {
  return {
    type: SET_LIST_LOCATION_RAW,
    payload: payload,
  }
}

export function setRemarks(payload) {
  return {
    type: SET_REMARKS_SUPPLY_ORDER,
    payload: payload,
  }
}

export function setRouteMaster(payload) {
  return {
    type: SET_ROUTE_MASTER_LIST,
    payload: payload,
  }
}

export function toggleModalConfirmationSupplyOrder(payload) {
  return {
    type: TOGGLE_MODAL_CONFIRMATION_SUPPLY_ORDER,
    payload,
  }
}

export function resetModalConfirmationSupplyOrder() {
  return {
    type: RESET_MODAL_CONFIRMATION_SUPPLY_ORDER,
  }
}

export const actGetListRemarks = () => async (dispatch) => {
  try {
    const {
      data: { data },
    } = await getSupplyOrderRemarks()
    dispatch(setRemarks(data))
  } catch (error) {
    callErrorMsg(error)
  }
}

export function setReasonsSupplyOrder(payload) {
  return {
    type: SET_REASONS_SUPPLY_ORDER,
    payload,
  }
}

export const actGetListDropdown = (callback) => async (dispatch, getState) => {
  const { userData } = getState().stateAuth
  // const arrPending = [getAlllocation()];
  dispatch(setIsCheckAll(false))
  dispatch(setSelectedProducts([]))
  dispatch(setSelectedOriginHub({}))
  dispatch(setSelectedDestinationHub({}))
  dispatch(setProducts([]))
  dispatch(setIsLoading(true))

  getAlllocation()
    .then(({ data: location }) => {
      const formatedLocation = [...location].map((el) => {
        return { ...el, name: el.location_name }
      })

      const filteredLocation = filterLocationList(formatedLocation, userData, 'location_id')
      dispatch(setlistLocationRaw(formatedLocation))
      dispatch(setDestinationHubs(filteredLocation))

      if (typeof callback === 'function') {
        callback(filteredLocation)
      }
    })
    .finally(() => {
      dispatch(setIsLoading(false))
    })
}

export const actGetProducts =
  (id, params = {}, selectedProductInit = false, cb = () => {}) =>
  (dispatch, getState) => {
    dispatch(setIsLoading(true))
    getSupplyOrderGrandProduct(id, params)
      .then(({ data }) => {
        const products = data.data.content
        const pagination = {
          pageSize: data.data.size,
          totalData: data.data.totalElements,
          totalPage: data.data.totalPages,
          currentPage: data.data.number,
          currentItem: data.data.numberOfElements,
          number: data.data.number,
          first: data.data.first,
          last: data.data.last,
        }
        const { selectedProducts } = getState().stateSupplyOrder
        const selectedData = selectedProductInit || selectedProducts
        const productFormated = []
        const selectedItems = []
        let countedFinded = 0
        products.forEach((product) => {
          const finded = selectedData.find((el) => el.productDetailId === product.productDetailId)
          if (finded) {
            countedFinded += 1
            const select = {
              ...finded,
              productName: product.productName,
              productImage: product.productImage,
              productSku: product.productSku,
              productStock: product.productStock,
              checked: true,
            }
            productFormated.push(select)
            selectedItems.push(select)
          } else {
            productFormated.push({ ...product, checked: false, stockInput: 0 })
          }
        })
        dispatch(setIsCheckAll(countedFinded === products.length && products.length > 0))
        dispatch(setDataPaginationAdd(pagination))
        dispatch(setProducts(productFormated))
        if (selectedProductInit) {
          cb(selectedItems)
        }
      })
      .catch((error) => {
        callErrorMsg(error)
      })
      .finally(() => {
        dispatch(setIsLoading(false))
      })
  }

export const acthandleChangeStock = (product) => (dispatch, getState) => {
  const { products, selectedProducts } = getState().stateSupplyOrder
  const formated = []
  products.forEach((el) => {
    if (el.productDetailId === product.productDetailId) {
      formated.push(product)
    } else {
      formated.push(el)
    }
  })
  const selectedProductsUpdated = []
  selectedProducts.forEach((el) => {
    if (el.productDetailId === product.productDetailId) {
      selectedProductsUpdated.push(product)
    } else {
      selectedProductsUpdated.push(el)
    }
  })
  dispatch(setSelectedProducts(selectedProductsUpdated))
  dispatch(setProducts(formated))
}

export const acthandleCheck = (product) => (dispatch, getState) => {
  const { products, selectedProducts } = getState().stateSupplyOrder
  const { data, checkAll, selectedProductsUpdated } = handleCheckSo(
    products,
    product,
    selectedProducts,
  )
  dispatch(setIsCheckAll(checkAll))
  dispatch(setSelectedProducts(selectedProductsUpdated))
  dispatch(setProducts(data))
}

export const acthandleCheckAll = (check) => (dispatch, getState) => {
  const { products, selectedProducts } = getState().stateSupplyOrder
  const formated = []
  let selectedProductsUpdated = [...selectedProducts]
  products.forEach((el) => {
    formated.push({ ...el, checked: check })
    if (check) {
      const finded = selectedProductsUpdated.find(
        (elc) => elc.productDetailId === el.productDetailId,
      )

      if (!finded) {
        selectedProductsUpdated.push(el)
      }
    } else {
      selectedProductsUpdated = selectedProductsUpdated.filter(
        (pro) => pro.productDetailId !== el.productDetailId,
      )
    }
  })
  dispatch(setSelectedProducts(selectedProductsUpdated))
  dispatch(setIsCheckAll(check))
  dispatch(setProducts(formated))
}

export const acthandleSubmit = (history, id) => (dispatch, getState) => {
  const { selectedProducts } = getState().stateSupplyOrder
  const initForm = JSON.parse(localStorage.getItem('payload'))
  const { poRef, ...restPayload } = initForm
  const payloadSoReturn =
    restPayload.soType === 'Return'
      ? {
          locationDestinationId: poRef.vendorId,
          locationOriginId: poRef.destinationId,
          soReff: poRef.poNumber,
        }
      : {}
  const arr = []
  selectedProducts.forEach((el) => {
    arr.push({
      productId: el.productId,
      productStock: el.productStock,
      productRequestQuantity: el.stockInput,
      productStatusId: el.productInventoryStatusId,
      productStatusNoteId: el.productInventoryNoteId,
      productQrCode: el.productQrCode,
    })
  })
  let payload = {
    ...restPayload,
    ...payloadSoReturn,
    remarks: initForm?.remarks?.code,
    supplyOrderItems: arr,
  }
  if (id) {
    payload = {
      supplyOrderRequest: {
        ...restPayload,
        ...payloadSoReturn,
        remarks: initForm?.remarks?.code,
      },
      supplyOrderItemRequest: arr,
    }
  }
  dispatch(setIsLoading(true))
  const service = id ? putSupplyOrderGrand : postSupplyOrderGrand
  service(payload, id)
    .then(({ data }) => {
      const id = data.data.supplyOrderId
      history.replace(`/dashboard/supply-order-detail?edit=true#${id}`)
    })
    .catch((e) => {
      callErrorMsg(e, 'Supply order gagal dibuat')
    })
    .finally(() => {
      dispatch(setIsLoading(false))
    })
}

export const acthandleGetSupplyOrder = (query) => (dispatch) => {
  dispatch(setIsLoading(true))
  getSupplyOrder(query)
    .then(({ data }) => {
      const supplyOrders = data.content
      const pagination = {
        pageSize: data.size,
        totalData: data.totalElements,
        totalPage: data.totalPages,
        currentPage: data.number,
        currentItem: data.numberOfElements,
      }
      dispatch(setSupplyOrders(supplyOrders))
      dispatch(setPaginationDataSo(pagination))
      dispatch(setLastQuery(query))
    })
    .catch(() => {
      toastFailed('Terjadi kesalahn silahkan refresh halaman')
    })
    .finally(() => {
      dispatch(setIsLoading(false))
    })
}

export const actHandleGetSupplyOrder =
  (id, next = () => {}) =>
  (dispatch) => {
    dispatch(setIsLoading(true))
    return getSupplyOrderDetail(id)
      .then(({ data }) => {
        next(data)
      })
      .finally(() => {
        dispatch(setIsLoading(false))
      })
  }

export const actHandlePrint = (id) => (dispatch) => {
  dispatch(setIsLoading(true))
  return getPrintSupplyOrder(id)
    .then(({ data }) => {
      printJS(data.file_url)
    })
    .catch(() => {
      toastFailed('Data gagal didapatkan')
    })
    .finally(() => {
      dispatch(setIsLoading(false))
    })
}

export const actCheckQtySo = (id) => (dispatch) => {
  dispatch(setIsLoading(true))
  return getSupplyOrderDetail(id)
    .then(({ data }) => {
      dispatch(setSelectedSo(data))
    })
    .catch(() => {
      toastFailed('Data gagal didapatkan')
    })
    .finally(() => {
      dispatch(setIsLoading(false))
    })
}

export const actSubmitCheckQty =
  (payload, cb = () => {}) =>
  (dispatch, getState) => {
    dispatch(setIsLoading(true))
    const { selectedSo } = getState().stateSupplyOrder
    return putSupplyOrderConfirm(selectedSo.supply_order_id, payload)
      .then(() => {
        toastSuccess('Update data berhasil')
        cb()
      })
      .catch((err) => {
        callErrorMsg(err)
      })
      .finally(() => {
        dispatch(setIsLoading(false))
      })
  }

export const actEditSo = (id) => (dispatch) => {
  dispatch(setIsLoading(true))
  return getSupplyOrderDetail(id)
    .then(async ({ data }) => {
      await dispatch(setSelectedSo(data))
      const formatedProduct = []
      data.supply_order_items.forEach((el) => {
        formatedProduct.push({
          ...el,
          product_id: el.productId,
          stockInput: el.product_request_quantity,
        })
      })
      dispatch(setSelectedOriginHub({ location_id: data.location_origin_id }))
      dispatch(setSelectedProducts(formatedProduct))
      dispatch(
        actGetProducts(data.location_origin_id, '', formatedProduct, (selectedItems) => {
          // dispatch(setSelectedProducts(selectedItems));
        }),
      )
    })
    .catch(() => {
      toastFailed('Data gagal didapatkan')
    })
    .finally(() => {
      dispatch(setIsLoading(false))
    })
}

export const actCheckQtySoGrand =
  (id, cb = () => {}) =>
  (dispatch) => {
    dispatch(setIsLoading(true))
    return getSupplyOrderGrandPacking(id)
      .then(({ data }) => {
        cb(data)
      })
      .catch(({ response }) => {
        const message = response?.data?.error?.message || 'Data gagal didapatkan'

        toastFailed(message)
      })
      .finally(() => {
        dispatch(setIsLoading(false))
      })
  }

export const actSubmitCheckQtyGrand =
  (id, payload, cb = () => {}) =>
  (dispatch) => {
    dispatch(setIsLoading(true))
    return putSupplyOrderGrandPacking(id, payload)
      .then(() => {
        toastSuccess('Update data berhasil')
        cb()
      })
      .catch((err) => {
        callErrorMsg(err)
      })
      .finally(() => {
        dispatch(setIsLoading(false))
      })
  }

export const actValidate =
  (id, payload, cb = () => {}) =>
  (dispatch) => {
    dispatch(setIsLoading(true))
    return postValidateCheckQty(id, payload)
      .then(() => {})
      .catch((err) => {
        callErrorMsg(err)
      })
      .finally(() => {
        dispatch(setIsLoading(false))
      })
  }

export const actGetListOfReasons = (locationId, type, callback) => async (dispatch) => {
  try {
    const { data } = await getReasonsForSupplyOrder({ locationId, type })
    if (callback) {
      callback(data.data)
      return
    }
    dispatch(setReasonsSupplyOrder(data.data))
  } catch (err) {
    callErrorMsg(err)
  }
}

export const actGetListRouteMaster = (callback) => async (dispatch, getState) => {
  const { userData } = getState().stateAuth
  const query = {
    originLocationIDs: userData.location_roles.map(({ location_id }) => location_id).join(','),
    pageSize: 1000,
    active: 'true',
  }

  try {
    const {
      data: { data },
    } = await getRouteMaster(query)

    dispatch(setRouteMaster(data))
    if (typeof callback === 'function') {
      callback(data)
    }
  } catch (error) {
    callErrorMsg(error)
  }
}
