import type { RolesKeyType } from 'constant/roles'

export const pricingEngineRoles: RolesKeyType[] = [
  'AUTH_ADMIN',
  'AUTH_PROCUREMENT',
  'AUTH_SOURCING',
  'AUTH_SOURCING_MANAGER',
  'AUTH_HO_CATEGORY_HEAD_PL_FOOD',
  'AUTH_HO_CATEGORY_HEAD_PL_NON_FOOD',
  'AUTH_HO_CATEGORY_MANAGER_PL_FOOD',
  'AUTH_HO_CATEGORY_MANAGER_PL_NON_FOOD',
  'AUTH_HO_PRICING_HEAD',
  'AUTH_HO_PRICING_MANAGER',
  'AUTH_HO_PRICING_SPV',
  'AUTH_HO_PRICING_STAFF',
]

export const pricingEngineEditRoles: RolesKeyType[] = [
  'AUTH_ADMIN',
  'AUTH_HO_PRICING_HEAD',
  'AUTH_HO_PRICING_MANAGER',
  'AUTH_HO_PRICING_SPV',
  'AUTH_HO_PRICING_STAFF',
]

export const pricingEngineReviewRoles: RolesKeyType[] = [
  'AUTH_ADMIN',
  'AUTH_HO_PRICING_HEAD',
  'AUTH_HO_PRICING_MANAGER',
  'AUTH_HO_PRICING_SPV',
  'AUTH_HO_PRICING_STAFF',
]

export const pricingEngineRequestRoles: RolesKeyType[] = [
  'AUTH_ADMIN',
  'AUTH_PROCUREMENT',
  'AUTH_SOURCING',
  'AUTH_SOURCING_MANAGER',
  'AUTH_HO_CATEGORY_HEAD_PL_FOOD',
  'AUTH_HO_CATEGORY_HEAD_PL_NON_FOOD',
  'AUTH_HO_CATEGORY_MANAGER_PL_FOOD',
  'AUTH_HO_CATEGORY_MANAGER_PL_NON_FOOD',
  'AUTH_HO_PRICING_HEAD',
  'AUTH_HO_PRICING_MANAGER',
  'AUTH_HO_PRICING_SPV',
  'AUTH_HO_PRICING_STAFF',
]
