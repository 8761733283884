import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  LocationStateType,
  SetModalPayloadActionType,
  SetFormModalDeliveryConfigPayloadActionType,
} from 'features/Location/@types/location'
import { SLICE_NAME, fetchLocationList, fetchSLAConfig, updateSLAConfig } from './thunk'

const initialState: LocationStateType = {
  isLoading: false,
  modal: '',
  formModalDeliveryConfig: {
    startDate: '',
    startTime: '',
    endDate: '',
    endTime: '',
    instant: false,
    twoHourOnly: false,
    selectedLocation: [],
  },
  locationOptions: [],
}

const location = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset: () => initialState,
    setModal: (state, action: PayloadAction<SetModalPayloadActionType>) => {
      state.modal = action.payload.value
    },
    setFormDeliveryConfig: (
      state,
      action: PayloadAction<SetFormModalDeliveryConfigPayloadActionType>,
    ) => {
      const { name, value } = action.payload
      if (name === 'twoHourOnly' && value) {
        state.formModalDeliveryConfig.instant = false
      }
      if (name === 'instant' && value) {
        state.formModalDeliveryConfig.twoHourOnly = false
      }
      state.formModalDeliveryConfig[name] = value as never
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchLocationList.fulfilled, (state, action) => {
        state.locationOptions = action.payload.content.map((el) => ({
          name: `${el.location_id} - ${el.location_name}`,
          id: el.location_id,
        }))
      })
      .addCase(fetchSLAConfig.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchSLAConfig.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchSLAConfig.fulfilled, (state, action) => {
        state.isLoading = false
        if (action.payload.length) {
          const { end_date, start_date, instant, locations, regular } = action.payload[0]
          state.formModalDeliveryConfig = {
            endDate: end_date.split(' ')[0],
            endTime: end_date.split(' ')[1],
            startDate: start_date.split(' ')[0],
            startTime: start_date.split(' ')[1],
            instant,
            twoHourOnly: regular,
            selectedLocation: locations.map((el) => ({
              name: `${el.id} -  ${el.name}`,
              id: el.id,
            })),
          }
        }
      })
      .addCase(updateSLAConfig.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateSLAConfig.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(updateSLAConfig.fulfilled, (state) => {
        state.isLoading = false
        state.modal = ''
        state.formModalDeliveryConfig = initialState.formModalDeliveryConfig
      })
  },
})

export default location.reducer
export const { reset, setModal, setFormDeliveryConfig } = location.actions
