import { createAsyncThunk } from '@reduxjs/toolkit'
import { callErrorMsg } from 'helpers/errorMsg'
import {
  getLocations,
  getLocationList,
  getSLAConfig,
  putSLAConfig,
} from 'features/Location/services/location'

export const SLICE_NAME = 'location'

export const fetchGetLocations = createAsyncThunk(
  `${SLICE_NAME}/fetchGetLocations`,
  async (params: Parameters<typeof getLocations>[0], { rejectWithValue }) => {
    try {
      const res = await getLocations(params)

      return res.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const fetchLocationList = createAsyncThunk(
  `${SLICE_NAME}/fetchLocationList`,
  async (_, { rejectWithValue }) => {
    try {
      const res = await getLocationList({ params: { active: true, type: 'HUB' } })

      return res.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const fetchSLAConfig = createAsyncThunk(
  `${SLICE_NAME}/fetchSLAConfig`,
  async (_, { rejectWithValue }) => {
    try {
      const res = await getSLAConfig()

      return res.data.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const updateSLAConfig = createAsyncThunk(
  `${SLICE_NAME}/updateSLAConfig`,
  async (_, { rejectWithValue, getState }) => {
    const {
      location: { formModalDeliveryConfig },
    } = getState() as StoreStateType
    try {
      const validateStartDate =
        formModalDeliveryConfig.startDate && formModalDeliveryConfig.startTime
      const validateEndDate = formModalDeliveryConfig.endDate && formModalDeliveryConfig.endTime
      const res = await putSLAConfig({
        payload: {
          end_date: validateEndDate
            ? `${formModalDeliveryConfig.endDate} ${formModalDeliveryConfig.endTime}`
            : '',
          start_date: validateStartDate
            ? `${formModalDeliveryConfig.startDate} ${formModalDeliveryConfig.startTime}`
            : '',
          instant: formModalDeliveryConfig.instant,
          locations: formModalDeliveryConfig.selectedLocation.map((el) => el.id),
          regular: formModalDeliveryConfig.twoHourOnly,
        },
      })

      return res.data.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)
