import { lazy } from 'react'
import generateRouterDetail from 'utils/helpers/generateRouterDetail'
import { AreaListPageRBAC } from '../pages/AreaList/rbac'
import { AreaCreatePageRBAC, AreaEditPageRBAC } from '../pages/AreaAddEdit/rbac'

const RackList = lazy(() => import('../pages/RackList'))
const RackDetail = lazy(() => import('../pages/RackDetail'))
const RackAddEdit = lazy(() => import('../pages/RackAddEdit'))
const RackSingleEdit = lazy(() => import('../pages/RackSingleEdit'))

const AreaList = lazy(() => import('../pages/AreaList'))
const AreaAddEdit = lazy(() => import('../pages/AreaAddEdit'))

export const RackListRouter: RouterFeatureInterface<'RackListRouter', '/dashboard/rack'> = {
  name: 'RackListRouter',
  Component: RackList,
  path: '/dashboard/rack',
  routeType: 'PRIVATE',

  allowedRoles: [
    'AUTH_CENTRAL_SUPERVISOR',
    'AUTH_SUPPLY_CHAIN',
    'AUTH_CAPTAIN',
    'AUTH_EXPANSION',
    'AUTH_INBOUND_STAFF',
    'AUTH_OUTBOUND_STAFF',
    'AUTH_INVENTORY_CONTROLLER',
    'AUTH_HO_LP',
  ],
  isDashboard: true,
}

export const RackDetailRouter: RouterFeatureInterface<
  'RackDetailRouter',
  '/dashboard/rack/detail/:locationId'
> = {
  name: 'RackDetailRouter',
  Component: RackDetail,
  path: '/dashboard/rack/detail/:locationId',
  routeType: 'PRIVATE',

  allowedRoles: [
    'AUTH_CENTRAL_SUPERVISOR',
    'AUTH_SUPPLY_CHAIN',
    'AUTH_CAPTAIN',
    'AUTH_EXPANSION',
    'AUTH_INBOUND_STAFF',
    'AUTH_OUTBOUND_STAFF',
    'AUTH_INVENTORY_CONTROLLER',
  ],
  isDashboard: true,
}

export const RackAddRouter: RouterFeatureInterface<'RackAddRouter', '/dashboard/rack/add'> = {
  name: 'RackAddRouter',
  Component: RackAddEdit,
  path: '/dashboard/rack/add',
  routeType: 'PRIVATE',
  allowedRoles: [
    'AUTH_CENTRAL_SUPERVISOR',
    'AUTH_INVENTORY_CONTROLLER',
    'AUTH_CAPTAIN',
    'AUTH_EXPANSION',
  ],
  isDashboard: true,
}

export const RackEditRouter: RouterFeatureInterface<'RackEditRouter', '/dashboard/rack/edit/:id'> =
  {
    name: 'RackEditRouter',
    Component: RackAddEdit,
    path: '/dashboard/rack/edit/:id',
    routeType: 'PRIVATE',
    allowedRoles: [
      'AUTH_CENTRAL_SUPERVISOR',
      'AUTH_INVENTORY_CONTROLLER',
      'AUTH_CAPTAIN',
      'AUTH_EXPANSION',
    ],
    isDashboard: true,
  }

export const RackSingleEditRouter: RouterFeatureInterface<
  'RackSingleEditRouter',
  '/dashboard/rack/:locationId/edit/:rackId'
> = {
  name: 'RackSingleEditRouter',
  Component: RackSingleEdit,
  path: '/dashboard/rack/:locationId/edit/:rackId',
  routeType: 'PRIVATE',
  allowedRoles: [
    'AUTH_CENTRAL_SUPERVISOR',
    'AUTH_INVENTORY_CONTROLLER',
    'AUTH_CAPTAIN',
    'AUTH_EXPANSION',
  ],
  isDashboard: true,
}

export const AreaListRouter: RouterFeatureInterface<'AreaListRouter', '/dashboard/area'> = {
  name: 'AreaListRouter',
  Component: AreaList,
  path: '/dashboard/area',
  routeType: 'PRIVATE',
  rbacPageID: AreaListPageRBAC.pageID,
  allowedRoles: [],
  isDashboard: true,
}

export const AreaAddRouter: RouterFeatureInterface<'AreaAddRouter', '/dashboard/area/add'> = {
  name: 'AreaAddRouter',
  Component: AreaAddEdit,
  path: '/dashboard/area/add',
  routeType: 'PRIVATE',
  rbacPageID: AreaCreatePageRBAC.pageID,
  allowedRoles: [],
  isDashboard: true,
}

export const AreaEditRouter: RouterFeatureInterface<'AreaEditRouter', '/dashboard/area/edit/:id'> =
  {
    name: 'AreaEditRouter',
    Component: AreaAddEdit,
    path: '/dashboard/area/edit/:id',
    routeType: 'PRIVATE',
    rbacPageID: AreaEditPageRBAC.pageID,
    allowedRoles: [],
    isDashboard: true,
  }

const RackRouter = [
  RackListRouter,
  RackDetailRouter,
  RackAddRouter,
  RackEditRouter,
  // RackSingleEditRouter,
  AreaListRouter,
  AreaAddRouter,
  AreaEditRouter,
]

export const RackRouterDetail = generateRouterDetail(RackRouter)

export default RackRouter
