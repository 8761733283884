import { memo } from 'react'
import styled from 'styled-components'
import { Checkbox, SystemIcon, RowAddSupplyOrder, NoData } from 'components'

function TableAddSupplyOrder({
  soType,
  data,
  handleChangeStock,
  handleCheck,
  isCheckAll,
  handleCheckAll,
}) {
  return (
    <>
      <Container>
        <thead>
          <Tr>
            <Th ratio="1">
              <Checkbox checked={isCheckAll} onChange={handleCheckAll} />
            </Th>
            <Th ratio="5">
              NAMA PRODUK
              <SystemIcon iconName="up-and-down" fontSize="smallest" className="ml-3" />
            </Th>
            <Th ratio="3">NOMOR SKU</Th>
            {soType?.toLowerCase() === 'bad stock' && <Th ratio="3">TANGGAL EXPIRED</Th>}
            <Th ratio="2">STOCK</Th>
            <Th ratio="2">SLOC</Th>
            <Th ratio="2">JUMLAH</Th>
            <Th ratio="2">STATUS</Th>
            <Th ratio="2">CATATAN</Th>
          </Tr>
        </thead>
        <tbody>
          {data.map((el) => (
            <RowAddSupplyOrder
              soType={soType}
              data={el}
              key={el.productDetailId}
              handleChangeStock={handleChangeStock}
              handleCheck={handleCheck}
            />
          ))}
        </tbody>
      </Container>
      {data.length === 0 && <NoData />}
    </>
  )
}

export default memo(TableAddSupplyOrder)

const Container = styled.table`
  width: 100%;
`
const Tr = styled.tr`
  display: flex;
  padding: 16px 32px;
  background-color: ${({ theme: { colors } }) => colors.tableHead};
`
const Th = styled.th`
  color: ${({ theme: { colors } }) => colors.grey};
  /* font-weight: ${({ theme: { fontWeights } }) => fontWeights.light}; */
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  flex: ${({ ratio }) => ratio};
  text-align: start;
`
