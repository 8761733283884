import React, { useState, useEffect, useMemo, memo, useRef, Fragment, ReactNode } from 'react'
import styled, { css, CSSProperties } from 'styled-components'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Typography,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  LoadingIndicator,
  Box,
  Divider,
} from '@astro-ui/components'
import { Select as MuiSelect, SelectProps as MuiSelectProps } from '@mui/material'

import {
  NoData,
  RenderMultipleValue,
  RenderValue,
  ResetIcon,
  SearchField,
  SelectAll,
  SelectNone,
} from './components'

type OptionObjType = {
  [key: string]: unknown
}

type OptionNameType = {
  [key: string]: string
}

export type SelectPropsType =
  | Omit<MuiSelectProps, 'value' | 'onChange' | 'error' | 'onClose'> &
      (
        | {
            multiple: true
            value: string[] | OptionObjType[]
          }
        | {
            multiple?: false
            value: string | OptionObjType | null
          }
      ) & {
        disableAutoSelectedOnClick?: boolean
        options: OptionObjType[] | string[]
        displayKey?: string
        multipleProps?: {
          valueType?: 'chip' | 'total'
          suffixTotal?: string
          hideSelectAll?: boolean
          selectAllText?: string
        }
        supportText?: string
        errorMessage?: string
        withSearch?: boolean
        searchPlaceholder?: string
        withReset?: boolean
        onClear?: () => void
        onChange?: (value: never) => void
        onSearch?: (value: string) => void
        onClose?: (value?: never) => void
        labelStyle?: CSSProperties
        CustomRenderOption?: React.FC<{ option: never }>
        dataNotFoundText?: string
        onOpenAction?: () => void
        isLoading?: boolean
        menuItemStyle?: CSSProperties
        CustomRenderSelectedValue?: (value: SelectPropsType['value'] | undefined) => ReactNode
        isGroupedBy?: string
        limit?: number
      }

export const generateOptions = (options: SelectPropsType['options']) => {
  const isArrOfStr = typeof options[0] === 'string'
  return isArrOfStr ? options.map((val) => ({ name: val })) : (options as OptionObjType[])
}

export const generateValue = (
  val: SelectPropsType['value'],
  displayKey: string,
): string | string[] => {
  const isArrayOfObject = Array.isArray(val) && typeof val[0] === 'object'
  const isObjectKeyExist = typeof val === 'object' && (val as OptionNameType)?.[displayKey]

  if (isArrayOfObject) return (val as OptionNameType[]).map((item) => item[displayKey])
  if (isObjectKeyExist) return (val as OptionNameType)[displayKey]

  return (val || '') as string | string[]
}

const Select = ({
  required,
  label,
  supportText,
  errorMessage,
  fullWidth,
  children,
  placeholder,
  value,
  withSearch,
  withReset,
  onClear,
  disableAutoSelectedOnClick,
  options = [],
  displayKey = 'name',
  multipleProps,
  onChange,
  onSearch,
  disabled,
  multiple,
  labelStyle,
  dataNotFoundText,
  CustomRenderOption,
  onOpen,
  onOpenAction,
  isLoading,
  menuItemStyle,
  onClose,
  searchPlaceholder,
  CustomRenderSelectedValue,
  isGroupedBy,
  limit,
  ...rest
}: SelectPropsType) => {
  const refSearch = useRef<ReturnType<typeof setTimeout>>()

  const [isOpen, setIsOpen] = useState(false)
  const [selected, setSelected] = useState<string | string[]>(generateValue(value, displayKey))
  const [search, setSearch] = useState('')

  const showResetIcon = !isOpen && !multiple && !!selected && !disabled
  const isAllSelected = !!multiple && options.length > 0 && selected.length === options.length
  const isIndeterminate = !!multiple && selected.length > 0 && selected.length < options.length

  const listOptions = useMemo(() => {
    const list = generateOptions(options)

    if (search && !onSearch) {
      return list.filter((item) =>
        (item[displayKey] as string)
          .toString()
          .toLowerCase()
          .includes(search.toString().toLowerCase()),
      )
    }

    return list
  }, [search, onSearch, options, displayKey])

  const handleOpen: SelectPropsType['onOpen'] = async (e) => {
    if (onOpen) await onOpen(e)
    setIsOpen(true)
    if (onOpenAction) onOpenAction()
  }

  const handleClose = () => {
    if (onClose) onClose()
    setIsOpen(false)
  }

  const handleCheckedMultiple = (key: string) => {
    let checked: string[] = []
    const val = [...(selected as string[])]
    const isExist = val.some((item) => item === key)

    if (key === 'ALL') {
      checked =
        val.length === options.length
          ? []
          : listOptions.map((item) => (item as OptionNameType)[displayKey])
    } else if (isExist) {
      checked = val.filter((item) => item !== key)
    } else {
      checked = [...val, key]
    }

    setSelected(checked)

    if (typeof onChange === 'function') {
      const isArrOfStr = typeof options[0] === 'string'
      const result = isArrOfStr
        ? (options as string[]).filter((opts) => checked.includes(opts))
        : (options as OptionNameType[]).filter((opts) => checked.includes(opts[displayKey]))

      onChange(result as never)
    }
  }

  const handleRemoveChip = (key: string) => {
    handleCheckedMultiple(key)
  }

  const handleSelected = (val: string, item?: never) => {
    if (multiple) {
      handleCheckedMultiple(val)
    } else {
      if (!disableAutoSelectedOnClick) setSelected(val)
      handleClose()

      if (typeof onChange === 'function') {
        if (item) {
          const isArrOfStr = typeof options[0] === 'string'
          const result = isArrOfStr ? item[displayKey] : item

          onChange(result)
        } else {
          onChange(null as never)
        }
      }
    }
  }

  const handleClear = () => {
    handleClose()
    setSelected(multiple ? [] : '')
    if (withSearch && !required) setSearch('')
    if (onClear) onClear()
    else if (onChange) onChange((multiple ? [] : '') as never)
  }

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value
    setSearch(val)

    if (typeof onSearch === 'function') {
      clearTimeout(refSearch.current)
      refSearch.current = setTimeout(() => {
        onSearch(val)
      }, 500)
    }
  }

  const groupByKey = (opts: OptionObjType[], groupKey: string) => {
    const grouped: {
      [key: string]: OptionObjType[]
    } = {}

    opts.forEach((item) => {
      const keyValue = item[groupKey] as never
      if (!grouped[keyValue]) {
        grouped[keyValue] = []
      }
      grouped[keyValue].push(item)
    })

    return Object.keys(grouped).map((key) => ({
      groupedBy: key,
      data: grouped[key],
    }))
  }

  useEffect(() => {
    setSelected(generateValue(value, displayKey))
  }, [displayKey, value])

  const renderOption = (opts: OptionObjType[]) =>
    opts.map((item, index) => {
      const name = (item as OptionNameType)[displayKey]
      const key = `${name}-${index}`

      return (
        <MenuItem
          key={key}
          onClick={() => handleSelected(name, item as never)}
          isSelected={name === selected}
          disabled={!!(item as { disabled?: boolean })?.disabled}
          {...(isGroupedBy && {
            style: {
              border: 'none',
            },
          })}
        >
          <ListItemText>
            {CustomRenderOption ? (
              <CustomRenderOption option={listOptions[index] as never} />
            ) : (
              <Typography variant="body2">{name}</Typography>
            )}
          </ListItemText>
          {multiple && (
            <ListItemIcon>
              <Checkbox checked={selected.includes(name)} />
            </ListItemIcon>
          )}
        </MenuItem>
      )
    })

  const renderMenuItem = () => {
    if (isGroupedBy) {
      return groupByKey(listOptions, isGroupedBy).map(({ groupedBy, data }) => (
        <Fragment key={groupedBy}>
          <Box p="4px 16px">
            <Typography fontSize="14px" fontWeight={700}>
              {groupedBy}
            </Typography>
          </Box>
          {renderOption(data)}
          <Divider />
        </Fragment>
      ))
    }

    return renderOption(limit ? listOptions.slice(0, limit) : listOptions)
  }

  return (
    <FormControl
      variant="standard"
      required={required}
      error={!!errorMessage}
      fullWidth={fullWidth}
    >
      {label && <InputLabel style={labelStyle}>{label}</InputLabel>}

      <SelectStyled
        displayEmpty
        disableUnderline
        endAdornment={<ResetIcon show={!!withReset && showResetIcon} onClick={handleClear} />}
        error={!!errorMessage}
        disabled={disabled}
        multiple={multiple}
        value={selected}
        renderValue={(val) => {
          if (typeof CustomRenderSelectedValue === 'function' && CustomRenderSelectedValue(value)) {
            return CustomRenderSelectedValue(value)
          }
          return multiple ? (
            <RenderMultipleValue
              value={val as string[]}
              placeholder={placeholder}
              onDelete={handleRemoveChip}
              multipleProps={multipleProps}
            />
          ) : (
            <RenderValue value={val as string} placeholder={placeholder} />
          )
        }}
        open={isOpen}
        onOpen={handleOpen}
        onClose={handleClose}
        {...rest}
      >
        {withSearch && (
          <SearchField placeholder={searchPlaceholder} search={search} onChange={handleSearch} />
        )}

        {isLoading ? (
          <Box width="30px" height="30px" margin="40px auto">
            <LoadingIndicator size={30} />
          </Box>
        ) : (
          <>
            {multiple && !multipleProps?.hideSelectAll && (
              <SelectAll
                display={options.length === listOptions.length ? 'flex' : 'none'}
                selectAllText={multipleProps?.selectAllText}
                checked={isAllSelected}
                indeterminate={isIndeterminate}
                onClick={() => handleSelected('ALL')}
              />
            )}

            {!required && <SelectNone onClick={handleClear} />}

            {renderMenuItem()}

            {!listOptions.length && <NoData dataNotFoundText={dataNotFoundText} />}
          </>
        )}
      </SelectStyled>

      {(!!errorMessage || supportText) && (
        <FormHelperText>{errorMessage || supportText}</FormHelperText>
      )}
    </FormControl>
  )
}

export default memo(Select)
export const SelecteBase = MuiSelect
export type SelectBaseType = MuiSelectProps

export const SelectStyled = styled(MuiSelect)<MuiSelectProps>`
  ${({ theme }) => css`
    label + & {
      margin-top: 16px;
    }
    & .MuiInputBase-input {
      border: 1px solid ${theme.colors.grey3};
      border-radius: 8px;
      background-color: ${theme.colors.grey0};
      padding: 9px 16px;
      padding-right: 42px !important;
      color: ${theme.colors.grey9};
      font-family: ${theme.fontFamily.nunitoSans};
      font-size: 14px;
      ::placeholder {
        line-height: 25px;
        color: ${theme.colors.grey5};
      }
    }
    & .Mui-disabled {
      background-color: ${theme.colors.grey1};
    }
    & .MuiSelect-icon {
      right: 10px;
    }
    & .MuiChip-label {
      color: ${theme.colors.grey6};
    }
  `}
`
