import { Get, Put, Post } from 'config/apiServiceConfig'
import { LocationType, RackItemType } from 'features/Rack/@types/rack'

interface AddNewMultipleRackRequestType {
  storageType: number
  storageEnvironment: number
  locationId: number
  maxQuantity: number
  maxVolume: number
  maxWeight: number
  maxVolumeUom: string
  maxQuantityUom: string
  maxWeightUom: string
  racks: {
    rack_name: string
    position: number
  }[]
}

interface AddNewMultipleRackResponseType {
  success: {
    id: number
    rackName: string
    locationId: number
    position: number
    active: boolean
    createdBy: string
    updatedBy: string
    maxVolume: number
    maxVolumeUom: string
    maxQuantity: number
    maxQuantityUom: string
    maxWeight: number
    maxWeightUom: string
    mixAttribute: boolean
    mixProduct: boolean
    storageEnvironment: number
    storageType: number
    productId: number
  }[]
}

export interface GetDetailRackMultipleResponseType {
  data: {
    location: LocationType
    racks: {
      content: RackItemType[]
      totalElements: number
      totalPages: number
      numberOfElements: number
    }
  }
}

export interface UpdateSingleRackRequestType {
  storageType: number
  storageEnvironment: number
  maxVolume: number
  maxQuantity: number
  maxWeight: number
  maxVolumeUom: string
  maxQuantityUom: string
  maxWeightUom: string
  racks: {
    rack_name: string
    position: number
    id: number
  }[]
  active: boolean
}

export const getDetailRackMultipleByLocationId = (locationId: number) =>
  Get<GetDetailRackMultipleResponseType>({ url: `/api/racks/v2/${locationId}` })

export const addNewMultipleRack = (locationId: number, data: AddNewMultipleRackRequestType) =>
  Post<AddNewMultipleRackResponseType>({ url: `/api/racks/${locationId}`, data })

export const updateMultipleRack = (locationId: number, data: AddNewMultipleRackRequestType) =>
  Put<AddNewMultipleRackResponseType>({ url: `/api/racks/${locationId}`, data })

export const getRackDetail = (rackId: number) =>
  Get<{ data: RackItemType }>({ url: `/api/racks/single/${rackId}` })

export const putUpdateSingleRack = (locationId: number, data: UpdateSingleRackRequestType) =>
  Put<{ data: RackItemType }>({ url: `/api/racks/single/${locationId}`, data })
