import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  ProductInventoryStatusType,
  ProductStockHistoryV1Type,
  ProductStockHistoryV2Type,
} from 'features/Product/@types/Product'

export const versionList = [
  {
    value: 'v2',
    name: 'Setelah 3 Oktober',
  },
  {
    value: 'v1',
    name: 'Sebelum 3 Oktober',
  },
]

export type ProductStockHistorySliceType = {
  isLoading: boolean
  inventoryStatusList: ProductInventoryStatusType[]
  stockHistoryListV1: ProductStockHistoryV1Type[]
  stockHistoryListV2: ProductStockHistoryV2Type[]
  selectedVersion: {
    value: string
    name: string
  }
  query: {
    pageIndex: number
    pageSize: number
    productId: number
    locationId: number
    createdDateFrom?: number | null
    createdDateTo?: number | null
    statusId?: number
  }
  selectedDateFrom: Date | null
  selectedDateTo: Date | null
  totalData: number
}

const initialState: ProductStockHistorySliceType = {
  isLoading: false,
  inventoryStatusList: [],
  stockHistoryListV1: [],
  stockHistoryListV2: [],
  selectedVersion: versionList[0],
  query: {
    pageIndex: 1,
    pageSize: 20,
    productId: 0,
    locationId: 0,
    statusId: 1,
  },
  selectedDateFrom: null,
  selectedDateTo: null,
  totalData: 0,
}

const productStockHistorySlice = createSlice({
  name: 'productStockHistory',
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setQuery: (state, action: PayloadAction<Partial<ProductStockHistorySliceType['query']>>) => {
      state.query = {
        ...state.query,
        ...action.payload,
      }
    },
    setSelectedVersion: (
      state,
      action: PayloadAction<ProductStockHistorySliceType['selectedVersion']>,
    ) => {
      state.selectedVersion = action.payload
    },
    setInventoryStatusList: (state, action: PayloadAction<ProductInventoryStatusType[]>) => {
      state.inventoryStatusList = action.payload
    },
    setStockHistoryV1OnSuccess: (
      state,
      action: PayloadAction<{ listData: ProductStockHistoryV1Type[]; totalData: number }>,
    ) => {
      const { listData, totalData } = action.payload
      state.stockHistoryListV1 = listData
      state.totalData = totalData
    },
    setStockHistoryV2OnSuccess: (
      state,
      action: PayloadAction<{ listData: ProductStockHistoryV2Type[]; totalData: number }>,
    ) => {
      const { listData, totalData } = action.payload
      state.stockHistoryListV2 = listData
      state.totalData = totalData
    },
    setSelectedDateRange: (state, action) => {
      const { dateFrom, dateTo } = action.payload
      state.selectedDateFrom = dateFrom
      state.selectedDateTo = dateTo
    },
    resetStateProductHistory: () => initialState,
  },
})

export const {
  setIsLoading,
  setQuery,
  setSelectedVersion,
  setInventoryStatusList,
  setStockHistoryV1OnSuccess,
  setStockHistoryV2OnSuccess,
  setSelectedDateRange,
  resetStateProductHistory,
} = productStockHistorySlice.actions
export default productStockHistorySlice.reducer
