import {
  SET_TYPE_PRINT_AREA,
  SET_IS_FAIL_PRINT_AREA,
  SET_IS_START_PRINT_AREA,
  SET_DATA_RESI_ORDER_PACKER_WAITING_DRIVER_PRINT_AREA,
  SET_DATA_RESI_ORDER_PICKER_PICKING_AREA,
  SET_IS_READY_PRINT_PRINT_AREA,
  SET_IMAGE_BARCODE_BLOB_PRINT_AREA,
  SET_DATA_SUPPLY_ORDER_PRINT_PRINT_AREA,
  SET_SELECTED_DATA_SO_PRINT_AREA,
  SET_DATA_SUPPLY_ORDER_CETAK_SURAT_JALAN_PRINT_AREA,
  SET_DATA_INBOUND_SO_PRINT_AREA,
  SET_SELECTED_DATA_INBOUND_SO_PRINT_AREA,
  SET_DATA_PO_LITE_QR_PRINT_AREA,
  SET_PO_LITE_QR_QTY_PRINT_AREA,
  SET_IMAGE_QRCODE_BLOB_PRINT_AREA,
  SET_DATA_PO_LITE_CREATE_PO_PRINT_AREA,
  SET_PRINT_CONTENT,
  SET_DATA_DELIVERY_NOTE_BULK,
  SET_DATA_RELABEL,
  SET_DATA_ASTRO_KITCHEN,
  SET_DATA_GIFT_NOTE,
  SET_VENDOR_ID,
} from 'storeContext/actionsType/printArea'

export const initialPrintArea = {
  isLoading: false,
  isReadyPrint: false,
  typeToBePrinted: '',
  imageBarcodeBlob: '',
  dataResiOrderPackerWaitingDriver: {},
  dataResiOrderPickerPicking: {},
  dataDeliveryNoteBulk: [],
  dataCetakListSO: {},
  selectedDataSO: {},
  dataCetakSuratJalan: {},
  dataCetakInboundSO: {},
  selectedDataInboundSO: {},
  dataPOLiteCetakQR: {},
  POLiteQRQty: 0,
  POLiteQRImageBlob: '',
  dataPOLiteCreatePO: {},
  printContent: {},
  dataRelabel: {},
  astroKitchenData: {},
  dataGiftNote: {},
  vendorId: 0,
}

export default function printArea(state = initialPrintArea, { type, payload }) {
  switch (type) {
    case SET_IS_READY_PRINT_PRINT_AREA:
      return {
        ...state,
        isReadyPrint: payload,
      }
    case SET_IS_START_PRINT_AREA:
      return {
        ...state,
        isLoading: true,
      }
    case SET_IS_FAIL_PRINT_AREA:
      return {
        ...state,
        isLoading: false,
      }
    case SET_TYPE_PRINT_AREA:
      return {
        ...state,
        typeToBePrinted: payload,
      }
    case SET_DATA_RESI_ORDER_PACKER_WAITING_DRIVER_PRINT_AREA:
      return {
        ...state,
        dataResiOrderPackerWaitingDriver: payload,
      }
    case SET_DATA_RESI_ORDER_PICKER_PICKING_AREA:
      return {
        ...state,
        dataResiOrderPickerPicking: payload,
      }

    case SET_DATA_DELIVERY_NOTE_BULK:
      return {
        ...state,
        dataDeliveryNoteBulk: payload,
      }

    case SET_IMAGE_BARCODE_BLOB_PRINT_AREA:
      return {
        ...state,
        imageBarcodeBlob: payload,
      }
    case SET_DATA_SUPPLY_ORDER_PRINT_PRINT_AREA:
      return {
        ...state,
        dataCetakListSO: payload,
      }
    case SET_SELECTED_DATA_SO_PRINT_AREA:
      return {
        ...state,
        selectedDataSO: payload,
      }
    case SET_DATA_SUPPLY_ORDER_CETAK_SURAT_JALAN_PRINT_AREA:
      return {
        ...state,
        dataCetakSuratJalan: payload,
      }

    case SET_DATA_INBOUND_SO_PRINT_AREA:
      return {
        ...state,
        dataCetakInboundSO: payload,
      }

    case SET_SELECTED_DATA_INBOUND_SO_PRINT_AREA:
      return {
        ...state,
        selectedDataInboundSO: payload,
      }
    case SET_DATA_PO_LITE_QR_PRINT_AREA:
      return {
        ...state,
        dataPOLiteCetakQR: payload,
      }
    case SET_PO_LITE_QR_QTY_PRINT_AREA:
      return {
        ...state,
        POLiteQRQty: payload,
      }
    case SET_IMAGE_QRCODE_BLOB_PRINT_AREA:
      return {
        ...state,
        POLiteQRImageBlob: payload,
      }
    case SET_DATA_PO_LITE_CREATE_PO_PRINT_AREA:
      return {
        ...state,
        dataPOLiteCreatePO: payload,
      }

    case SET_PRINT_CONTENT:
      return {
        ...state,
        printContent: payload,
      }
    case SET_DATA_RELABEL:
      return {
        ...state,
        dataRelabel: payload,
      }
    case SET_DATA_ASTRO_KITCHEN:
      return {
        ...state,
        astroKitchenData: payload,
      }
    case SET_DATA_GIFT_NOTE:
      return {
        ...state,
        dataGiftNote: payload,
      }
    case SET_VENDOR_ID: {
      return {
        ...state,
        vendorId: payload,
      }
    }
    default:
      return state
  }
}
