import { memo, useContext } from 'react'
import styled from 'styled-components'
import { Pill, Typography } from '@astro-ui/components'
import store from 'storeContext'
import { formatMoneyRMG } from 'helpers'
import { styleBusinessTagging } from 'features/Product/constant/product'

const widthList = {
  w2: '20',
  w3: '37',
  w4: '10',
  w5: '9',
  w6: '9',
  w7: '15',
}

function TableMain() {
  const {
    state: {
      dataPOLiteCreatePO: { items },
    },
  } = useContext(store.PrintAreaContext)

  return (
    <Container>
      <thead>
        <Tr style={{ backgroundColor: '#E9E9E9' }}>
          <Th wd={widthList.w2}>Nomor SKU</Th>
          <Th wd={widthList.w3}>Nama Produk</Th>
          <Th wd={widthList.w4}>Jumlah PO</Th>
          <Th wd={widthList.w5}>Harga Satuan</Th>
          <Th wd={widthList.w6}>Harga Diskon</Th>
          <Th wd={widthList.w7}>Total</Th>
        </Tr>
      </thead>
      <tbody>
        {items?.map((el, i) => (
          <Row data={el} key={'el.supply_order_item_id' + i} idx={i} />
        ))}
      </tbody>
    </Container>
  )
}

function Row({ data, idx }) {
  return (
    <TrRow>
      <Td wd={widthList.w2}>
        <Typography variant="body2">{data?.product_sku}</Typography>
      </Td>
      <Td wd={widthList.w3}>
        <Typography variant="body2">{data?.product_name}</Typography>
        {data.business_taggings.length > 0 &&
          data.business_taggings.map((taggingItem) => (
            <Pill
              key={taggingItem.key}
              content={taggingItem.value}
              sx={{ marginTop: '5px', ...styleBusinessTagging[taggingItem.key] }}
            />
          ))}
      </Td>

      <Td wd={widthList.w4}>
        <Typography variant="body2">{data?.incoming_quantity}</Typography>
      </Td>
      <Td wd={widthList.w5}>
        <Typography variant="body2">
          {formatMoneyRMG(data?.buy_price ? data?.buy_price : 0, '') || '-'}
        </Typography>
      </Td>
      <Td wd={widthList.w6}>
        <Typography variant="body2">{formatMoneyRMG(data?.discounted_price, '') || '-'}</Typography>
      </Td>
      <Td wd={widthList.w7}>
        <Typography variant="body2">{formatMoneyRMG(Number(data.total), '')}</Typography>
      </Td>
    </TrRow>
  )
}
export default memo(TableMain)

const TrRow = styled.tr`
  background-color: ${({ theme: { colors } }) => colors.white};
  align-items: center;
  display: flex;
`
const Td = styled.td`
  color: ${({ theme: { colors } }) => colors.black};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  padding: 12px;
  text-align: start;
  width: ${({ wd }) => {
    return `${wd}%`
  }};
`

const Container = styled.table`
  width: 100%;
  margin-top: 20px;
  border: 1px solid #d3d3d3;
  border-radius: 8px;
`

const Tr = styled.tr`
  background-color: ${({ theme: { colors } }) => colors.tableHead};
  display: flex;
`
const Th = styled.th`
  color: #212121;
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  text-align: start;
  padding: 16px 12px;
  width: ${({ wd }) => {
    return `${wd}%`
  }};
`
