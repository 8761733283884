/* eslint-disable @typescript-eslint/naming-convention */
import { AppDispatchType, RootStateType } from 'store'
import {
  getProductInventoryStatus,
  GetProductStockHistoryV2ResponseType,
  getProductStockHistory,
  getProductStockHistoryV2,
} from 'features/Product/services/product'
import { callErrorMsg } from 'helpers/errorMsg'
import { AxiosResponse } from 'axios'
import {
  ProductStockHistorySliceType,
  setIsLoading,
  setInventoryStatusList,
  setStockHistoryV1OnSuccess,
  setStockHistoryV2OnSuccess,
} from './slice'

export const fetchInventoryStatus = () => async (dispatch: AppDispatchType) => {
  try {
    dispatch(setIsLoading(true))
    const { data } = await getProductInventoryStatus()
    dispatch(setInventoryStatusList(data.data.status))
  } catch (err) {
    callErrorMsg(err)
  } finally {
    dispatch(setIsLoading(false))
  }
}

const getQueryParamsForStockHistoryV1 = (query: ProductStockHistorySliceType['query']) => ({
  pageIndex: query.pageIndex,
  pageSize: query.pageSize,
  productId: query.productId,
  locationId: query.locationId,
  ...(query?.createdDateFrom && { createdDateFrom: query.createdDateFrom }),
  ...(query?.createdDateTo && { createdDateTo: query.createdDateTo }),
  ...(query?.statusId?.toString() !== '0' && { statusId: query.statusId }),
})

export const fetchStockHistoryV1 =
  () => async (dispatch: AppDispatchType, getState: () => RootStateType) => {
    try {
      dispatch(setIsLoading(true))

      const { query } = getState().productStockHistory
      const params = getQueryParamsForStockHistoryV1(query)

      const { data } = await getProductStockHistory(params)
      dispatch(
        setStockHistoryV1OnSuccess({
          listData: data.data,
          totalData: data.pagination.numberOfElements,
        }),
      )
    } catch (err) {
      callErrorMsg(err)
    } finally {
      dispatch(setIsLoading(false))
    }
  }

const getQueryParamsForStockHistoryV2 = (query: ProductStockHistorySliceType['query']) => ({
  'pagination.page_size': query.pageSize,
  'pagination.page_index': query.pageIndex,
  product_id: query.productId,
  location_id: query.locationId,
  ...(query?.createdDateFrom && { created_date_from: query.createdDateFrom }),
  ...(query?.createdDateTo && { created_date_to: query.createdDateTo }),
  ...(query?.statusId?.toString() !== '0' && { status_id: query.statusId }),
})

export const fetchStockHistoryV2 =
  () => async (dispatch: AppDispatchType, getState: () => RootStateType) => {
    try {
      dispatch(setIsLoading(true))

      const { query } = getState().productStockHistory
      const params = getQueryParamsForStockHistoryV2(query)

      const { data } = (await getProductStockHistoryV2(
        params,
      )) as unknown as AxiosResponse<GetProductStockHistoryV2ResponseType>
      dispatch(
        setStockHistoryV2OnSuccess({
          listData: data.data,
          totalData: data.pagination.number_of_elements,
        }),
      )
    } catch (err) {
      callErrorMsg(err)
    } finally {
      dispatch(setIsLoading(false))
    }
  }

export const fetchStockHistory =
  () => (dispatch: AppDispatchType, getState: () => RootStateType) => {
    const { selectedVersion } = getState().productStockHistory

    if (selectedVersion.value === 'v2') {
      dispatch(fetchStockHistoryV2())
      return
    }
    dispatch(fetchStockHistoryV1())
  }
