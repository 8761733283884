import { memo, useContext } from 'react'
import styled from 'styled-components'
import {
  TemplateOrderPackerPacking,
  TemplateOrderPackerWaitingDriver,
  TemplateOrderPickerPicking,
  TemplateInboundPo,
  TemplateSupplyOrderPickingList,
  TemplateInboundCompleteSO_v2,
  TemplateDeliveryOrders,
  TemplateCetakQR,
  TemplatePurchaseOrderLite,
  TemplateCetakPackageID,
  TemplatePrintPackageIdMobile,
  TemplateSupplyOrderDeliveryNote,
  TemplatePrintQR,
  TemplateFPOPrintBarcode,
  TemplateCetakKoliID,
  TemplateCetakSupplyOrderCheckQty,
  TemplatePrintSTO,
  TemplatePrintSTOBeritaAcara,
  TemplateRelabelAstroGoods,
  TemplateGrabMart,
  TemplateAstroKitchen,
  TemplateSupplyOrderDeliveryNoteOld,
  TemplateGiftNote,
  TemplateKitchenModifierReceipt,
  TemplatePriceTagPrint,
} from './component'
import store from 'storeContext'
import TypeTemplateList from 'helpers/typeTemplateList'
import { usePrintArea } from './usePrintArea'

function PrintArea() {
  const { state: statePrintArea } = useContext(store.PrintAreaContext)
  const {
    state: { typeTobePrinted },
  } = usePrintArea()

  const renderTemplate = () => {
    switch (statePrintArea.typeToBePrinted || typeTobePrinted) {
      case TypeTemplateList.OrderPackerWaitingDriver:
        return <TemplateOrderPackerWaitingDriver />
      case TypeTemplateList.OrderPickerPicking:
        return <TemplateOrderPickerPicking />
      case TypeTemplateList.OrderPackerPacking:
        return <TemplateOrderPackerPacking />
      case TypeTemplateList.InboundPo:
        return <TemplateInboundPo />
      case TypeTemplateList.SupplyOrderCekList:
        return <TemplateSupplyOrderPickingList />
      case TypeTemplateList.SupplyOrderCetakSuratJalan:
        return <TemplateSupplyOrderPickingList isSuratJalan />
      case TypeTemplateList.InboundCompleteSO:
        return <TemplateInboundCompleteSO_v2 />
      case TypeTemplateList.DeliveryOrder:
        return <TemplateDeliveryOrders />
      case TypeTemplateList.PurchaseOrderLiteQRCodeExpire:
        return <TemplateCetakQR />
      case TypeTemplateList.PriceTagPrint:
        return <TemplatePriceTagPrint />
      case TypeTemplateList.PurchaseOrderLiteCreatePOLite:
        return <TemplatePurchaseOrderLite />
      case TypeTemplateList.PurchaseOrderLiteGeneratePackageID:
        return <TemplateCetakPackageID />
      case TypeTemplateList.PurchaseOrderLiteGeneratePackageIdMobile:
        return <TemplatePrintPackageIdMobile />
      case TypeTemplateList.PurchaseOrderLiteGenerateKoliID:
        return <TemplateCetakKoliID />
      case TypeTemplateList.SupplyOrderCheckQuantity:
        return <TemplateCetakSupplyOrderCheckQty />
      case TypeTemplateList.SupplyOrderPrintDeliveryNoteV2:
        return <TemplateSupplyOrderDeliveryNote />
      case TypeTemplateList.PrintQRV2:
        return <TemplatePrintQR />
      case TypeTemplateList.FPOPrintBarcode:
        return <TemplateFPOPrintBarcode />
      case TypeTemplateList.StockOpname:
        return <TemplatePrintSTO />
      case TypeTemplateList.StockOpnameBeritaAcara:
        return <TemplatePrintSTOBeritaAcara />
      case TypeTemplateList.RelabelAstroGoods:
        return <TemplateRelabelAstroGoods />
      case TypeTemplateList.GrabMart:
        return <TemplateGrabMart />
      case TypeTemplateList.AstroKitchen:
        return <TemplateAstroKitchen />
      case TypeTemplateList.SupplyOrderPrintDeliveryNoteOld:
        return <TemplateSupplyOrderDeliveryNoteOld />
      case TypeTemplateList.GiftNote:
        return <TemplateGiftNote />
      case TypeTemplateList.KitchenModifierReceipt:
        return <TemplateKitchenModifierReceipt />
      default:
        return <div></div>
    }
  }
  return (
    <Container className="print-area order-packing-waiting-driver">{renderTemplate()}</Container>
  )
}

export default memo(PrintArea)

const Container = styled.div`
  visibility: hidden;
  position: fixed;
`
