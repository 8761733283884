export const downloadFileUrl = (url: string, fileName?: string) => {
  const link = document.createElement('a')
  link.href = url
  document.body.appendChild(link)
  link.setAttribute('download', fileName || 'true')
  link.click()
  document.body.removeChild(link)
}

export const downloadFileBlob = ({
  data,
  fileName,
  mimeType,
  isOpenNewTab = false,
}: {
  data: BlobPart
  fileName: string
  mimeType:
    | 'application/pdf'
    | 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    | 'application/vnd.ms-excel'
    | 'application/zip'
    | 'application/*'
  isOpenNewTab?: boolean
}) => {
  const url = window.URL.createObjectURL(new Blob([data], { type: mimeType }))
  if (isOpenNewTab) {
    window.open(url)
    return
  }
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', `${fileName}`)
  document.body.appendChild(link)
  link.click()
}

export const dowloadFileCSV = async ({
  fileUrl,
  fileName = 'download',
}: {
  fileUrl: string
  fileName?: string
}) => {
  const response = await fetch(fileUrl)

  if (!response.ok) {
    throw new Error(`Failed to fetch CSV file: ${response.statusText}`)
  }

  const csvContent = await response.text()

  const data = new Blob([csvContent], { type: 'text/csv' })

  downloadFileBlob({
    data,
    fileName,
    mimeType: 'application/*',
  })
}
