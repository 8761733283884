import { createAsyncThunk } from '@reduxjs/toolkit'
import { callErrorMsg } from 'helpers/errorMsg'
import { getProducts, getProductById } from 'features/Product/services/product'
import {
  BannerHighlightType,
  ProductHighlightLabelEnum,
  ProductHighlightType,
  ProductCategoryType,
} from 'features/CategoryAndPosisi/@types/DynamicChannelAddEdit'
import { labelTypeOptions } from 'features/CategoryAndPosisi/pages/DynamicChannel/PopupAddEdit/constants'
import {
  AxiosResponse,
  withImageService,
  type ImageVariantIdType as AstroUtilsImageVariantIdType,
} from '@astronautsid/wpe-utils'
import { getImageRules, type ImageVariantIdType } from 'utils/apiList/images'
import axiosInstanceImageService from 'config/apiServiceImage'
import { toastFailed } from 'utils/toast'
import { getCategory } from 'utils/api'
import { unixToDateFormat } from 'features/CategoryAndPosisi/utils/date'
import {
  SLICE_NAME,
  setBannerHighlightCategories,
  setBannerHighlightList,
  setImageRules,
  setProductHighlightList,
  setProductHighlightProducts,
} from './slice'

const { ServiceCustomerImageUpload } = withImageService(axiosInstanceImageService)

export type UploadImagePayloadType = {
  typeId: ImageVariantIdType
  file: File
  uniqueKey: string
}

export type UploadImageReturnedType = {
  data: Awaited<ReturnType<typeof ServiceCustomerImageUpload>>
  uniqueKey: string
}

export const fetchBannerHighlightCategories = createAsyncThunk(
  `${SLICE_NAME}/fetchBannerHighlightCategories`,
  async (categoryId: number, { dispatch }) => {
    try {
      const res = await getCategory(categoryId)
      const categoryDetail = res.data as ProductCategoryType

      dispatch(
        setBannerHighlightCategories(
          (categoryDetail.category_childs || []) as ProductCategoryType[],
        ),
      )
    } catch (err) {
      callErrorMsg(err, 'Gagal mendapatkan daftar kategori')
    }
  },
)

export const fetchProductHighlightProducts = createAsyncThunk(
  `${SLICE_NAME}/fetchProductHighlightProducts`,
  async (params: { categoryIDs: number; name: string }, { dispatch }) => {
    try {
      const { data } = await getProducts({
        ...params,
        pageSize: 100,
      })

      dispatch(setProductHighlightProducts(data.data))
    } catch (err) {
      callErrorMsg(err, 'Gagal mendapatkan daftar produk')
    }
  },
)

export const fetchProductDetailAndMappingProductHighlight = createAsyncThunk(
  `${SLICE_NAME}/fetchProductDetailAndMappingProductHighlight`,
  async (productHighlight: ProductHighlightType[], { dispatch }) => {
    // const productIds = productHighlight.map(({ product_id }) => product_id)

    try {
      const res = await Promise.allSettled(
        productHighlight.map(({ product_id }) => getProductById(product_id)),
      )

      const payload = productHighlight.map((item) => {
        const product = res.find((resData) => {
          if (resData.status === 'fulfilled') {
            return resData.value?.data?.product_id === item.product_id
          }

          return false
        }) as PromiseFulfilledResult<Awaited<ReturnType<typeof getProductById>>> | undefined

        return {
          ...item,
          uniqueKey: crypto.randomUUID(),
          is_deleted: false,
          labelType:
            labelTypeOptions.find(
              ({ id }) =>
                id ===
                (item.default_text
                  ? ProductHighlightLabelEnum.HEMAT
                  : ProductHighlightLabelEnum.FREE_TEXT),
            ) || null,
          startDate: unixToDateFormat(item.start_date, 'date'),
          startTime: unixToDateFormat(item.start_date, 'time'),
          endDate: unixToDateFormat(item.end_date, 'date'),
          endTime: unixToDateFormat(item.end_date, 'time'),
          product: product
            ? {
                productID: product.value?.data?.product_id,
                productName: product.value?.data?.product_name,
                productSkuNo: product.value?.data.product_sku_number,
                baseUom: '',
              }
            : null,
        }
      })

      dispatch(setProductHighlightList(payload))
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const fetchCategoryAndMappingBannerHighlight = createAsyncThunk(
  `${SLICE_NAME}/fetchCategoryAndMappingBannerHighlight`,
  async (bannerHighlight: BannerHighlightType[], { dispatch }) => {
    try {
      const res = await Promise.allSettled(
        bannerHighlight.map(({ category_id }) => getCategory(category_id)),
      )

      const payload = bannerHighlight.map((banner) => {
        const category = res.find((resData) => {
          if (resData.status === 'fulfilled') {
            return resData.value?.data?.category_id === banner.category_id
          }

          return false
        }) as PromiseFulfilledResult<AxiosResponse<ProductCategoryType>> | undefined

        return {
          ...banner,
          uniqueKey: crypto.randomUUID(),
          is_deleted: false,
          startDate: unixToDateFormat(banner.start_date, 'date'),
          startTime: unixToDateFormat(banner.start_date, 'time'),
          endDate: unixToDateFormat(banner.end_date, 'date'),
          endTime: unixToDateFormat(banner.end_date, 'time'),
          imageFile: null,
          imagePreview: banner.banner_category_highlight_image?.url_medium || '',
          category: category?.value.data || null,
        }
      })

      dispatch(setBannerHighlightList(payload))
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const fetchImageRules = createAsyncThunk(
  `${SLICE_NAME}/fetchImageRules`,
  async (typeIds: ImageVariantIdType[], { dispatch }) => {
    try {
      const res = await Promise.allSettled(typeIds.map((typeId) => getImageRules(typeId)))

      const imageRulePayload = res.reduce((acc, cur) => {
        if (cur.status === 'fulfilled') {
          const { data } = cur.value.data
          return { ...acc, [data.typeId]: data }
        }
        return acc
      }, {})

      dispatch(setImageRules(imageRulePayload))
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const uploadImages = createAsyncThunk(
  `${SLICE_NAME}/uploadImages`,
  async (payload: UploadImagePayloadType[], { getState }) => {
    const {
      auth: { userData },
    } = getState() as StoreStateType

    try {
      const res = await ServiceCustomerImageUpload(
        payload.map(({ file, typeId }) => ({
          data: { file_input: file, created_by: userData.id },
          typeId: typeId as AstroUtilsImageVariantIdType,
        })),
      )

      res.forEach(({ error, message }) => {
        if (error) {
          toastFailed(message)
        }
      })

      return res.map((item, index) => ({
        uniqueKey: payload[index].uniqueKey,
        data: item,
      }))
    } catch (error) {
      callErrorMsg(error)
      return []
    }
  },
)
