import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { callErrorMsg } from 'helpers/errorMsg'
import {
  getCombinationSLA,
  getLocationPoolingConfig,
  postNewPeakHourPoolingConfig,
  putNewPeakHourPoolingConfig,
  putSavePoolingConfig,
} from 'utils/api'
import { toastSuccess } from 'utils/toast'

const reducerName = 'editPooling'

const initialState = {
  isEmpty: false,
  selectedId: 0,
  showSpesificTime: false,
  isEditingSpecific: false,
  isLoadingConfigPool: false,
  isEditing: false,
  isOpened: false,
  location: {
    locationId: 0,
    locationName: '',
  },
  prevConfig: [
    {
      id: 0,
      is_enable: true,
      max_distance: 0, // km
      max_weight: 0, // kg
      max_sku: 0,
      max_quantity: 0,
      searching_time: {
        sequence_1: {
          sla_15: 0, // minute
          sla_30: 0, // minute
          scheduled_instant: null,
          start_timeslot: 'before',
          end_timeslot: 'before',
        },
        sequence_2: {
          start: 0, // minute
          end: 0, // minute
          scheduled_instant: null,
          start_timeslot: 'before',
          end_timeslot: 'before',
        },
        sequence_3: {
          start: 0, // minute
          end: 0, // minute
          scheduled_instant: null,
          start_timeslot: 'before',
          end_timeslot: 'before',
        },
        sequence_4: {
          start: 0,
          end: 0,
          scheduled_instant: null,
          start_timeslot: 'before',
          end_timeslot: 'before',
        },
      },
      sla_combination: {
        combination_2: [],
        combination_3: [],
        combination_4: [],
      },
    },
  ],
  userConfig: [
    {
      id: 0,
      is_enable: true,
      max_distance: 0, // km
      max_weight: 0, // kg
      max_sku: 0,
      max_quantity: 0,
      searching_time: {
        sequence_1: {
          sla_15: 0, // minute
          sla_30: 0, // minute
        },
        sequence_2: {
          start: 0, // minute
          end: 0, // minute
          scheduled_instant: null,
          start_timeslot: 'after',
          end_timeslot: 'after',
        },
        sequence_3: {
          start: 0, // minute
          end: 0, // minute
          scheduled_instant: null,
          start_timeslot: 'after',
          end_timeslot: 'after',
        },
        sequence_4: {
          start: 0,
          end: 0,
          scheduled_instant: null,
          start_timeslot: 'after',
          end_timeslot: 'after',
        },
      },
      sla_combination: {
        combination_2: [],
        combination_3: [],
        combination_4: [],
      },
    },
  ],
  combinations: {},
  start_timeslot: 'before',
  end_timeslot: 'before',
}

const handleNullObjectSequence = (dataArray) => {
  const dataCopyArray = JSON.parse(JSON.stringify(dataArray))
  const sequenceArray = ['sequence_2', 'sequence_3', 'sequence_4']
  dataCopyArray.forEach((data) => {
    sequenceArray.forEach((sequenceKey) => {
      if (!data.searching_time?.[sequenceKey]) {
        data.searching_time[sequenceKey] = {
          start: 0,
          end: 0,
        }
      }
    })
  })
  return dataCopyArray
}

const sendNullObjectSequence = (dataArray) => {
  const sequenceArray = ['sequence_1', 'sequence_2', 'sequence_3', 'sequence_4']

  return dataArray.map((data) => {
    const clonedData = JSON.parse(JSON.stringify(data))

    sequenceArray.forEach((sequenceKey) => {
      if (sequenceKey === 'sequence_1') {
        if (
          clonedData.searching_time &&
          clonedData.searching_time[sequenceKey]?.sla_30 === 0 &&
          clonedData.searching_time[sequenceKey]?.sla_15 === 0
        ) {
          clonedData.searching_time[sequenceKey] = null
        }
      } else if (clonedData.searching_time && clonedData.searching_time[sequenceKey]) {
        if (
          clonedData.searching_time[sequenceKey]?.start === 0 &&
          clonedData.searching_time[sequenceKey]?.end === 0
        ) {
          clonedData.searching_time[sequenceKey] = null
        }
      }
    })

    return clonedData
  })
}

const handleTimeslotDropdown = (searching_time) => {
  let searchingTimeCopy = { ...searching_time }
  for (let index = 1; index < 4; index++) {
    const sequence = searchingTimeCopy[`sequence_${index + 1}`]

    if (sequence && sequence.scheduled_instant) {
      sequence.start_timeslot =
        sequence.scheduled_instant.start_after_timeslot === -1 ? 'before' : 'after'

      sequence.end_timeslot =
        sequence.scheduled_instant.end_after_timeslot === -1 ? 'before' : 'after'
    }
    searchingTimeCopy[`sequence_${index + 1}`] = sequence
  }

  return searchingTimeCopy
}

const appSlice = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    resetEditPooling: () => initialState,
    setisLoadingConfigPool: (state, { payload }) => {
      state.isLoadingConfigPool = payload
    },
    setIsOpenedEditPooling: (state, { payload }) => {
      state.isOpened = payload
    },
    setPrevConfig: (state, { payload }) => {
      if (Array.isArray(payload)) {
        const parsePayloadWIthDropdownValue = payload.map((config) => ({
          ...config,
          searching_time: handleTimeslotDropdown(config.searching_time),
        }))
        state.prevConfig = handleNullObjectSequence(parsePayloadWIthDropdownValue)
        if (payload.length === 1) {
          state.isEmpty = true
        }
      } else {
        const dataParsed = state.prevConfig.map((config) =>
          config.id === state.selectedId ? payload : config,
        )
        state.prevConfig = handleNullObjectSequence(dataParsed)
      }
    },
    setUserConfig: (state, { payload }) => {
      if (Array.isArray(payload)) {
        const parsePayloadWIthDropdownValue = payload.map((config) => ({
          ...config,
          searching_time: handleTimeslotDropdown(config.searching_time),
        }))
        state.userConfig = handleNullObjectSequence(parsePayloadWIthDropdownValue)
        if (payload.length === 1) {
          state.isEmpty = true
        }
      } else {
        const dataParsed = state.userConfig.map((config) =>
          config.id === state.selectedId ? payload : config,
        )
        state.userConfig = handleNullObjectSequence(dataParsed)
      }
    },
    setCombination: (state, { payload }) => {
      const { data, keyCombination } = payload
      state.combinations[keyCombination] = data
    },
    setLocationEditConfig: (state, { payload }) => {
      state.location = payload
    },
    setSelectedId: (state, { payload }) => {
      state.selectedId = payload
    },
    setShowSpecificTime: (state, { payload }) => {
      state.showSpesificTime = payload
    },
    setIsEditingSpecific: (state, { payload }) => {
      state.isEditingSpecific = payload
    },
    setIsEmpty: (state, { payload }) => {
      state.isEmpty = payload
    },
  },
})

const { actions, reducer } = appSlice
export const {
  resetEditPooling,
  setIsOpenedEditPooling,
  setPrevConfig,
  setCombination,
  setLocationEditConfig,
  setisLoadingConfigPool,
  setUserConfig,
  setSelectedId,
  setShowSpecificTime,
  setIsEditingSpecific,
  setIsEmpty,
} = actions

export const fetchLocationPoolingConfig = createAsyncThunk(
  `${reducerName}/fetchLocationPoolingConfig`,
  async (locationId, { dispatch }) => {
    dispatch(setisLoadingConfigPool(true))
    try {
      const { data } = await getLocationPoolingConfig(locationId)
      dispatch(setPrevConfig(data?.data?.pooling_configurations))
      dispatch(setUserConfig(data?.data?.pooling_configurations))
    } catch (err) {
      callErrorMsg(err)
    } finally {
      dispatch(setisLoadingConfigPool(false))
    }
  },
)

export const fetchCombinationSLA = createAsyncThunk(
  `${reducerName}/fetchCombinationSLA`,
  async (params, { dispatch }) => {
    try {
      const { data } = await getCombinationSLA(params.combination)
      const payload = {
        keyCombination: `list${params.combination}`,
        data: data.data,
      }
      payload.data.map((d) => ({ ...d, selected: d.mandatory }))
      dispatch(setCombination(payload))
    } catch (err) {
      callErrorMsg(err)
    }
  },
)

export const putPoolingConfig = createAsyncThunk(
  `${reducerName}/putPoolingConfig`,
  async ({ locationId, succesCallback }, { dispatch, getState }) => {
    const {
      userConfig,
      location: { locationName },
    } = getState().editPooling
    const returnOnlyId = (array) => (array.length > 0 ? array.map((d) => d.id) : null)

    let parseData = userConfig
      .filter((config) => config.is_enable)
      .map((config) => {
        let copyConfig = { ...config }
        let updatedSearchingTime = {}
        for (let index = 1; index <= 4; index++) {
          if (copyConfig.searching_time[`sequence_${index}`]) {
            updatedSearchingTime[`sequence_${index}`] = (({
              start_timeslot,
              end_timeslot,
              ...rest
            }) => rest)(copyConfig.searching_time[`sequence_${index}`])
          }
        }
        return {
          id: config.id,
          max_distance: config.max_distance,
          max_quantity: config.max_quantity,
          max_sku: config.max_sku,
          max_weight: config.max_weight,
          sla_combination: {
            combination_2: returnOnlyId(config.sla_combination?.combination_2),
            combination_3: returnOnlyId(config.sla_combination?.combination_3),
            combination_4: returnOnlyId(config.sla_combination?.combination_4),
          },
          searching_time: updatedSearchingTime,
        }
      })

    const payload = {
      location_id: locationId,
      pooling_configurations: sendNullObjectSequence(parseData),
    }

    try {
      const { data } = await putSavePoolingConfig(payload)
      toastSuccess(`Pengaturan pooling ${locationName} berhasil diperbarui`)
      dispatch(resetEditPooling())
      succesCallback()
      return data
    } catch (err) {
      callErrorMsg(err)
    }
  },
)

export const submitNewPeakTime = createAsyncThunk(
  `${reducerName}/submitNewPeakTime`,
  /**
   * define type to cater ts checker
   * @param {Object} payload
   */
  async (payload) => {
    try {
      const { data } = await postNewPeakHourPoolingConfig(payload?.data)
      if (data && payload.successCallback) {
        payload.successCallback(data.message)
      }
    } catch (err) {
      callErrorMsg(err)
    }
  },
)

export const updatePeakTime = createAsyncThunk(
  `${reducerName}/updatePeakTime`,
  /**
   * define type to cater ts checker
   * @param {Object} payload
   */
  async (payload) => {
    try {
      const { data } = await putNewPeakHourPoolingConfig(payload?.data)
      if (data && payload.successCallback) {
        payload.successCallback(data.message)
      }
    } catch (err) {
      callErrorMsg(err)
    }
  },
)

export default reducer
