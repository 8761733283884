/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/naming-convention */

import { getRequestXDevice } from 'utils/helpers/userAgent'
import { createAxiosInstance, AxiosError, AxiosResponse } from '@astronautsid/wpe-utils'

import { axiosErrorLoggerInterceptor } from 'config/axiosLogger'
import { getTokenCookies } from 'config/cookies'

const { REACT_APP_BASE_URL_KONG_API, REACT_APP_SYSTEM_MAINTENANCE_URL, REACT_APP_MAINTENANCE } =
  process.env

const handleRejectedResponse = (error: AxiosError) => {
  if (error?.request?.status === 302 || REACT_APP_MAINTENANCE === 'true') {
    window.location.assign(`${REACT_APP_SYSTEM_MAINTENANCE_URL}?callback=${window.location.href}`)
  }
  axiosErrorLoggerInterceptor(error)

  return Promise.reject(error)
}

const axiosInstanceApiGateway = createAxiosInstance(
  {
    baseURL: REACT_APP_BASE_URL_KONG_API,
    headers: {
      'Access-Control-Allow-Origin':
        process.env.NODE_ENV !== 'production' ? 'http://localhost:3000' : undefined,
      'Access-Control-Max-Age': 7200,
    },
  },
  {
    interceptorRequest: (config) => {
      const token = getTokenCookies()
      const headers = config.headers as Exclude<typeof config.headers, undefined>
      /* This way requests are allowed to override interceptor */
      headers.Authorization =
        headers && headers.Authorization !== undefined ? headers.Authorization : `Bearer ${token}`
      config.headers = headers
      ;(config.headers as Exclude<typeof config.headers, undefined>)['x-device'] =
        getRequestXDevice()
      return config
    },
    interceptorResponse: (response: AxiosResponse) => {
      if (REACT_APP_MAINTENANCE === 'true') {
        window.location.assign(
          `${REACT_APP_SYSTEM_MAINTENANCE_URL}?callback=${window.location.href}`,
        )
        return response
      }
      return response
    },
    interceptorResponseReject: handleRejectedResponse,
    interceptorRequestReject: (error: AxiosError) => Promise.reject(error),
  },
)

export default axiosInstanceApiGateway
