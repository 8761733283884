import { Get, Post } from 'config/apiServiceConfig'

export interface GetMasterPayrollListServicesInterface {
  data: {
    id: number
    staff: {
      id: number
      name: string
    }
    role: string
    employee_status: string
    payroll_type: string
    amount: string
  }[]
  pagination: {
    numberOfElements: number
    pageIndex: number
  }
}

export interface GetPayrollDetailByUserIdServicesInterface {
  data: {
    id: string
    staff_id: string
    staff_name: string
    payroll: {
      id: string
      start_date: string
      end_date: string
      period: string
      payroll_type: string
      amount: string
    }[]
  }
  pagination: {
    numberOfElements: number
    pageIndex: number
  }
}

export interface PayrollQueryParamsInterface {
  sort: string
  direction: string
  pageSize: number
  pageIndex: number
  numberOfElements: number
}

export const getMasterPayrollList = (
  params: Pick<PayrollQueryParamsInterface, 'direction' | 'pageIndex' | 'pageSize' | 'sort'> & {
    search?: string
    locationId: number
    year: string
    month: string
    payrollType: string
  },
) =>
  Get<GetMasterPayrollListServicesInterface>({ url: '/api/staff-management/payroll/list', params })

export const getDetailPayrollByUserIdServices = (
  params: PayrollQueryParamsInterface & { staffId: string | number },
) =>
  Get<GetPayrollDetailByUserIdServicesInterface>({
    url: '/api/staff-management/payroll/list/detail',
    params,
  })

export const getMasterPayrollBulkUploadTemplate = (params: { payoutType: string }) =>
  Get<{ data: { file_url: string } }>({ url: '/api/staff-management/payroll/template', params })

export const postMasterPayrollBulkUpload = (payload: unknown) =>
  Post({
    url: '/api/staff-management/payroll/import',
    data: payload,
    contentType: 'multipart/form-data',
  })

export const getMasterPayrollPayslipPdf = (params: { payrollId: string | number }) =>
  Get<BlobPart>({
    url: '/api/staff-management/payroll/payslip/generate',
    params,
    config: {
      responseType: 'blob',
    },
  })

export const getExportedPayrollExcel = (params: {
  locationId: string
  year: string
  month: string
  search?: string
  payrollType?: string
}) =>
  Get<{ data: { file_url: string } }>({
    url: '/api/staff-management/payroll/export',
    params,
  })

export const postPublishPayslip = (payload: unknown) =>
  Post({
    url: '/api/staff-management/payroll/publish',
    data: payload,
    contentType: 'application/json',
  })
