import { createSlice } from '@reduxjs/toolkit'
import { LocationType } from 'features/Rack/@types/rack'
import {
  GetRackListResponseType,
  GetRackDetailByLocationIdParamsType,
  GetRackDetailByLocationIdResponseType,
} from 'features/Rack/services/rackList'

export type RackInitialStateType = {
  isLoading: boolean
  rack: {
    listData: GetRackListResponseType['data']['content']
    pageIndex: number
  }
  location: {
    listData: LocationType[]
    selectedLocation: LocationType | null
    queryParams?: {
      name: string
    }
  }
  modalBulkUpload: {
    isOpen: boolean
    templateUrl: string
    fileCSV: {
      status: string
      rawFile?: File
      file?: string
      fileName?: string
      fileSize?: File['size']
      fileType?: File['type']
      url_download: string
      data_count: {
        total_data: number
        success_data: number
      }
    }
  }
  modalDelete: {
    isOpen: boolean
    title: string
    type: 'single' | 'multiple'
    content: string
    idToDelete: number[]
  }
  rackDetail: {
    numberOfElements: number
    location: GetRackDetailByLocationIdResponseType['data']['location']
    listData: GetRackDetailByLocationIdResponseType['data']['racks']['content']
    params: GetRackDetailByLocationIdParamsType
  }
  checkedIds: number[]
}

const initialState: RackInitialStateType = {
  isLoading: false,
  rack: {
    listData: [],
    pageIndex: 0,
  },
  location: {
    listData: [],
    selectedLocation: null,
    queryParams: {
      name: '',
    },
  },
  modalBulkUpload: {
    isOpen: false,
    templateUrl: '',
    fileCSV: {
      status: 'ON_CHOOSE_FILE',
      fileName: '',
      url_download: '',
      data_count: { total_data: 0, success_data: 0 },
    },
  },
  modalDelete: {
    isOpen: false,
    title: '',
    type: 'single',
    content: 'Data yg sudah dihapus tidak dapat dikembalikan lagi, yakin ingin hapus ?',
    idToDelete: [],
  },
  rackDetail: {
    numberOfElements: 0,
    location: {
      location_id: 0,
      location_name: '',
      location_type: '',
    },
    listData: [],
    params: {
      pageIndex: 0,
      pageSize: 20,
    },
  },
  checkedIds: [],
}

export const SLICE_NAME = 'rack'

const rack = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setRack: (state, action) => {
      state.rack = { ...state.rack, ...action.payload }
    },
    setLocation: (state, action) => {
      state.location = { ...state.location, ...action.payload }
    },
    setModalBulkUpload: (state, action) => {
      state.modalBulkUpload = { ...state.modalBulkUpload, ...action.payload }
    },
    setRackDetail: (state, action) => {
      state.rackDetail = { ...state.rackDetail, ...action.payload }
    },
    setCheckedIds: (state, action) => {
      state.checkedIds = action.payload
    },
    setModalDelete: (state, action) => {
      state.modalDelete = { ...state.modalDelete, ...action.payload }
    },
    resetModalDelete: (state) => {
      state.modalDelete = initialState.modalDelete
    },
    resetModalBulkUpload: (state) => {
      state.modalBulkUpload = initialState.modalBulkUpload
    },
  },
})
export const {
  setRack,
  setIsLoading,
  setLocation,
  setRackDetail,
  setModalBulkUpload,
  setCheckedIds,
  setModalDelete,
  resetModalDelete,
  resetModalBulkUpload,
} = rack.actions
export default rack.reducer
