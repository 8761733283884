import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { callErrorMsg } from 'helpers/errorMsg'
import { dateFormat } from '@astronautsid/wpe-utils'
import { getParameter } from 'utils/apiList/fraudWatchTower'

import type {
  WatchtowerRuleV2StateType,
  PutWatchtowerStatusRequestType,
  GetWatchTowerRuleV3DetailRequestType,
  SetShowModalPayloadActionType,
  GroupRuleType,
  GroupParameterRuleType,
  RuleType,
  SetFormRuleDetailPayloadActionType,
  SetGroupRulePayloadActionType,
  FormRuleDetailType,
  AddRemoveGroupParameterRulePayloadActionType,
  SetGroupParameterRulePayloadActionType,
  SetQueryPayloadActionType,
  PostWatchtowerRuleRequestType,
  PutWatchtowerRuleRequestType,
  WatchtowerModeType,
  ParameterRuleValueType,
} from 'features/Fraud/@types/watchtowerRule'
import {
  getWatchTowerRuleV3,
  putWatchtowerStatus,
  getWatchTowerRuleV3Detail,
  postWatchtowerRules,
  putWatchtowerRule,
} from 'features/Fraud/services/fraudWatchTowerRule'

export const SLICE_NAME = 'userFraudStatus'

export const PARAMETER_RULE_OPERATOR_OPTIONS = [
  'IN',
  'NOT_IN',
  'EXIST',
  'EQUAL',
  'NOT_EQUAL',
  'GTE',
  'LTE',
  'GT',
  'LT',
]

export const composeGroupRules: (ruleType: RuleType) => GroupRuleType = (ruleType) => ({
  id: 0,
  logic: 'AND',
  rule_type: ruleType,
  parameter_rules: [composeGroupParameterRules()],
  uniqueGroupId: window.crypto.randomUUID(),
  is_deleted: false,
})

export const composeGroupParameterRules: () => GroupParameterRuleType = () => ({
  id: 0,
  logic: 'OR',
  operator: '',
  parameter_id: 0,
  values: [{ id: 0, value: '', is_deleted: false }],
  composedValue: '',
  selectedParameter: null,
  uniqueParameterId: window.crypto.randomUUID(),
  is_deleted: false,
})

const initialState: WatchtowerRuleV2StateType = {
  isLoading: false,
  query: { name: '', page_index: 0, page_size: 20, status: '' },
  ruleList: [],
  showModal: '',
  formRuleDetail: {
    activity_score: 0,
    device_score: 0,
    flag_activity_score: false,
    flag_device_score: false,
    group_rules: [composeGroupRules('INCLUDE')],
    group_rules_exclude: [],
    group_rules_include: [composeGroupRules('INCLUDE')],
    id: 0,
    name: '',
    end_date: '',
    end_time: '',
    start_date: '',
    start_time: '',
    status: 'INACTIVE',
    type: 'WATCHTOWER',
  },
  parameterList: [],
}

const watchtowerRulev2 = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    reset: () => initialState,
    resetRuleDetail: (state) => {
      state.formRuleDetail = initialState.formRuleDetail
    },
    setQuery: (state, action: PayloadAction<SetQueryPayloadActionType>) => {
      const { value } = action.payload
      state.query = { ...state.query, ...value }
    },
    setShowModal: (state, action: PayloadAction<SetShowModalPayloadActionType>) => {
      const { value } = action.payload
      state.showModal = value

      if (value === '') {
        state.formRuleDetail = initialState.formRuleDetail
      }
    },
    setFormRuleDetail: (state, action: PayloadAction<SetFormRuleDetailPayloadActionType>) => {
      const { value, name } = action.payload
      state.formRuleDetail[name] = value as never
    },
    setGroupRule: (state, actionPayload: PayloadAction<SetGroupRulePayloadActionType>) => {
      const { value, action } = actionPayload.payload

      const removeEntireGroupRule: (currentGroupRule: GroupRuleType[]) => GroupRuleType[] = (
        currentGroupRule,
      ) =>
        currentGroupRule.map((el) => ({
          ...el,
          is_deleted: true,
          parameter_rules: el.parameter_rules.map((ell) => ({
            ...ell,
            is_deleted: true,
            values: ell.values.map((elVal) => ({ ...elVal, is_deleted: true })),
          })),
        }))

      if (action === 'ADD' && value === 'EXCLUDE') {
        state.formRuleDetail.group_rules_exclude = [
          ...state.formRuleDetail.group_rules_exclude,
          composeGroupRules(value),
        ]
      }
      if (action === 'ADD' && value === 'INCLUDE') {
        state.formRuleDetail.group_rules_include = [
          ...state.formRuleDetail.group_rules_include,
          composeGroupRules(value),
        ]
      }
      if (action === 'REMOVE' && value === 'EXCLUDE') {
        state.formRuleDetail.group_rules_exclude = removeEntireGroupRule(
          state.formRuleDetail.group_rules_exclude,
        )
      }
      if (action === 'REMOVE' && value === 'INCLUDE') {
        state.formRuleDetail.group_rules_include = removeEntireGroupRule(
          state.formRuleDetail.group_rules_include,
        )
      }
    },
    addRemoveGroupParameterRule: (
      state,
      actionPayload: PayloadAction<AddRemoveGroupParameterRulePayloadActionType>,
    ) => {
      const { action, groupIndex, value, parameterIndex } = actionPayload.payload

      const mapRemoveParams: (
        currentGroupRules: GroupRuleType[],
        currentGroupIndex: number,
        currentGroupParameterIndex: number,
      ) => GroupParameterRuleType = (
        currentGroupRules,
        currentGroupIndex,
        currentGroupParameterIndex,
      ) => ({
        ...currentGroupRules[currentGroupIndex].parameter_rules[currentGroupParameterIndex],
        is_deleted: true,
        values: currentGroupRules[currentGroupIndex].parameter_rules[
          currentGroupParameterIndex
        ].values.map((el) => ({ ...el, is_deleted: true })),
      })

      const removeEntireParamterRuleByGroupIndex: (
        currentGroupRules: GroupParameterRuleType[],
      ) => GroupParameterRuleType[] = (currentGroupRules) =>
        currentGroupRules.map((el) => ({
          ...el,
          is_deleted: true,
          values: el.values.map((elVal) => ({ ...elVal, is_deleted: true })),
        }))

      if (value === 'EXCLUDE' && action === 'ADD') {
        state.formRuleDetail.group_rules_exclude[groupIndex].parameter_rules = [
          ...state.formRuleDetail.group_rules_exclude[groupIndex].parameter_rules,
          composeGroupParameterRules(),
        ]
      }
      if (value === 'INCLUDE' && action === 'ADD') {
        state.formRuleDetail.group_rules_include[groupIndex].parameter_rules = [
          ...state.formRuleDetail.group_rules_include[groupIndex].parameter_rules,
          composeGroupParameterRules(),
        ]
      }
      if (value === 'EXCLUDE' && action === 'REMOVE' && parameterIndex !== undefined) {
        if (state.formRuleDetail.group_rules_exclude[groupIndex].parameter_rules.length === 1) {
          state.formRuleDetail.group_rules_exclude[groupIndex].is_deleted = true

          state.formRuleDetail.group_rules_exclude[groupIndex].parameter_rules =
            removeEntireParamterRuleByGroupIndex(
              state.formRuleDetail.group_rules_exclude[groupIndex].parameter_rules,
            )
        } else {
          state.formRuleDetail.group_rules_include[groupIndex].parameter_rules[parameterIndex] =
            mapRemoveParams(state.formRuleDetail.group_rules_exclude, groupIndex, parameterIndex)
        }
      }
      if (value === 'INCLUDE' && action === 'REMOVE' && parameterIndex !== undefined) {
        if (
          state.formRuleDetail.group_rules_include[groupIndex].parameter_rules.filter(
            (el) => !el.is_deleted,
          ).length === 1
        ) {
          state.formRuleDetail.group_rules_include[groupIndex].is_deleted = true
          state.formRuleDetail.group_rules_include[groupIndex].parameter_rules =
            removeEntireParamterRuleByGroupIndex(
              state.formRuleDetail.group_rules_include[groupIndex].parameter_rules,
            )
        } else {
          state.formRuleDetail.group_rules_include[groupIndex].parameter_rules[parameterIndex] =
            mapRemoveParams(state.formRuleDetail.group_rules_include, groupIndex, parameterIndex)
        }
      }
    },
    setGroupParameterValue: (
      state,
      actionPayload: PayloadAction<SetGroupParameterRulePayloadActionType>,
    ) => {
      const { groupIndex, parameterIndex, ruleType, value } = actionPayload.payload
      if (ruleType === 'EXCLUDE') {
        state.formRuleDetail.group_rules_exclude[groupIndex].parameter_rules[parameterIndex] = {
          ...state.formRuleDetail.group_rules_exclude[groupIndex].parameter_rules[parameterIndex],
          ...value,
        }
      }
      if (ruleType === 'INCLUDE') {
        state.formRuleDetail.group_rules_include[groupIndex].parameter_rules[parameterIndex] = {
          ...state.formRuleDetail.group_rules_include[groupIndex].parameter_rules[parameterIndex],
          ...value,
        }
      }
    },
    setSelectedParameter: (state) => {
      const { parameterList } = state
      const composeRule: (params: GroupRuleType[]) => GroupRuleType[] = (input) =>
        input.map((elGroup) => {
          const groupParameterRules = elGroup.parameter_rules.map((elParameterRule) => ({
            ...elParameterRule,
            selectedParameter:
              parameterList.find((i) => i.id === elParameterRule.parameter_id) || null,
          }))

          return { ...elGroup, parameter_rules: groupParameterRules }
        })

      state.formRuleDetail.group_rules_include = composeRule(
        state.formRuleDetail.group_rules_include,
      )
      state.formRuleDetail.group_rules_exclude = composeRule(
        state.formRuleDetail.group_rules_exclude,
      )
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchWatchtowerRuleList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchWatchtowerRuleList.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchWatchtowerRuleList.fulfilled, (state, action) => {
        const { rules } = action.payload
        state.isLoading = false
        state.ruleList = rules
      })
      .addCase(updateWatchtowerRuleStatus.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateWatchtowerRuleStatus.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(updateWatchtowerRuleStatus.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(fetchWatchtowerRuleDetail.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchWatchtowerRuleDetail.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchWatchtowerRuleDetail.fulfilled, (state, action) => {
        const { rule } = action.payload
        state.isLoading = false
        const composedGroup: FormRuleDetailType['group_rules'] = rule.group_rules.map((el) => ({
          ...el,
          uniqueGroupId: window.crypto.randomUUID(),
          is_deleted: false,
          parameter_rules: el.parameter_rules.map((elParameters) => {
            const composedValue = elParameters.values.map((elVal) => elVal.value).join(',')

            return {
              ...elParameters,
              selectedParameter: null,
              uniqueParameterId: window.crypto.randomUUID(),
              is_deleted: false,
              values: elParameters.values.map((elVal) => ({ ...elVal, is_deleted: false })),
              composedValue,
            }
          }),
        }))
        state.formRuleDetail = {
          ...rule,
          end_time: dateFormat({ date: new Date(rule.end_date), format: 'HH:mm' }),
          end_date: dateFormat({ date: new Date(rule.end_date), format: 'YYYY-MM-DD' }),
          start_date: dateFormat({ date: new Date(rule.start_date), format: 'YYYY-MM-DD' }),
          start_time: dateFormat({ date: new Date(rule.start_date), format: 'HH:mm' }),
          group_rules: composedGroup,
          group_rules_exclude: composedGroup.filter((el) => el.rule_type === 'EXCLUDE'),
          group_rules_include: composedGroup.filter((el) => el.rule_type === 'INCLUDE'),
        }
      })
      .addCase(createWatchtowerRule.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createWatchtowerRule.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(createWatchtowerRule.fulfilled, (state) => {
        state.isLoading = false
      })

      .addCase(fetchParameterList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchParameterList.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchParameterList.fulfilled, (state, action) => {
        const { parameters } = action.payload
        state.isLoading = false
        state.parameterList = parameters
      })
  },
})

export const {
  setIsLoading,
  reset,
  setShowModal,
  resetRuleDetail,
  setFormRuleDetail,
  setGroupRule,
  addRemoveGroupParameterRule,
  setQuery,
  setGroupParameterValue,
  setSelectedParameter,
} = watchtowerRulev2.actions
export default watchtowerRulev2.reducer

export const fetchWatchtowerRuleList = createAsyncThunk(
  `${SLICE_NAME}/fetchWatchtowerRuleList`,
  async ({ type }: { type: WatchtowerModeType }, { rejectWithValue, getState }) => {
    const {
      watchtowerRuleV2: { query },
    } = getState() as StoreStateType
    try {
      const res = await getWatchTowerRuleV3({
        params: {
          page_index: query.page_index,
          page_size: query.page_size,
          status: query.status,
          type,
        },
      })

      return res.data.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const updateWatchtowerRuleStatus = createAsyncThunk(
  `${SLICE_NAME}/updateWatchtowerRuleStatus`,
  async (request: PutWatchtowerStatusRequestType, { rejectWithValue }) => {
    try {
      const res = await putWatchtowerStatus(request)

      return res.data.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)
export const fetchWatchtowerRuleDetail = createAsyncThunk(
  `${SLICE_NAME}/fetchWatchtowerRuleDetail`,
  async (request: GetWatchTowerRuleV3DetailRequestType, { rejectWithValue }) => {
    try {
      const res = await getWatchTowerRuleV3Detail(request)

      return res.data.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const createWatchtowerRule = createAsyncThunk(
  `${SLICE_NAME}/createWatchtowerRule`,
  async ({ type }: { type: WatchtowerModeType }, { rejectWithValue, getState }) => {
    const {
      watchtowerRuleV2: { formRuleDetail },
    } = getState() as StoreStateType
    try {
      const composePayloadGroupRule: (
        params: GroupRuleType[],
      ) => PostWatchtowerRuleRequestType['payload']['group_rules'] = (groupRules) =>
        groupRules
          .filter((el) => !el.is_deleted)
          .map((el) => ({
            rule_type: el.rule_type,
            parameter_rules: el.parameter_rules
              .filter((ell) => !ell.is_deleted)
              .map((ell) => ({
                operator: ell.operator,
                parameter_id: ell.parameter_id,
                values: ell.composedValue.split(',').map((elMap) => elMap.trim()),
                is_deleted: false,
              })),
          }))
      const res = await postWatchtowerRules({
        payload: {
          end_date: new Date(`${formRuleDetail.end_date} ${formRuleDetail.end_time}`).valueOf(),
          start_date: new Date(
            `${formRuleDetail.start_date} ${formRuleDetail.start_time}`,
          ).valueOf(),
          activity_score: formRuleDetail.activity_score,
          device_score: formRuleDetail.device_score,
          flag_activity_score: formRuleDetail.flag_activity_score,
          flag_device_score: formRuleDetail.flag_activity_score,
          name: formRuleDetail.name,
          group_rules: [
            ...composePayloadGroupRule(formRuleDetail.group_rules_exclude),
            ...composePayloadGroupRule(formRuleDetail.group_rules_include),
          ],
          type,
        },
      })

      return res.data.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)
export const fetchParameterList = createAsyncThunk(
  `${SLICE_NAME}/fetchParameterList`,
  async (_, { rejectWithValue }) => {
    try {
      const res = await getParameter({ pageSize: 9999 })

      return res.data.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const updateWatchtowerRule = createAsyncThunk(
  `${SLICE_NAME}/updateWatchtowerRule`,
  async ({ type }: { type: WatchtowerModeType }, { rejectWithValue, getState }) => {
    const {
      watchtowerRuleV2: { formRuleDetail },
    } = getState() as StoreStateType

    const decomposeValues: (
      composedValue: string,
      oldValue: ParameterRuleValueType[],
    ) => ParameterRuleValueType[] = (composedValue, oldValues) => {
      const newValues: ParameterRuleValueType[] = []
      const splited = composedValue.split(',')

      // insert old values first into new value and add {is_deleted: true} when old values did't have some value of composed value
      oldValues.forEach((el) => {
        const haveSameValue = splited.some((elSome) => elSome === el.value)
        if (haveSameValue) {
          newValues.push({ ...el, value: el.value.trim() })
        } else {
          newValues.push({ ...el, value: el.value.trim(), is_deleted: true })
        }
      })
      // insert composed value into new value
      splited.forEach((el) => {
        const haveSomeValue = newValues.some((elSome) => elSome.value === el)

        if (!haveSomeValue) {
          newValues.push({ id: 0, is_deleted: false, value: el.trim() })
        }
      })

      return newValues
    }

    const composePayloadGroupRule: (
      params: GroupRuleType[],
    ) => PutWatchtowerRuleRequestType['payload']['group_rules'] = (groupRules) =>
      groupRules.map((el) => ({
        rule_type: el.rule_type,
        id: el.id,
        is_deleted: el.is_deleted,
        parameter_rules: el.parameter_rules.map((ell) => ({
          id: ell.id,
          operator: ell.operator,
          parameter_id: ell.parameter_id,
          values: decomposeValues(ell.composedValue, ell.values),
          is_deleted: ell.is_deleted,
        })),
      }))

    try {
      const res = await putWatchtowerRule({
        id: formRuleDetail.id.toString(),
        payload: {
          end_date: new Date(`${formRuleDetail.end_date} ${formRuleDetail.end_time}`).valueOf(),
          start_date: new Date(
            `${formRuleDetail.start_date} ${formRuleDetail.start_time}`,
          ).valueOf(),
          activity_score: formRuleDetail.activity_score,
          device_score: formRuleDetail.device_score,
          flag_activity_score: formRuleDetail.flag_activity_score,
          flag_device_score: formRuleDetail.flag_activity_score,
          name: formRuleDetail.name,
          group_rules: [
            ...composePayloadGroupRule(formRuleDetail.group_rules_exclude),
            ...composePayloadGroupRule(formRuleDetail.group_rules_include),
          ],
          type,
        },
      })

      return res.data.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)
