import { Get } from 'config/apiServiceConfig'
import axiosInstanceApiGateway from 'config/apiServiceApiGateway'
import {
  GetSLAConfigResponseType,
  GetLocationListResponseType,
  GetLocationListRequestType,
  PutSLAConfigRequestType,
  PutSLAConfigResponseType,
  GetLocationDetailResponseType,
  GetLocationTypeResponseType,
  GetLocationListByTypeResponseType,
} from 'features/Location/@types/location'

const { Get: GetKong, Put: PutKong } = axiosInstanceApiGateway

export const getLocations = (params?: GetLocationListRequestType) =>
  Get<GetLocationListResponseType>({
    url: `/api/location`,
    params,
  })

export const getLocationList = ({ params }: { params: GetLocationListRequestType }) =>
  Get<GetLocationListResponseType>({
    url: `/api/location`,
    params: { pageSize: 99999, ...params },
  })

export const getLocationDetail = (id: number) =>
  Get<GetLocationDetailResponseType>({
    url: `/api/location/${id}`,
  })

export const getSLAConfig = () =>
  GetKong<GetSLAConfigResponseType>({ url: `/location/internal/v1/sla-config` })

export const putSLAConfig = ({ payload }: PutSLAConfigRequestType) =>
  PutKong<PutSLAConfigResponseType>({ url: `/location/internal/v1/sla-config`, data: payload })

export const getLocationTypeList = () =>
  Get<GetLocationTypeResponseType>({ url: '/api/location/type' })

export const getLocationListByType = (locationType: string) =>
  Get<GetLocationListByTypeResponseType[]>({
    url: `/api/locations/${encodeURI(locationType)}`,
  })

export const getAllLocations = () => Get({ url: '/api/locations/all' })
