/* eslint-disable no-undef */
import axios from 'axios'

const baseUrl = process.env.REACT_APP_BASE_URL_API

export type StatusType = 'AVAILABLE' | 'LOST' | 'BAD_STOCK'
export type StatusDotsType = 'SUCCESS' | 'WARNING' | 'ERROR'

export type GetSupplyOrderGrandPackingKoliReqType = {
  id: string
  params: {
    code: string
  }
  isKoliNotRight?: boolean
}

export type GetSupplyOrderGrandPackingKoliResType = {
  data: {
    code: string
    createdDate: string
    id: number
  }
  error: {
    message: string
  }
}

export type GetSupplyOrderGrandProductV2ReqType = {
  id: string
  params: { qrCode: string }
}

export type GetSupplyOrderGrandProductV2ResType = {
  data: {
    expDate: number | null
    productId: number
    productName: string
    productSku: string
    productUom: string
    qrCode: string
    remainingRequestQuantity: number
    requestQuantity: number
    status: string
    statusId: number
    statusNote: string
    statusNoteId: number
    supplyOrderItemId: number
  }
  error: {
    message: string
  }
}

export type GetSupplyOrderGrandPackingProductV2ResType = {
  data: {
    packingProducts: {
      expDate: number | null
      productId: number
      productName: string
      productSku: string
      productUom: string
      qrCode: string
      remainingRequestQuantity: number
      requestQuantity: number
      status: string
      statusId: number
      statusNote: string
      statusNoteId: number
      supplyOrderItemId: number
    }[]
    showPopupExpiryDate: boolean
    fresh: boolean
    substituteItem: boolean
  }
  error: {
    message: string
  }
}

export type PostSupplyOrderGrandValidateReqType = {
  id: string
  payload: {
    supplyOrderItemId: number
    actualQuantity: number
  }
}

export type PostSupplyOrderGrandPackingItemReqType = {
  id: string
  payload: {
    supplyOrderItemId: number
    details: {
      quantity: number
      inventoryStatusId: number | string | undefined
      inventoryStatusNoteId: number | string | undefined | null
      koliId?: number
    }[]
  }
}

export type PostSupplyOrderPackingItemReqType = {
  id: string
  payload: {
    supplyOrderItemId: number
    details: {
      quantity: number
      inventoryStatusId?: number | string
      inventoryStatusNoteId?: number | string | null
      koliId?: number | null
      manualExpiryDate?: number
    }[]
  }[]
}

export type GetSupplyOrderGrandPackingV2ResType = {
  supplyOrderId: number
  supplyOrderNumber: string
  checker: {
    id: number
    name: string
  } | null
  packers: {
    id: number
    name: string
  }[]
  supplyOrderItems: {
    productId: number
    productSku: string
    productName: string
    productUom: string
    requestQuantity: number
    totalActualQuantity: number
    supplyOrderItemDetails: {
      supplyOrderItemDetailId: number
      actualQuantity: number
      expDate: number
      koliNumber: string
      statusCode: StatusType
      statusName: string
      manualExpiryDate?: number | null
    }[]
    substituteItem: boolean
    productType: string
    supplyOrderItemId: number
    statusId: number
    statusNoteId: Nullable<number>
  }[]
}

export type DeleteSupplyOrderGrandItemDetailReqType = {
  id: string
  detailId: number
}

export type DeleteSupplyOrderItemDetailV2ReqType = {
  id: string
  detailId: number
}

export type GetStatusResType = {
  data: {
    status: {
      displayName: string
      id: number
    }[]
  }
}

export type GetNotessResType = {
  data: {
    notes: {
      displayName: string
      id: number
      statusId: number
    }[]
  }
}

export type GetSupplyOrderGrandKoliLabelResType = {
  data: {
    koliNumber: string
    koliCode: string
    origin: string
    destination: string
    supplyOrderNumber: string
    skuDetails: {
      skuNumber: string
      quantity: number
    }[]
  }[]
}

export type GetProgressPickingResType = {
  data: {
    activity: string
    name: string
    status: string
    startAt: string
    endAt: string
  }[]
}

export type PostAssignCheckerPackerRequestType = {
  soId: number
  payload: {
    packerIds: number[]
    checkerId: number
  }
}

export type PutAssignCheckerSORequestType = {
  soId: number
  payload: {
    checkerId: number
  }
}

export type PutAssignPackerSORequestType = {
  soId: number
  payload: {
    packerIds: number[]
  }
}

export type PostSupplyOrderPackingFreshRequestType = {
  id: number
  payload: {
    supplyOrderItemId: number
    manualReason?: string
    details: {
      quantity: number
      inventoryStatusId: number
      inventoryStatusNoteId: number | null
      koliId: number
      manualExpiryDate?: number
    }[]
    substituteItem: boolean
    qrCode: string
    productId: number | null
  }
}

export type PostSupplyOrderPackingFreshResponseType = {
  data: {
    message: string
  }
}

export type GetScanManualReasonResponseType = {
  data: {
    id: number
    reason: string
  }[]
}

export const getSupplyOrderGrandPackingV2 = (id: string) =>
  axios.get(`${baseUrl}/api/v2/supply-order-grand/${id}/packing`)

export const getSupplyOrderGrandPackingKoli = ({
  id,
  params,
}: GetSupplyOrderGrandPackingKoliReqType) =>
  axios.get(`${baseUrl}/api/v2/supply-order-grand/${id}/packing/koli`, { params })

export const getSupplyOrderGrandProductV2 = ({ id, params }: GetSupplyOrderGrandProductV2ReqType) =>
  axios.get(`${baseUrl}/api/v2/supply-order-grand/${id}/packing/product`, { params })

export const getSupplyOrderGrandPackingProduct = ({
  id,
  params,
}: GetSupplyOrderGrandProductV2ReqType) =>
  axios.get(`${baseUrl}/api/wms/v2/supply-order/${id}/packing/product`, { params })

export const getSupplyOrderGrandPackingProductV3 = ({
  id,
  params,
}: GetSupplyOrderGrandProductV2ReqType) =>
  axios.get(`${baseUrl}/api/wms/v3/supply-order/${id}/packing/product`, { params })

export const postSupplyOrderPackingFresh = ({
  id,
  payload,
}: PostSupplyOrderPackingFreshRequestType) =>
  axios.post(`${baseUrl}/api/wms/v2/supply-order/${id}/packing/item/fresh`, payload)

export const postSupplyOrderGrandValidate = ({
  id,
  payload,
}: PostSupplyOrderGrandValidateReqType) =>
  axios.post(`${baseUrl}/api/v2/supply-order-grand/${id}/packing/validate`, payload)

export const deleteSupplyOrderGrandItemDetail = ({
  id,
  detailId,
}: DeleteSupplyOrderGrandItemDetailReqType) =>
  axios.delete(`${baseUrl}/api/v2/supply-order-grand/${id}/item-detail/${detailId}`)

export const deleteSupplyOrderItemDetailV2 = ({
  id,
  detailId,
}: DeleteSupplyOrderItemDetailV2ReqType) =>
  axios.delete(`${baseUrl}/api/wms/v2/supply-order/${id}/item-detail/${detailId}`)

export const postSupplyOrderGrandPackingItem = ({
  id,
  payload,
}: PostSupplyOrderGrandPackingItemReqType) =>
  axios.post(`${baseUrl}/api/v2/supply-order-grand/${id}/packing/item`, payload)

export const postSupplyOrderPackingItem = ({ id, payload }: PostSupplyOrderPackingItemReqType) =>
  axios.post(`${baseUrl}/api/wms/v2/supply-order/${id}/packing/item`, payload)

export const postSupplyOrderGrandPackingPublish = (id: string) =>
  axios.post(`${baseUrl}/api/wms/v2/supply-order/${id}/packing`)

export const getStatus = () => axios.get(`${baseUrl}/api/inventory-status`)

export const getNotes = () => axios.get(`${baseUrl}/api/inventory-status-notes`)

export const getSupplyOrderGrandKoliLabel = (id: string) =>
  axios.get(`${baseUrl}/api/v2/supply-order-grand/${id}/koli-label`)

export const getProgressPicking = (id: string) =>
  axios.get(`${baseUrl}/api/supply-order-grand/${id}/in-progress-picking`)

export const getSupplyOrderGrandType = (
  locationId: number,
  type: 'AUTH_OUTBOUND_CHECKER' | 'AUTH_OUTBOUND_PACKER',
) => axios.get(`${baseUrl}/api/staff-management/staff/${locationId}/${type}`)

export const postAssignCheckerPackerSO = (
  soId: number,
  payload: PostAssignCheckerPackerRequestType['payload'],
) => axios.post(`${baseUrl}/api/wms/v2/supply-order/${soId}/checker-packer`, payload)

export const putAssignCheckerSO = (
  soId: number,
  payload: PutAssignCheckerSORequestType['payload'],
) => axios.put(`${baseUrl}/api/wms/v2/supply-order/${soId}/checker`, payload)

export const putAssignPackerSO = (soId: number, payload: PutAssignPackerSORequestType['payload']) =>
  axios.put(`${baseUrl}/api/wms/v2/supply-order/${soId}/packer`, payload)

export const getScanManualReason = () =>
  axios.get(`${baseUrl}/api/supply-order-grand/manual-reason`)
