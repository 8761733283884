/* eslint-disable @typescript-eslint/naming-convention */
import AxiosFile from 'config/apiServiceFile'

export type FileVariantIdType = 'banner-animation'

export type FileRulesType = {
  typeId: FileVariantIdType
  maxFileSize: number
  extensions: string[]
}
type GetFileRulesResponseType = {
  data: FileRulesType
  error: {
    status: boolean
    msg: string
    code: number
  }
}

type PostFileRequestType = {
  typeId: FileVariantIdType
  data: { file_input: File }
}

type PostFileResponseType = {
  data: {
    filePath: string
    fileName: string
    fileUrl: string
  }
  error: {
    status: boolean
    msg: string
    code: number
  }
}

const createFormData = (payload: Record<string, unknown>) => {
  const formData = new FormData()
  Object.entries(payload).forEach((each) => {
    const [key, value] = each
    formData.append(key, value as string | Blob)
  })
  return formData
}

export const getFileRules = (typeId: FileVariantIdType) =>
  AxiosFile.Get<GetFileRulesResponseType, true>({
    url: `/internal/v1/${typeId}/rules`,
  })

export const postFile = ({ data, typeId }: PostFileRequestType) => {
  const formData = createFormData(data)

  return AxiosFile.Post<PostFileResponseType, true>({
    url: `/internal/v1/${typeId}`,
    data: formData,
    config: {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  })
}
