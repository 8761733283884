import { createAsyncThunk } from '@reduxjs/toolkit'

import {
  getLocationHub,
  getLocationWarehouse,
  getSLOCList,
  getSLOCRackEnvironment,
  getSLOCRackLocation,
  postAddSLOC,
  getProductTypes,
} from 'utils/apiList/stockOpname'

import { SOMETHING_WHEN_WRONG } from 'constant/errorMessages'

import { callErrorMsg } from 'helpers/errorMsg'

const SLICE_NAME = 'stockOpnameCheckList'

export const handleTest = () => 12

export const fetchHubLocationsList = createAsyncThunk(
  `${SLICE_NAME}/fetchStockOpnameListWarehouseLocationList`,
  async (_, { getState, rejectWithValue }) => {
    try {
      const { userData } = (getState() as StoreStateType).auth
      const res = await getLocationHub()

      if (userData.isSuperAdmin) return res.data

      const listLocationId = getAccessibleLocationIdByType(userData.location_roles, 'HUB')
      return res.data.filter((item) => listLocationId.includes(item.location_id))
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

type LocationRoleType = {
  location_id: number
  location_name: string
  location_type: string
  role_names: string[]
}

const getAccessibleLocationIdByType = (
  locationRoles: LocationRoleType[],
  type: 'HUB' | 'WAREHOUSE',
) =>
  locationRoles
    .filter((item: LocationRoleType) => item.location_type === type)
    ?.map((item: LocationRoleType) => item.location_id)

export const fetchWarehouseLocationsList = createAsyncThunk(
  `${SLICE_NAME}/fetchStockOpnameListHubLocationList`,
  async (_, { getState, rejectWithValue }) => {
    try {
      const { userData } = (getState() as StoreStateType).auth
      const res = await getLocationWarehouse()

      if (userData.isSuperAdmin) return res.data

      const listLocationId = getAccessibleLocationIdByType(userData.location_roles, 'WAREHOUSE')
      return res.data.filter((item) => listLocationId.includes(item.location_id))
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const fetchSLOCRackEnvironment = createAsyncThunk(
  `${SLICE_NAME}/fetchSLOCRackEnvironment`,
  async (_, { rejectWithValue }) => {
    try {
      const res = await getSLOCRackEnvironment()

      return res.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const fetchSLOCRackLocation = createAsyncThunk(
  `${SLICE_NAME}/fetchSLOCRackLocation`,
  async (_, { rejectWithValue }) => {
    try {
      const res = await getSLOCRackLocation()

      return res.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const fetchSLOCRackList = createAsyncThunk(
  `${SLICE_NAME}/fetchSLOCRackList`,
  async (
    {
      pageIndex,
      storageTypes,
      storageEnvironment,
      rackName,
    }: { pageIndex: number; storageEnvironment?: number; storageTypes?: string; rackName?: string },
    { rejectWithValue, getState },
  ) => {
    const {
      stockOpnameAdd: {
        query: { locationId },
      },
    } = getState() as StoreStateType
    try {
      const pageSize = 10
      if (locationId !== 0 && locationId) {
        const res = await getSLOCList(
          pageIndex,
          pageSize,
          locationId,
          rackName,
          storageTypes,
          String(storageEnvironment || ``),
        )

        return res.data
      }

      return null
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const postAddSloc = createAsyncThunk(
  `${SLICE_NAME}/postAddSloc`,
  async (_, { rejectWithValue, getState }) => {
    const {
      stockOpnameAdd: {
        query: { locationId },
        submitParam: { taskGenerated, locationType, time, date, SLOCConfiguration },
        selectedSlocList,
        rackLocation,
        productType,
      },
    } = getState() as StoreStateType

    let selectedIds: number[] = []

    if (SLOCConfiguration === `rack`) {
      selectedIds = selectedSlocList.map((d) => d.id)
    }

    const param = {
      locationType: locationType.toUpperCase(),
      locationId,
      startDateAt: date,
      startTimeAt: time,
      selectedType: SLOCConfiguration.toUpperCase(),
      selectedIds,
      taskGenerated,
      rackStorageType: rackLocation.selectedDataList.map((item) => item.rackStorageId),
      productTypes: productType.selectedDataList.map((item) => item.product_type_id),
    }

    if (SLOCConfiguration === `rack` && taskGenerated > selectedIds.length) {
      return rejectWithValue(`Maaf, Jumlah Task harus lebih kecil daripada jumlah SLOC`)
    }
    try {
      const res = await postAddSLOC(param)
      return res.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const fetchProductType = createAsyncThunk(
  `${SLICE_NAME}/fetchProductType`,
  async (_, { rejectWithValue }) => {
    try {
      const {
        data: { data },
      } = await getProductTypes()
      return data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)
