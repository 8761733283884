import { type VendorType } from 'features/Enterprise/Vendor/services/addEditVendor'

export const supplierSchedule = [
  {
    label: 'Senin',
    value: '1',
  },
  {
    label: 'Selasa',
    value: '2',
  },
  {
    label: 'Rabu',
    value: '3',
  },
  {
    label: 'Kamis',
    value: '4',
  },
  {
    label: 'Jumat',
    value: '5',
  },
  {
    label: 'Sabtu',
    value: '6',
  },
  {
    label: 'Minggu',
    value: '7',
  },
]

export const paymentTerms = [
  '10 DAYS',
  '14 DAYS',
  '15 DAYS',
  '20 DAYS',
  '21 DAYS',
  '26 DAYS',
  '28 DAYS',
  '3 DAYS',
  '30 DAYS',
  '30% -> 40% -> 30%',
  '45 DAYS',
  '5 DAYS',
  '60 DAYS',
  '7 DAYS',
  '90 DAYS',
  'CBD',
  'COD',
  'Consignment',
  'DP 50 % then 50% COD',
  'DP 50%',
]

export const vendorCategory = [
  {
    value: '1',
    label: 'Sayur & Buah',
  },
  {
    value: '2',
    label: 'Makanan Segar (Daging dan Seafood)',
  },
  {
    value: '3',
    label: 'Susu & Olahan Susu',
  },
  {
    value: '4',
    label: 'Sarapan',
  },
  {
    value: '5',
    label: 'Snack & Es Krim',
  },
  {
    value: '6',
    label: 'Minuman',
  },
  {
    value: '7',
    label: 'Alkohol dan Rokok',
  },
  {
    value: '8',
    label: 'Makanan dan Minuman Siap Makan',
  },
  {
    value: '9',
    label: 'Kebutuhan Pokok',
  },
  {
    value: '10',
    label: 'Bahan Masak dan Bumbu',
  },
  {
    value: '11',
    label: 'Tepung & Bahan Kue',
  },
  {
    value: '12',
    label: 'Makanan Beku',
  },
  {
    value: '13',
    label: 'Ibu & Bayi',
  },
  {
    value: '14',
    label: 'Vitamin',
  },
  {
    value: '15',
    label: 'Obat-obatan',
  },
  {
    value: '16',
    label: 'Alat Kesehatan',
  },
  {
    value: '17',
    label: 'Kebersihan Diri',
  },
  {
    value: '18',
    label: 'Perawatan Diri',
  },
  {
    value: '19',
    label: 'Kecantikan',
  },
  {
    value: '20',
    label: 'Perawatan Rumah',
  },
  {
    value: '21',
    label: 'Perlengkapan Hewan',
  },
  {
    value: '22',
    label: 'Elektronik',
  },
  {
    value: '23',
    label: 'Alat Tulis Kantor',
  },
  {
    value: '24',
    label: 'Fashion & Aksesoris',
  },
]

export const ppn = ['11', '12']

export const currency = ['IDR', 'USD']

export const inventoryType = [
  {
    label: 'Inventory',
    value: 'Inventory',
  },
  {
    label: 'Non-Inventory',
    value: 'Non-Inventory',
  },
]

export const vendorType = [
  {
    label: 'PT',
    value: 'PT',
  },
  {
    label: 'CV',
    value: 'CV',
  },
  {
    label: 'UD',
    value: 'UD',
  },
  {
    label: 'Individual',
    value: 'INDIVIDU',
  },
]

export const vendorStatusOptions = [
  { display: 'Menunggu Direview', key: 'PENDING_APPROVAL' },
  { display: 'Sedang Direview', key: 'REVIEWED' },
  { display: 'Menunggu Revisi', key: 'REJECTED' },
  { display: 'Dibatalkan', key: 'CANCELLED' },
]

export const vendorPicOptions = [
  { display: 'Tim Catman Lead', key: 'HEAD_CATMAN' },
  { display: 'Tim SPV Commercial', key: 'SPV_COMMERCIAL' },
  { display: 'Tim Legal', key: 'LEGAL' },
  { display: 'Tim Finance', key: 'FINANCE' },
]

export const complimentaryDocList = (type: VendorType) => [
  {
    document_type: 'ktp',
    file_name: '',
    file_path: '',
    company_id: 0,
    required: type === 'UD' || type === 'INDIVIDU' ? 1 : 0,
    label: 'KTP',
  },
  {
    document_type: 'npwp',
    file_name: '',
    file_path: '',
    company_id: 0,
    required: 1,
    label: 'NPWP',
  },
  {
    document_type: 'credit_limit',
    file_name: '',
    file_path: '',
    company_id: 0,
    required: 1,
    label: 'Limit Kredit',
  },
  {
    document_type: 'nomor_induk_usaha',
    file_name: '',
    file_path: '',
    company_id: 0,
    required: type === 'PT' || type === 'CV' ? 1 : 0,
    label: 'Nomor Induk Berusaha (NIB)',
  },
  {
    document_type: 'bank_guarantee',
    file_name: '',
    file_path: '',
    company_id: 0,
    required: 0,
    label: 'Bank Guarantee',
  },
  {
    document_type: 'account_book',
    file_name: '',
    file_path: '',
    company_id: 0,
    required: 0,
    label: 'Halaman Depan Buku Rekening',
  },
  {
    document_type: 'akta_pendirian',
    file_name: '',
    file_path: '',
    company_id: 0,
    required: type === 'PT' || type === 'CV' ? 1 : 0,
    label: 'Akta Pendirian + SK Kemenkumham',
  },
  {
    document_type: 'akta_perubahan',
    file_name: '',
    file_path: '',
    company_id: 0,
    required: 0,
    label: 'Akta Perubahan + SK Kemenkumham',
  },
  {
    document_type: 'trading_term',
    file_name: '',
    file_path: '',
    company_id: 0,
    required: 0,
    label: 'Trading Terms',
  },
  {
    document_type: 'product_license',
    file_name: '',
    file_path: '',
    company_id: 0,
    required: 0,
    label: 'Product License',
  },
]

export const initialSingleRoleForm = [
  {
    name: '',
    phone_number: '',
    email: '',
    role: 'SALES',
    form_id: window.crypto.randomUUID(),
  },
  {
    name: '',
    phone_number: '',
    email: '',
    role: 'FINANCE',
    form_id: window.crypto.randomUUID(),
  },
]

export const initialDoubleForm = [
  {
    name: '',
    phone_number: '',
    email: '',
    role: 'MULTI-ROLE',
    form_id: window.crypto.randomUUID(),
  },
]
