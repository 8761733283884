import { memo, useContext } from 'react'
import { Box, Typography } from '@astro-ui/components'
import { Col, StyledHeader, StyledRoundedCard } from '../../style'
import logo_phone from 'assets/images/icon_phone.svg'
import logo_email from 'assets/images/icon_mail.svg'
import store from 'storeContext'
import dayjs from 'dayjs'

function HeadInformation() {
  const {
    state: { dataPOLiteCreatePO },
  } = useContext(store.PrintAreaContext)

  return (
    <Box>
      <FirstRowContent data={dataPOLiteCreatePO} />
      <SecondRowContent data={dataPOLiteCreatePO} />
    </Box>
  )
}

export default memo(HeadInformation)

function FirstRowContent({ data }) {
  return (
    <Box display="flex" marginBottom="10px">
      <Col width="48">
        <StyledRoundedCard height="180px">
          <StyledHeader>SHIP TO</StyledHeader>
          <Box padding="8px 12px">
            <LocationTemplate
              corp="PT Astro Technologies Indonesia"
              address1={`${data?.destination_name} ${
                data?.destination_contact_phone ? data?.destination_contact_phone : ''
              }`}
              address2={data?.destination_address}
            />
          </Box>
        </StyledRoundedCard>
      </Col>
      <Col width="48" ml="auto">
        <StyledRoundedCard height="180px">
          <StyledHeader>PURCHASE ORDER DETAILS</StyledHeader>
          <Box padding="8px 12px">
            <LabelAndContentTemplate
              label="PO Number"
              content={data?.purchase_order_number ? data?.purchase_order_number : '-'}
            />
            <LabelAndContentTemplate
              label="Date"
              content={dayjs(data?.created_at).format('DD MMM YYYY')}
            />
            <LabelAndContentTemplate label="Created By" content={data?.created_by} />
            <LabelAndContentTemplate
              label="PO Exp Date"
              content={dayjs(data?.expiry_date).format('DD MMM YYYY')}
            />
            <LabelAndContentTemplate
              label="Est Shipping Date"
              content={dayjs(data?.request_shipping_date).format('DD MMM YYYY HH:mm')}
            />
          </Box>
        </StyledRoundedCard>
      </Col>
    </Box>
  )
}

function SecondRowContent({ data }) {
  return (
    <Box display="flex" marginBottom="10px">
      <Col width="48">
        <StyledRoundedCard height="180px">
          <StyledHeader>BILL TO</StyledHeader>
          <Box padding="8px 12px">
            <LocationTemplate
              corp="PT Astro Technologies Indonesia"
              address1={'Graha Antero Lantai 5-6'}
              address2={
                'Jl. Tomang Raya No. 27, RW 1, Kel. Tomang, Kec. Grogol Petamburan, Kota Jakarta barat, Daerah Khusus Ibukota Jakarta 11440'
              }
              isContactUs
            />
          </Box>
        </StyledRoundedCard>
      </Col>
      <Col width="48" ml="auto">
        <StyledRoundedCard height="180px">
          <StyledHeader>VENDOR DETAILS</StyledHeader>
          <Box padding="8px 12px">
            <LabelAndContentTemplate label="Vendor Name" content={data?.vendor_name} />
            <LabelAndContentTemplate label="Contact" content={`${data?.vendor_contact}`} />
            <LabelAndContentTemplate label="Payment Terms" content={data?.payment_term} />
          </Box>
        </StyledRoundedCard>
      </Col>
    </Box>
  )
}

function LabelAndContentTemplate({ label, content }) {
  return (
    <Box display="flex" justifyContent="space-between">
      <Typography variant="body2" fontWeight="700">
        {label}
      </Typography>
      <Typography variant="body2" textAlign="right">
        {content}
      </Typography>
    </Box>
  )
}

LabelAndContentTemplate.defaultProps = { label: 'Label', content: 'Content' }

function LocationTemplate({ corp, address1, address2, isContactUs }) {
  return (
    <>
      <Typography variant="body2" fontWeight="700">
        {corp}
      </Typography>
      <Typography variant="body2">{address1}</Typography>
      <Typography variant="body2">{address2}</Typography>
      {isContactUs && (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center">
            <img src={logo_phone} style={{ marginRight: '6px' }} />
            <Typography variant="body2">+6221-56958853</Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <img src={logo_email} style={{ marginRight: '6px' }} />
            <Typography variant="body2">sourcing@astronauts.id</Typography>
          </Box>
        </Box>
      )}
    </>
  )
}

LocationTemplate.defaultProps = {
  corp: false,
  address1: false,
  address2: false,
}
