/* eslint-disable @typescript-eslint/naming-convention */
import AxiosInstance from 'config/apiServiceApiGateway'

export type VendorBankType = {
  id?: number
  is_primary?: boolean
  bank_name: string
  bank_code?: string
  account_name: string
  account_number: string
  deleted?: boolean
  form_id?: string
}

export type VendorContactType = {
  deleted?: boolean
  email: string
  id?: number
  name: string
  phone_number: string
  vendor_id?: number
  role: string
  form_id?: string
  emailErrorMsg?: string
  phoneErrorMsg?: string
}

export type VendorLocationType = {
  state: string
  postal_code: string
  address: string
  country: string
  address_label: string
  city: string
  id?: number
  latitude?: string
  longitude?: string
  vendor_location_hours?: string[]
}

export type CompanyType = {
  id: number
  company_name: string
  company_code: string
  company_id?: number
}

export type DocumentType =
  | 'ktp'
  | 'npwp'
  | 'nomor_induk_usaha'
  | 'bank_guarantee'
  | 'account_book'
  | 'akta_pendirian'
  | 'akta_perubahan'
  | 'trading_term'
  | 'credit_limit'
  | 'sp_non_pkp'
  | 'pks'

export type VendorType = 'PT' | 'CV' | 'UD' | 'INDIVIDU'

export type VendorDocumentType = {
  id?: number
  file_path: string
  file_name: string
  document_type: DocumentType
  company_id?: number
  label?: string
  required?: number
  file_url?: string
}

export type VendorFormType = Omit<VendorDetailsInterface, 'vendor_locations'> & {
  state: string
  postal_code: string
  address: string
  country: string
  address_label: string
  city: string
}

export interface VendorDetailsInterface {
  active: boolean
  category_vendor: string
  company_name: string
  credit_limit: string
  currency_code: string
  email: string
  first_name: string
  id: number
  is_person: boolean
  last_name: string
  middle_name: string
  netsuite_id: number
  npwp: string
  pic_phone_number: string
  supplier_schedule: number[]
  tax_code: string
  terms: string
  user: string
  vendor_bank_accounts: VendorBankType[]
  vendor_code: string
  vendor_general_contacts: VendorContactType[]
  vendor_locations: VendorLocationType[]
  vendor_phone_number: string
  waiting_time: number
  documents: VendorDocumentType[]
  vendor_type: VendorType
  inventory_type: string
  companies: CompanyType[]
  sppkp_number: string
  nib_number: number
  ktp: number
  vendor_id: number
  cutoff_po_time: string
}

export type VendorCompanyType = {
  id: number
  company_name: string
  company_code: string
}

export type GetFullImageUrlParamsType = {
  file_path: string
  request_type: string
}

export type PostImageResponseType = {
  file_path: string
  file_url: string
  filename: string
  error: { code: number; status: boolean; message: string }
}

export interface VendorDetailRequestInterface {
  approval_pic: string
  id: number
  reason: string
  reject_pic: string
  revision: number
  status: string
  request_data: VendorDetailsInterface
}

export type RevisionInfoType = Omit<VendorDetailRequestInterface, 'request_data'>

export const getVendorDetails = (vendor_id: string) =>
  AxiosInstance.Get<VendorDetailsInterface>({
    url: `/erp/internal/v2/vendor/${vendor_id}`,
  })

export const createNewVendor = (data: VendorDetailsInterface) =>
  AxiosInstance.Post({
    url: `/erp/internal/v2/vendor`,
    data,
  })

export const putVendor = (data: VendorDetailsInterface) =>
  AxiosInstance.Put({
    url: `/erp/internal/v2/vendor`,
    data,
  })

export const getCompanies = () =>
  AxiosInstance.Get<VendorCompanyType[]>({
    url: `/erp/internal/:version/companies?page_size=99`,
    version: 'v1',
  })

export const getFullImageUrl = (params: GetFullImageUrlParamsType) =>
  AxiosInstance.Get({
    url: `/erp/internal/:version/vendor/document`,
    params,
    version: 'v2',
  })

export const uploadImage = (data: FormData) =>
  AxiosInstance.Post<PostImageResponseType>({
    url: `/erp/internal/:version/vendor/document`,
    version: 'v2',
    data,
    config: {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  })

export const postVendorRequest = (data: VendorDetailsInterface) =>
  AxiosInstance.Post({
    url: `/erp/internal/:version/vendor/submission`,
    version: 'v2',
    data,
  })

export const putVendorRequest = (data: VendorDetailsInterface) =>
  AxiosInstance.Put({
    url: `/erp/internal/:version/vendor/submission/:id`,
    version: 'v2',
    urlParams: { id: data.id },
    data,
  })

export const getVendorDetailRequest = (vendor_id: string) =>
  AxiosInstance.Get<VendorDetailRequestInterface>({
    url: `/erp/internal/:version/vendor/request/${vendor_id}`,
    version: 'v2',
  })

export const postReview = (vendor_id: number | string, data: { action: string; reason: string }) =>
  AxiosInstance.Post({
    url: `/erp/internal/:version/vendor/submission/${vendor_id}/review`,
    version: 'v2',
    data,
  })
