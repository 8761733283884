import { Get } from 'config/apiServiceConfig'

type GetOrderDetailResponseType = {
  type: string
  invoice: string
  sla: string
  grabOrderId: string
  grabBookingCode: string
  orderCreatedAt: number
  status: string
  note: string
  referenceOrder: string
  customer: {
    name: string
    phone: string
    address: string
    latitude: number
    longitude: number
    addressDetail: string
    driverNote: string
  }
  payment: {
    method: string
    name: string
    amount: number
    partner: string
    deliveryFee: number
    astroBalance: number
    totalCoinUsed: number
    totalCoinEarned: number
    totalPriceItem: number
    platformFee: {
      amount: number
      paymentFee: number
      packagingFee: number
    }
  }
  scheduledInstant: boolean
  hybrid: boolean
}

export const getOrderDetail = (id: number) =>
  Get<GetOrderDetailResponseType>({ url: `/api/v2/order/${id}` })
