import { useRef, useState, useEffect, memo } from 'react'
import styled, { css } from 'styled-components'
import debounce from 'utils/debounce'

import { SystemIcon, TextBody } from 'components'
import listenClickOutside from 'customHooks/outSideClick'
import { filterMethod } from 'helpers'
import { Button } from 'components/atoms'
import useGetAllCategories from 'customHooks/api/useGetAllCategories'

import ChevronRight from '../../../assets/images/chevron_right.svg'

const InputDropDown = ({
  className,
  width,
  label,
  color,
  mandatory,
  value,
  handleCheck,
  handleCloseOptions,
  iconName,
  selected,
  disabledDropDown,
  iconDrop,
  name,
  onSelectOptions,
  ...rest
}) => {
  const { data: rawCategory, isLoading: isLoadingRawCategory } = useGetAllCategories()
  const [mainCategory, setMainCategory] = useState([])
  const [childCategory, setChildCategory] = useState([])
  const [showList, setShowList] = useState(false)
  const [searchKey, setSearchKey] = useState('')
  const refOutside = useRef(null)

  const handleSearch = (e) => {
    const {
      target: { value },
    } = e
    setSearchKey(value)
    const go = debounce(() => {
      const filteref = filterMethod(rawCategory, value, 'category_name')
      setMainCategory(filteref)
      setChildCategory([])
    }, 500)
    go()
  }

  const handleClear = () => {
    setSearchKey('')
    setMainCategory(rawCategory)
    setChildCategory([])
    onSelectOptions(null)
  }

  const onSelectMainCategory = (data) => {
    if (data.category_childs.length) {
      const { category_childs, ...rest } = data
      setChildCategory(category_childs.map((each) => ({ ...each, parent: rest })))
      return
    }
    onSelectOptions(data)
    setShowList(false)
  }

  const onSelectChildCategory = (data) => {
    onSelectOptions(data)
    setShowList(false)
  }

  const renderDisplayText = (data) => {
    const { parent, ...rest } = data
    if (parent) {
      setSearchKey(`${parent.category_name} \u00a0 > \u00a0 ${rest.category_name}`)
      return
    }
    setSearchKey(`${rest.category_name}`)
  }

  useEffect(() => {
    if (rawCategory) {
      setMainCategory(rawCategory)
      setChildCategory([])
    }
  }, [rawCategory])

  useEffect(() => {
    if (!showList) {
      setMainCategory(rawCategory)
      setChildCategory([])
    }
  }, [showList])

  useEffect(() => {
    if (value) {
      renderDisplayText(value)
      return
    }
    setSearchKey('')
  }, [value])

  listenClickOutside(
    refOutside,
    () => {
      handleCloseOptions()
      setShowList(false)
    },
    showList,
  )

  return (
    <div className="input-drop-down" ref={refOutside}>
      {label && (
        <>
          <Label className="label-input">
            <TextBody className="mb-7" color={color}>
              {label}
            </TextBody>
          </Label>
          <Span>{mandatory}</Span>
        </>
      )}
      <Container
        className={className}
        active={showList}
        width={width}
        disabledDropDown={disabledDropDown}
      >
        <IconSearch className="mt-2">
          <SystemIcon iconName="search" colorIcon="grey" fontSize="bigger" />
        </IconSearch>
        <InputWrapper onClick={() => setShowList(true)}>
          <Input
            {...rest}
            value={searchKey}
            autoComplete="nope"
            onChange={handleSearch}
            disabledDropDown={disabledDropDown}
          />
        </InputWrapper>
        <IconWrapper>
          <Button variant="wrapper" onClick={() => setShowList(!showList)}>
            <SystemIcon iconName={iconDrop} colorIcon="grey" fontSize="smaller" />
          </Button>
        </IconWrapper>
        {/* dropDown Normal */}
        {showList && !disabledDropDown && (
          <ListContainer className="list-container">
            <WrapperContent>
              <WrapperContent maxHeight="200" overflowScroll direction="column">
                {mainCategory?.map((each) => (
                  <Items
                    key={each.category_id}
                    className="border-bottom border-right"
                    onClick={() => onSelectMainCategory(each)}
                  >
                    {each.category_name}
                    {each.category_childs.length && (
                      <img src={ChevronRight} alt={'chevron_right'} />
                    )}
                  </Items>
                ))}
              </WrapperContent>
              <WrapperContent maxHeight="200" overflowScroll direction="column">
                {childCategory?.map((each) => (
                  <Items
                    key={each.category_id}
                    className="border-bottom border-left"
                    onClick={() => onSelectChildCategory(each)}
                  >
                    {each.category_name}
                  </Items>
                ))}
              </WrapperContent>
            </WrapperContent>
            {mainCategory?.length === 0 && (
              <WrapperNoData>
                <TextBody color="secondaryText" weight="light">
                  Data tidak ditemukan
                </TextBody>
              </WrapperNoData>
            )}
            {isLoadingRawCategory && (
              <WrapperNoData>
                <TextBody color="secondaryText" weight="light">
                  Loading...
                </TextBody>
              </WrapperNoData>
            )}
          </ListContainer>
        )}
        {!showList && value && (
          <IconClear onClick={handleClear}>
            <SystemIcon colorIcon="secondaryText" iconName="plus" fontSize="smallest" />
          </IconClear>
        )}
      </Container>
    </div>
  )
}

InputDropDown.defaultProps = {
  size: 'normal',
  width: '',
  value: null,
  label: '',
  color: 'secondaryText',
  mandatory: '',
  handleCloseOptions: () => {},
  iconName: '',
  iconDrop: 'dropdown',
  onSelectOptions: () => null,
}

export default memo(InputDropDown)

const IconSearch = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
`

const ListContainer = styled.div`
  max-height: 200px;
  z-index: 2;
  overflow: hidden;
  width: fit-content;
  border: 1px solid #e4eaf3;
  min-width: 100%;
  border-radius: 6px;
  position: absolute;
  left: 0;
  top: 45px;
  bottom: auto;
  background-color: ${({ theme: { colors } }) => colors.white};
  box-shadow: 0px 2px 16px 0px rgba(202, 211, 225, 0.4);
  -webkit-box-shadow: 0px 2px 16px 0px rgba(202, 211, 225, 0.4);
  -moz-box-shadow: 0px 2px 16px 0px rgba(202, 211, 225, 0.4);
  p {
    white-space: nowrap;
  }
`

const IconWrapper = styled.div`
  transform: rotate(0deg);
  display: grid;
  place-items: center;
`

const Input = styled.input`
  background: none;
  cursor: pointer;
  border: none;
  padding: 10px 0;
  font-size: ${({ theme: { fontSizes } }) => fontSizes.normal};
  color: ${({ theme: { colors } }) => colors.textSoft};
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 20px;
  width: 100%;
  max-width: 100%;
  ${({ disabledDropDown }) =>
    disabledDropDown &&
    css`
      color: ${({ theme: { colors } }) => colors.grey};
    `}
  :focus {
    outline: none;
  }
  ::placeholder {
    opacity: 1;
    color: #aab4c8;
  }
`

const InputWrapper = styled.div`
  width: 100%;
`

const Container = styled.div`
  width: ${({ width }) => width || '-webkit-fill-available'};
  border: 1px solid ${({ theme: { colors }, active }) => (active ? colors.main : '#E4EAF3')};
  padding: 0px 12px;
  background-color: ${({ theme: { colors } }) => colors.white};
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  padding-right: 15px;
  position: relative;
  ${({ disabledDropDown }) =>
    disabledDropDown &&
    css`
      background-color: #fafafa;
    `}
`

const Label = styled.div`
  display: inline-block;
`
const Span = styled.span`
  color: #f08227;
  margin-left: 2px;
  vertical-align: middle;
`

const IconClear = styled.div`
  border: 1px solid ${({ theme: { colors } }) => colors.secondaryText};
  position: absolute;
  cursor: pointer;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  right: 33px;
  top: 14px;
  transform: rotate(45deg);
  i {
    position: absolute;
    right: 1px;
    top: -4.7px;
  }
`

const WrapperNoData = styled.div`
  padding: 10px;
  display: grid;
  place-items: center;
`

const WrapperContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: ${({ direction }) => direction || 'row'};
  .border-bottom {
    border-bottom: 1px solid #f0f3f7;
  }
  .border-left {
    border-left: 1px solid #f0f3f7;
  }
  .border-right {
    border-right: 1px solid #f0f3f7;
  }
  ${({ overflowScroll }) =>
    overflowScroll &&
    css`
      overflow-y: scroll;
    `}
  ${({ maxHeight }) =>
    maxHeight &&
    css`
      max-height: ${`${maxHeight}px`};
    `}
`

const Items = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 12px;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
  :hover {
    background-color: ${({ theme: { colors } }) => colors.tableBlue};
  }
`
