import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ProductType } from 'utils/apiList/stockOpname'
import {
  fetchHubLocationsList,
  fetchWarehouseLocationsList,
  fetchSLOCRackEnvironment,
  fetchSLOCRackLocation,
  fetchSLOCRackList,
  postAddSloc,
  fetchProductType,
} from './stockOpnameAddThunk'

export type LocationListType = {
  location_id: number
  location_name: string
  location_type: string
  name: string
}

export type RackListType = {
  rackStorageId: number
  rackStorageName: string
  rackStorageType: string
}

export type SLOCListType = {
  id: number
  locationId: number
  rackName: string
  position: number
  active: boolean
  isDeleted: boolean
  isEmpty: boolean
  storageEnvironment: { id: number; name: string }
  storageType: { id: number; name: string }
}

type StockOpnameAddStateType = {
  query: { locationId: number; locationName: string; showSelectSLOC: boolean }
  previousLocationId: number
  submitParam: {
    date: string
    taskGenerated: number
    SLOCConfiguration: string
    locationType: string
    time: string
    rackStorageType: number[]
    productTypes: number[]
  }
  rackLocation: {
    listData: RackListType[]
    previousDataList: RackListType[]
    selectedDataList: RackListType[]
  }
  productType: {
    listData: ProductType[]
    selectedDataList: ProductType[]
  }
  locationList: LocationListType[]
  rackEnvironment: RackListType[]
  slocList: SLOCListType[]
  selectedSlocList: SLOCListType[]
  slocListPaginationData: { totalElement: number; pageSize: number }
  isLoading: boolean
}

const initialState: StockOpnameAddStateType = {
  query: { locationId: 0, locationName: ``, showSelectSLOC: false },
  previousLocationId: 0,
  submitParam: {
    date: ``,
    taskGenerated: 0,
    SLOCConfiguration: ``,
    locationType: ``,
    time: ``,
    rackStorageType: [],
    productTypes: [],
  },
  selectedSlocList: [],
  locationList: [],
  rackEnvironment: [],
  rackLocation: {
    listData: [],
    previousDataList: [],
    selectedDataList: [],
  },
  productType: {
    listData: [],
    selectedDataList: [],
  },
  slocList: [],
  slocListPaginationData: { totalElement: 0, pageSize: 0 },
  isLoading: false,
}

const stockOpnameAdd = createSlice({
  name: 'stockOpnameAdd',
  initialState,
  reducers: {
    resetState: () => initialState,
    setQuery: (
      state,
      action: PayloadAction<{
        fieldName: keyof StockOpnameAddStateType['query']
        value: string | number | boolean
      }>,
    ) => {
      const { fieldName, value } = action.payload
      state.query[fieldName] = value as never
    },
    setPreviousLocationId: (state, action) => {
      state.previousLocationId = action.payload
    },
    setSelectedSLOCList: (state, action: PayloadAction<SLOCListType[]>) => {
      const selectedList = action.payload
      state.selectedSlocList = selectedList
    },
    setSubmitParam: (
      state,
      action: PayloadAction<{
        fieldName: keyof StockOpnameAddStateType['submitParam']
        value: string | number | boolean
      }>,
    ) => {
      const { fieldName, value } = action.payload
      state.submitParam[fieldName] = value as never
    },
    setRackLocation: (state, action) => {
      state.rackLocation = { ...state.rackLocation, ...action.payload }
    },
    setProductType: (state, action) => {
      state.productType = { ...state.productType, ...action.payload }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchWarehouseLocationsList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchWarehouseLocationsList.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchWarehouseLocationsList.fulfilled, (state, action) => {
        state.locationList = action.payload.map((el) => ({ ...el, name: el.location_name }))
      })
      .addCase(fetchHubLocationsList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchHubLocationsList.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchHubLocationsList.fulfilled, (state, action) => {
        state.locationList = action.payload.map((el) => ({ ...el, name: el.location_name }))
      })
      .addCase(fetchSLOCRackEnvironment.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchSLOCRackEnvironment.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchSLOCRackEnvironment.fulfilled, (state, action) => {
        state.rackEnvironment = action.payload.data.content
      })
      .addCase(fetchSLOCRackLocation.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchSLOCRackLocation.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchSLOCRackLocation.fulfilled, (state, action) => {
        state.rackLocation.listData = action.payload.data.content
      })
      .addCase(fetchSLOCRackList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchSLOCRackList.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchSLOCRackList.fulfilled, (state, action) => {
        if (action.payload) {
          state.slocList = action.payload.data
          state.slocListPaginationData = {
            totalElement: action.payload.pagination.numberOfElements,
            pageSize: action.payload.pagination.pageSize,
          }
        }
      })
      .addCase(postAddSloc.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(postAddSloc.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchProductType.fulfilled, (state, action) => {
        state.productType.listData = action.payload
      })
  },
})

export const {
  setQuery,
  setSelectedSLOCList,
  setPreviousLocationId,
  setSubmitParam,
  resetState,
  setRackLocation,
  setProductType,
} = stockOpnameAdd.actions
export default stockOpnameAdd.reducer
