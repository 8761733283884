export const mapIconColor = {
  CREATED: 'blueSoft',
  NEW: 'blueSoft',
  PICKING: 'yellow',
  'ON DELIVERY': 'red',
  DELIVERED: 'grey',
  'IN PROGRESS': 'grey',
  'Checking Quantity': 'grey',
  COMPLETED: 'green',
  'READY TO SHIP': 'green',
  'PARTIALLY FULFILLED': 'green',
  PACKING: 'yellow',
  CANCELLED: 'grey',
  Created: 'blueSoft',
  Picking: 'yellow',
  Completed: 'green',
  Done: 'green',
  Confirmed: 'grey',
  'Kitting De-Kitting': 'grey',
  LOADING: 'yellow',
  STAGING: 'yellow',
}

export const STATUS_SO = {
  CREATED: 'CREATED',
  NEW: 'NEW',
  PICKING: 'PICKING',
  'ON DELIVERY': 'ON DELIVERY',
  DELIVERED: 'DELIVERED',
  'IN PROGRESS': 'IN PROGRESS',
  COMPLETED: 'COMPLETED',
  PACKING: 'PACKING',
  'READY TO SHIP': 'READY TO SHIP',
  CANCELLED: 'CANCELLED',
  'PARTIALLY FULFILLED': 'PARTIALLY FULFILLED',
  Picking: 'Picking',
  STAGING: 'STAGING',
  LOADING: 'LOADING',
  UNLOADING: 'UNLOADING',
}

export const STATICLIST = [
  {
    name: 'Copy',
  },
  {
    name: 'Edit',
  },
  {
    name: 'Confirm',
  },
  {
    name: 'Cancel',
  },
]

export const STATICLIST_SO = [
  {
    name: 'Cetak Picking List',
    available: {
      NEW: true,
      PICKING: true,
      PACKING: true,
    },
  },
  {
    name: 'Ubah Picker',
    available: {
      PICKING: true,
      PACKING: true,
    },
  },
  {
    name: 'Cek Quantity',
    available: {
      PICKING: true,
      PACKING: true,
    },
  },
  {
    name: 'Delivery',
    available: {
      'READY TO SHIP': true,
    },
  },
  {
    name: 'Cetak Surat Jalan',
    available: {
      'READY TO SHIP': true,
      'ON DELIVERY': true,
    },
  },
  {
    name: 'Cetak Supply Order',
    available: {
      'READY TO SHIP': true,
      'ON DELIVERY': true,
      LOADING: true,
    },
  },
  {
    name: 'Mulai Loading',
    available: {
      LOADING: true,
    },
  },
  {
    name: 'Pilih Loader & Driver',
    available: {
      STAGING: true,
    },
  },
  {
    name: 'Cetak Label Koli',
    available: {
      'READY TO SHIP': true,
      'ON DELIVERY': true,
      STAGING: true,
    },
  },
]

export const STATICLIST_SO_MOBILE = [
  {
    name: 'Ambil Barang',
    available: {
      PICKING: true,
      PACKING: true,
      NEW: true,
      READY_TO_SHIP: true,
      ON_DELIVERY: true,
      DELIVERED: true,
      IN_PROGRESS: true,
    },
  },
  {
    name: 'Cek Quantity',
    available: {
      PICKING: true,
      PACKING: true,
    },
  },
  {
    name: 'Cetak Surat Jalan',
    available: {
      'READY TO SHIP': true,
    },
  },
  {
    name: 'Cetak Supply Order',
    available: {
      'READY TO SHIP': true,
      'ON DELIVERY': true,
      LOADING: true,
    },
  },
  {
    name: 'Mulai Loading',
    available: {
      LOADING: true,
    },
  },
  {
    name: 'Pilih Loader & Driver',
    available: {
      STAGING: true,
    },
  },
]

export const STATICLIST_SO_MILE_ADMIN = [
  {
    name: 'Cetak Surat Jalan',
    available: {
      'ON DELIVERY': true,
    },
  },
]

export const ATUR_SAVE_CANCEL = [
  {
    name: 'Save',
  },
  {
    name: 'Cancel',
  },
]

export const STATIC_FILTER = [
  {
    name: 'All',
    value: '',
  },
  {
    name: 'Created',
    value: 'CREATED',
  },
  {
    name: 'New',
    value: 'NEW',
  },
  {
    name: 'Picking',
    value: 'PICKING',
  },
  {
    name: 'Packing',
    value: 'PACKING',
  },
  {
    name: 'Staging',
    value: 'STAGING',
  },
  {
    name: 'Loading',
    value: 'LOADING',
  },
  {
    name: 'Ready to ship',
    value: 'READY TO SHIP',
  },
  {
    name: 'On Delivery',
    value: 'ON DELIVERY',
  },
  {
    name: 'Delivered',
    value: 'DELIVERED',
  },
  {
    name: 'In Progress',
    value: 'IN PROGRESS',
  },
  {
    name: 'Completed',
    value: 'COMPLETED',
  },
]

export const mapProductInventoryStatus = {
  Available: 'green',
  Lost: 'yellow',
  'Bad / Damage': 'red',
}

export const mapColorDot = {
  NOT_FULFILL: 'red',
  COMPLETED: 'green',
  PARTIALLY: 'yellow',
}

export const mapColorRow = {
  NOT_FULFILL: '#FFF2F2',
  COMPLETED: '#E8FFF2',
  PARTIALLY: '#FFFEF2',
}
export const STATIC_FILTER_KITTING = [
  {
    name: 'All',
    value: '',
  },
  {
    name: 'Dibuat',
    value: 'CREATED',
  },
  {
    name: 'Terkonfirmasi',
    value: 'CONFIRMED',
  },
  {
    name: 'Picking',
    value: 'PICKING',
  },
  {
    name: 'Checking Quantity',
    value: 'CHECKING QUANTITY',
  },
  {
    name: 'Kitting / De-Kitting',
    value: 'KITTING DE-KITTING',
  },
  {
    name: 'Selesai',
    value: 'COMPLETED',
  },
]

export const ACTION_KITTING = {
  Created: [
    { name: 'Confirm', val: 'confirm' },
    { name: 'Cancel', val: 'cancel' },
  ],
  Confirmed: [
    {
      name: 'Cetak Picking List',
      val: 'printPicking',
    },
    { name: 'Cancel', val: 'cancel' },
  ],
  'Checking Quantity': [
    { name: 'Check Quantity', val: 'chekQty' },
    { name: 'Cancel', val: 'cancel' },
  ],
  Picking: [
    {
      name: 'Cetak Picking List',
      val: 'printPicking',
    },
    { name: 'Check Quantity', val: 'chekQty' },
    { name: 'Cancel', val: 'cancel' },
  ],
  'Kitting De-Kitting': [{ name: 'Completed', val: 'completed' }],
}

export const STATIC_SO_APPROVAL = [
  {
    name: 'Menunggu Approval',
    val: 'WAITING_APPROVAL',
  },
  {
    name: 'Dalam Review',
    val: 'IN_REVIEW',
  },
  {
    name: 'Selesai',
    val: 'COMPLETED',
  },
  {
    name: 'Menunggu Tim Loss & Prevention',
    val: 'WAITING_LP',
  },
]
