/* eslint-disable space-before-function-paren */
import axios from 'axios'

import { removeTokenCookies, getTokenCookies, removeCookies } from 'config/cookies'
import { axiosErrorLoggerInterceptor } from 'config/apiServiceConfig/apiServicesInstanceAndInterceptors'
import { getRequestXDevice } from 'utils/helpers/userAgent'

export const requestInterceptors = () => {
  axios.interceptors.request.use(
    (config) => {
      const credentials = getTokenCookies()

      config.headers['Authorization'] = `Bearer ${credentials}`
      config.headers['Access-Control-Allow-Origin'] = 'http://localhost:3000'
      config.headers['Access-Control-Max-Age'] = 7200
      config.headers['x-device'] = getRequestXDevice()

      return config
    },
    (error) => {
      if (error?.request?.status === 401) {
        removeTokenCookies()
        window.location.href = '/'
      }
      return Promise.reject(error)
    },
  )
}

export const responseInterceptors = () => {
  axios.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      axiosErrorLoggerInterceptor(error)

      if (error?.response?.status === 401) {
        if (window.location.pathname !== '/') {
          removeCookies('auth')
          window.location.href = '/'
        }
      }
      if (error?.response?.status === 403) {
        const nextError = {
          ...error,
          response: {
            ...error.response,
            data: {
              ...error.response.data,
              error_message: 'Anda tidak memiliki akses',
            },
          },
        }
        return Promise.reject(nextError)
      }

      return Promise.reject(error)
    },
  )
}
