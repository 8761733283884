import {
  SET_DATA_LOCATIONS,
  SET_IS_LOADING_PICKER,
  SET_DATA_PAGINATION,
  SET_DATA_PICKERS,
  SET_IS_POPUP_DETAIL_SHOW,
  SET_SELECTED_PICKER,
  SET_IS_POPUP_CANCEL_SHOW,
  SET_LAST_PARAMS,
  SET_SELECTED_LOCATION,
  SET_LOCATIONS,
  SET_VISIBLE_NOTIF_SUCCESS_ASSIGN_DRIVER,
  SET_VISIBLE_MODAL_TO_ASSIGN_DRIVER,
  SET_HEAD_START_FORM,
  SET_POPUP_STATUS,
} from 'storeContext/actionsType/picker'

export const initialStatePicker = {
  isLoading: false,
  dataLocations: [],
  dataPagination: {},
  dataPickers: [],
  isPopupDetailShow: false,
  selectedPicker: {},
  isPopupCancelShow: false,
  lastParams: {},
  locations: [],
  selectedLocation: {},
  isVisibleNotifSuccessAssignDriver: false,
  isVisibleModalToAssignDriver: false,
  headStartForm: {
    captain_email: '',
    captain_password: '',
    note: '',
  },
  isPopupUpdateStatus: false,
}

export default function picker(state = initialStatePicker, { type, payload }) {
  switch (type) {
    case SET_DATA_LOCATIONS:
      return {
        ...state,
        dataLocations: payload,
      }
    case SET_IS_LOADING_PICKER:
      return {
        ...state,
        isLoading: payload,
      }
    case SET_DATA_PAGINATION:
      return {
        ...state,
        dataPagination: payload,
      }
    case SET_DATA_PICKERS:
      return {
        ...state,
        dataPickers: payload,
      }
    case SET_IS_POPUP_DETAIL_SHOW:
      return {
        ...state,
        isPopupDetailShow: payload,
      }

    case SET_VISIBLE_NOTIF_SUCCESS_ASSIGN_DRIVER:
      return {
        ...state,
        isVisibleNotifSuccessAssignDriver: payload,
      }

    case SET_VISIBLE_MODAL_TO_ASSIGN_DRIVER:
      return {
        ...state,
        isVisibleModalToAssignDriver: payload,
      }

    case SET_SELECTED_PICKER:
      return {
        ...state,
        selectedPicker: payload,
      }
    case SET_IS_POPUP_CANCEL_SHOW:
      return {
        ...state,
        isPopupCancelShow: payload,
      }
    case SET_LAST_PARAMS:
      return {
        ...state,
        lastParams: payload,
      }
    case SET_SELECTED_LOCATION:
      return {
        ...state,
        selectedLocation: payload,
      }
    case SET_LOCATIONS:
      return {
        ...state,
        locations: payload,
      }
    case SET_HEAD_START_FORM:
      return {
        ...state,
        headStartForm: payload,
      }
    case SET_POPUP_STATUS:
      return {
        ...state,
        isPopupUpdateStatus: payload,
      }
    default:
      return state
  }
}
