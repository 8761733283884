import {
  SET_DATA_LOCATIONS,
  SET_IS_LOADING_PICKER,
  SET_DATA_PAGINATION,
  SET_DATA_PICKERS,
  SET_IS_POPUP_DETAIL_SHOW,
  SET_SELECTED_PICKER,
  SET_IS_POPUP_CANCEL_SHOW,
  SET_LAST_PARAMS,
  SET_SELECTED_LOCATION,
  SET_LOCATIONS,
  SET_VISIBLE_NOTIF_SUCCESS_ASSIGN_DRIVER,
  SET_VISIBLE_MODAL_TO_ASSIGN_DRIVER,
  SET_HEAD_START_FORM,
  SET_POPUP_STATUS,
} from 'storeContext/actionsType/picker'
import { printPickListDocument } from 'utils/printFromBrowser'
import {
  getPickers,
  getPicker,
  putPicker,
  cancelPicker,
  returnPicker,
  listP1cker,
  changePicker,
  changePacker,
  getPickersSuper,
  changePackerSuper,
  changePickerSuper,
  putPickerSuper,
  putUpcomingOrder,
  listP1ckerSuper,
} from 'utils/api'
import { toastFailed, toastSuccess } from 'utils/toast'
import { getAllLocation } from 'utils/apiList/allOrder'
import { callErrorMsg } from 'helpers/errorMsg'
import { log } from 'utils/helpers/dataDogLog'

export function setLocations(payload) {
  return {
    type: SET_LOCATIONS,
    payload: payload,
  }
}
export function setSelectedLocation(payload) {
  return {
    type: SET_SELECTED_LOCATION,
    payload: payload,
  }
}
export function setLastParams(payload) {
  return {
    type: SET_LAST_PARAMS,
    payload: payload,
  }
}
export function setIsPopupCancelShow(payload) {
  return {
    type: SET_IS_POPUP_CANCEL_SHOW,
    payload: payload,
  }
}
export function setSelectedPicker(payload) {
  return {
    type: SET_SELECTED_PICKER,
    payload: payload,
  }
}
export function setIsPopupDetailShow(payload) {
  return {
    type: SET_IS_POPUP_DETAIL_SHOW,
    payload: payload,
  }
}

export function setVisibleNotifSuccessAssignDriver(payload) {
  return {
    type: SET_VISIBLE_NOTIF_SUCCESS_ASSIGN_DRIVER,
    payload: payload,
  }
}

export function setVisibleModalToAssignDriver(payload) {
  return {
    type: SET_VISIBLE_MODAL_TO_ASSIGN_DRIVER,
    payload: payload,
  }
}

export function setDataLocations(payload) {
  return {
    type: SET_DATA_LOCATIONS,
    payload: payload,
  }
}
export function setIsLoading(payload) {
  return {
    type: SET_IS_LOADING_PICKER,
    payload: payload,
  }
}
export function setDataPagination(payload) {
  return {
    type: SET_DATA_PAGINATION,
    payload: payload,
  }
}
export function setDataPicker(payload) {
  return {
    type: SET_DATA_PICKERS,
    payload: payload,
  }
}
export function setHeadStartForm(payload) {
  return {
    type: SET_HEAD_START_FORM,
    payload: payload,
  }
}

export function setIsPopupUpdateStatus(payload) {
  return {
    type: SET_POPUP_STATUS,
    payload: payload,
  }
}

const dataSorted = (dataPackers) => {
  const arrayOrderSorted = dataPackers.filter((item) => item.pool_number === null)

  let uniquePoolNumber = dataPackers
    .filter((item) => item.pool_number !== null)
    .map((item) => item.pool_number)
  uniquePoolNumber = [...new Set(uniquePoolNumber)]

  let header = uniquePoolNumber.reduce((accumulator, item) => {
    const smallestPossible = Math.min(
      ...dataPackers.filter((d) => d.pool_number == item).map((d) => d.order_id),
    )
    const el = dataPackers.find((item) => item.order_id === smallestPossible)
    return [...accumulator, el]
  }, [])
  header = header.sort((a, b) => (a.order_id < b.order_id ? -1 : 1))

  const newOrder = header.reduceRight((accumulator, head) => {
    const nearestPossible = accumulator
      .filter((order) => order.order_id < head.order_id)
      .map((item) => item.order_id)

    const beforeIndex = accumulator.findIndex(
      (order) => order.order_id === Math.max(...nearestPossible),
    )

    const currentIndex = beforeIndex + 1
    const members = dataPackers.filter(
      (member) => member.pool_number === head.pool_number && member.order_id !== head.order_id,
    )

    const pair = [head, ...members].sort((a, b) => (a.order_id < b.order_id ? -1 : 1))

    if (beforeIndex === -1) {
      accumulator.unshift(...pair)
    } else {
      accumulator.splice(currentIndex, 0, ...pair)
    }

    return accumulator
  }, arrayOrderSorted)
  return newOrder
}

export const actGetDataPickers =
  (params, isWithoutLoading, isNewApi) => async (dispatch, getState) => {
    if (!isWithoutLoading) {
      dispatch(setIsLoading(true))
    }
    const pathParts = window.location.pathname.split('/')
    const orderIndex = pathParts.indexOf('order')
    let status = orderIndex !== -1 && pathParts[orderIndex + 1] ? pathParts[orderIndex + 1] : null

    const allowedStatuses = [
      'new-order',
      'picking',
      'packing',
      'ready-for-delivery',
      'on-delivery',
      'back-to-hub',
      'waiting-confirmation',
      'upcoming-order',
    ]

    // do not call the api if status is not allowed
    if (!params.status && !allowedStatuses.includes(status)) {
      const { lastParams } = getState().statePicker
      log.info('Skipping API call due to invalid params', {
        status,
        path: window.location.pathname,
        params,
        lastParams,
      })
      dispatch(setIsLoading(false))
      return
    }

    // Use the status in your params if it's not already set
    if (!params.status && status) {
      params.status = status.replace(/-/g, ' ')
    }
    return getPickers(params)
      .then(({ data }) => {
        const newOrder = dataSorted(data?.data?.content)
        const pagination = {
          pageItem: data?.data?.pagination?.page_item,
          pageSize: data?.data?.pagination?.page_size,
          next: data?.data?.pagination?.next_cursor,
          prev: data?.data?.pagination?.previous_cursor,
          first: !data?.data?.pagination?.previous_page_exist,
          last: !data?.data?.pagination?.next_page_exist,
        }

        dispatch(setDataPagination(pagination))
        dispatch(setDataPicker(newOrder))
        dispatch(setLastParams(params))
      })
      .finally(() => {
        dispatch(setIsLoading(false))
      })
  }

export const actGetDataPickersSuper = (params, isWithoutLoading) => (dispatch) => {
  if (!isWithoutLoading) {
    dispatch(setIsLoading(true))
  }
  return getPickersSuper(params)
    .then(({ data }) => {
      const dataPickers = data?.data?.content
      const pagination = {
        pageItem: data?.data?.pagination?.page_item,
        pageSize: data?.data?.pagination?.page_size,
        next: data?.data?.pagination?.next_cursor,
        prev: data?.data?.pagination?.previous_cursor,
        first: !data?.data?.pagination?.previous_page_exist,
        last: !data?.data?.pagination?.next_page_exist,
      }

      dispatch(setDataPagination(pagination))
      dispatch(setDataPicker(dataPickers))
      dispatch(setLastParams(params))
    })
    .catch((err) => {
      callErrorMsg(err)
    })
    .finally(() => {
      dispatch(setIsLoading(false))
    })
}

export const actHandlePrint = (picker) => (dispatch) => {
  dispatch(setIsLoading(true))
  return getPicker(picker.order_id)
    .then(({ data }) => {
      dispatch(setSelectedPicker(data))
      dispatch(setIsPopupDetailShow(true))
    })
    .finally(() => {
      dispatch(setIsLoading(false))
    })
}

export const actHandleCancel = (picker) => (dispatch) => {
  dispatch(setIsLoading(true))
  return getPicker(picker.order_id)
    .then(({ data }) => {
      dispatch(setSelectedPicker(data))
      dispatch(setIsPopupCancelShow(true))
    })
    .finally(() => {
      dispatch(setIsLoading(false))
    })
}

export const actHandlePutPicker =
  (picker, payload, callbackSuccess = null, callbackFailed = null) =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsPopupDetailShow(false))
      dispatch(setIsLoading(true))
      await putPicker(picker.order_id, payload)
      const { lastParams } = getState().statePicker
      await dispatch(actGetDataPickers(lastParams))
      await dispatch({
        type: SET_VISIBLE_NOTIF_SUCCESS_ASSIGN_DRIVER,
        payload: true,
      })
      callbackSuccess && callbackSuccess()
    } catch (error) {
      callbackFailed && callbackFailed(error)
    } finally {
      dispatch(setIsLoading(false))
    }
  }

export const actHandlePutPickerSuper =
  (picker, payload, callbackSuccess = null, callbackFailed = null) =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsPopupDetailShow(false))
      dispatch(setIsLoading(true))
      await putPickerSuper(picker.order_id, payload)
      const { lastParams } = getState().statePicker
      await dispatch(actGetDataPickersSuper(lastParams))
      await dispatch({
        type: SET_VISIBLE_NOTIF_SUCCESS_ASSIGN_DRIVER,
        payload: true,
      })
      callbackSuccess && callbackSuccess()
    } catch (error) {
      callbackFailed && callbackFailed(error)
    } finally {
      dispatch(setIsLoading(false))
    }
  }

export const actHandleChangePacker =
  (packer, payload, callbackSuccess, callbackFailed) => async (dispatch) => {
    try {
      dispatch(setIsLoading(true))
      await changePacker(packer.order_id, payload)
      await dispatch({
        type: SET_VISIBLE_NOTIF_SUCCESS_ASSIGN_DRIVER,
        payload: true,
      })
      callbackSuccess()
    } catch (error) {
      callbackFailed(error)
    } finally {
      dispatch(setIsLoading(false))
    }
  }

export const actHandleChangePackerSuper =
  (packer, payload, callbackSuccess, callbackFailed) => async (dispatch) => {
    try {
      dispatch(setIsLoading(true))
      await changePackerSuper(packer.order_id, payload)
      await dispatch({
        type: SET_VISIBLE_NOTIF_SUCCESS_ASSIGN_DRIVER,
        payload: true,
      })
      callbackSuccess()
    } catch (error) {
      callbackFailed(error)
    } finally {
      dispatch(setIsLoading(false))
    }
  }

export const actHandleChangePicker =
  (picker, payload, callbackSuccess, callbackFailed) => async (dispatch) => {
    try {
      dispatch(setIsLoading(true))
      await changePicker(picker.order_id, payload)
      await dispatch({
        type: SET_VISIBLE_NOTIF_SUCCESS_ASSIGN_DRIVER,
        payload: true,
      })
      callbackSuccess()
    } catch (error) {
      callbackFailed(error)
    } finally {
      dispatch(setIsLoading(false))
    }
  }

export const actHandleChangePickerSuper =
  (picker, payload, callbackSuccess, callbackFailed) => async (dispatch) => {
    try {
      dispatch(setIsLoading(true))
      await changePickerSuper(picker.order_id, payload)
      await dispatch({
        type: SET_VISIBLE_NOTIF_SUCCESS_ASSIGN_DRIVER,
        payload: true,
      })
      callbackSuccess()
    } catch (error) {
      callbackFailed(error)
    } finally {
      dispatch(setIsLoading(false))
    }
  }

export const actHandleCancelPicker = (data, cancelExternal) => (dispatch, getState) => {
  dispatch(setIsLoading(true))
  const { lastParams, selectedPicker, selectedLocation } = getState().statePicker
  if (selectedLocation?.location_id) {
    return cancelPicker(selectedPicker.order_id, data)
      .then(() => {
        dispatch(actGetDataPickers(lastParams))
        toastSuccess(`${selectedPicker.order_invoice} berhasil dicancel`)
        dispatch(setIsPopupCancelShow(false))
        if (selectedPicker.order_third_party_logistic_used) {
          cancelExternal(selectedPicker, selectedLocation?.location_id, data.note)
        }
      })
      .catch(() => {
        toastFailed(`${selectedPicker.order_invoice} gagal dicancel`)
      })
      .finally(() => {
        dispatch(setIsLoading(false))
      })
  } else {
    toastFailed('Mohon pilih lokasi terlebih dahulu')
    dispatch(setIsLoading(false))
  }
}

export const actHandleGetLocation = (objQuery) => (dispatch, getState) => {
  const { userData } = getState().stateAuth
  return getAllLocation().then(({ data }) => {
    const formated = []
    let selectData = ''
    data.data.forEach((el) => {
      formated.push({ ...el, name: el.location_name, location_type: el.location_type })
      if (String(el.location_id) === String(objQuery.locationId)) {
        selectData = { ...el, name: el.location_name, location_type: el.location_type }
      }
    })

    if (userData?.location_roles?.length > 0) {
      let dataLocation = []
      const locations = userData.location_roles.map((d) => {
        return {
          ...d,
          name: d.location_name,
          location_type: d.location_type,
        }
      })
      locations.forEach((el) => {
        formated.map((f) => {
          if (f.location_id == el.location_id && el.location_type.toLowerCase() !== 'warehouse') {
            dataLocation.push(f)
          }
        })
      })
      let selectData = dataLocation[0]
      dispatch(setSelectedLocation(selectData))
      return dispatch(setLocations(dataLocation))
    }
    dispatch(setSelectedLocation(selectData))
    return dispatch(setLocations(formated))
  })
}

export const actHandleReturnPicker = (picker) => (dispatch, getState) => {
  dispatch(setIsLoading(true))
  return returnPicker(picker.order_id)
    .then(() => {
      const { lastParams } = getState().statePicker
      dispatch(actGetDataPickers(lastParams))
      toastSuccess('Pengembalian pesanan berhasil')
    })
    .catch(() => {
      toastFailed('Pengembalian pesanan gagal')
    })
    .finally(() => {
      dispatch(setIsLoading(false))
    })
}

export const actHandleDownloadReceipt = (picker) => (dispatch) => {
  dispatch(setIsLoading(true))
  return listP1cker(picker.order_id)
    .then(() => {
      printPickListDocument(picker)
    })
    .catch(() => {
      toastFailed('Cetak List gagal')
    })
    .finally(() => {
      dispatch(setIsLoading(false))
    })
}

export const actHandleDownloadReceiptSuper = (picker, params) => (dispatch) => {
  dispatch(setIsLoading(true))
  return listP1ckerSuper(picker.order_id, params)
    .then((res) => {
      if (res.data?.data) {
        printPickListDocument(res.data.data)
      }
    })
    .catch(() => {
      toastFailed('Cetak List gagal')
    })
    .finally(() => {
      dispatch(setIsLoading(false))
    })
}

export const actHandleSubmitUpdateStatus = (data, handleRefetch) => (dispatch, getState) => {
  dispatch(setIsLoading(true))
  const { order_id } = getState().statePicker.selectedPicker
  if (order_id) {
    return putUpcomingOrder(order_id, data)
      .then((res) => {
        toastSuccess(`Order ${order_id} berhasil di update`)
      })
      .catch((err) => {
        callErrorMsg(err)
      })
      .finally(() => {
        dispatch(setIsLoading(false))
        dispatch(setIsPopupUpdateStatus(false))
        dispatch(setSelectedPicker({}))
        handleRefetch()
      })
  }
}
