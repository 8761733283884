import {
  realStartStopRecording,
  realGetStatusRecording,
  getIsPackingLineActiveV2,
  getHubFeatureFlag,
  getFeatureFlagByLocationID,
  PackingRecordingActionValueType,
} from 'features/SupplyOrder/services/packingRecording'
import {
  setPackingRecordingOnCheckQty,
  setCountingToDetermineCameraIssues,
  resetPackingRecordingOnCheckQty,
} from 'features/SupplyOrder/redux/packingRecording/slice'
import { AppDispatchType, RootStateType } from 'store'
import { callErrorMsg } from 'helpers/errorMsg'
import { toastSuccess } from 'utils/toast'
import {
  getLocalIP,
  isPackingRecordingActiveOnLocation,
  resetPackingRecordingToOriginalState,
} from 'features/SupplyOrder/utils/helpers/packingRecording'

type ParamActStartStopRecordingType = {
  isForceStop: boolean
  isTimeoutStop: boolean
  isStop: boolean
}
export const actStartStopRecording =
  (param?: Partial<ParamActStartStopRecordingType> | undefined, callbackFunction?: () => void) =>
  async (dispatch: AppDispatchType, getState: () => RootStateType) => {
    dispatch(setPackingRecordingOnCheckQty({ isLoadingHitApiRecording: true }))
    try {
      const { packingRecordingOnCheckQty } = getState().packingRecording

      let actionValue = ''
      if (param?.isForceStop) actionValue = 'force_stop'
      else if (param?.isTimeoutStop) actionValue = 'timeout_stop'
      else if (param?.isStop || packingRecordingOnCheckQty.isRecordingStarted) actionValue = 'stop'
      else actionValue = 'start'

      const dataToSubmitV2 = {
        action: actionValue as PackingRecordingActionValueType,
        device: packingRecordingOnCheckQty.ipAddress,
        koli_id: packingRecordingOnCheckQty.koliId,
      }

      await realStartStopRecording(packingRecordingOnCheckQty.soId, dataToSubmitV2)

      if (callbackFunction) {
        callbackFunction()
        return
      }

      dispatch(
        setPackingRecordingOnCheckQty({
          expectedRecordingToStart: !packingRecordingOnCheckQty.isRecordingStarted,
          needToGetStatusRecording: true,
        }),
      )
    } catch (err) {
      const errorResponse = err as unknown as ApiErrorType

      if (errorResponse?.response?.status === 404) {
        // disini handling process normal kek biasa
        dispatch(resetPackingRecordingOnCheckQty())
        return
      }
      dispatch(
        setPackingRecordingOnCheckQty({
          expectedRecordingToStart: false,
          isLoadingHitApiRecording: false,
          needToGetStatusRecording: false,
          koliId: 0,
          koliNumber: '',
        }),
      )
      callErrorMsg(err)
    }
  }

export const actGetRecordingStatus =
  (callbackFunction: () => void) =>
  async (dispatch: AppDispatchType, getState: () => RootStateType) => {
    try {
      const { packingRecordingOnCheckQty } = getState().packingRecording
      const {
        data: { data },
      } = await realGetStatusRecording(packingRecordingOnCheckQty.soId, {
        koli_id: packingRecordingOnCheckQty.koliId,
      })

      if (data.status === 'STOPPED') {
        if (!packingRecordingOnCheckQty.expectedRecordingToStart) {
          toastSuccess(`Rekaman koli ${packingRecordingOnCheckQty.koliNumber} berhasil disimpan`)
        }
        dispatch(
          setPackingRecordingOnCheckQty({
            needToGetStatusRecording: false,
            userAbleToStopRecording: false,
            isLoadingHitApiRecording: false,
          }),
        )
        resetPackingRecordingToOriginalState()
        if (callbackFunction) {
          callbackFunction()
        }
      }
      if (
        (!packingRecordingOnCheckQty.expectedRecordingToStart && data.status === 'RUNNING') ||
        data.status === 'IDLE'
      ) {
        dispatch(
          setCountingToDetermineCameraIssues(
            packingRecordingOnCheckQty.countingToDetermineCameraIssues + 1,
          ),
        )
      } else if (data.status === 'RUNNING' && packingRecordingOnCheckQty.expectedRecordingToStart) {
        dispatch(
          setPackingRecordingOnCheckQty({
            isLoadingHitApiRecording: false,
          }),
        )
      }

      dispatch(
        setPackingRecordingOnCheckQty({
          isRecordingStarted: data.status === 'RUNNING',
        }),
      )
    } catch (err: unknown) {
      const errorResponse = err as unknown as ApiErrorType
      if (errorResponse?.response?.status === 404) {
        dispatch(resetPackingRecordingOnCheckQty())
        return
      }

      dispatch(
        setPackingRecordingOnCheckQty({
          isRecordingStarted: false,
          needToGetStatusRecording: false,
          userAbleToStopRecording: false,
          isLoadingHitApiRecording: false,
        }),
      )
      callErrorMsg(err)
    }
  }

export const actGetFeatureFlagByLocationAndPackingLine =
  (feature: string, locationId: number) => async (dispatch: AppDispatchType) => {
    let isPackingLineActive = false

    getLocalIP()
      .then((localIp) => {
        dispatch(
          setPackingRecordingOnCheckQty({
            ipAddress: localIp,
          }),
        )
        return getIsPackingLineActiveV2(locationId, localIp)
      })
      .then((responsePackingLine) => {
        isPackingLineActive = responsePackingLine.data.data.recording_enabled
        dispatch(
          setPackingRecordingOnCheckQty({
            packingLineNumber: responsePackingLine.data.data.line_number,
          }),
        )
        return getHubFeatureFlag({ feature })
      })
      .then((responseFlagAllLocation) => {
        const { flag, active } = responseFlagAllLocation.data.data
        if (flag) {
          dispatch(
            setPackingRecordingOnCheckQty({
              isFeatureFlagActiveOnLocation: flag,
              isPackingRecordingEnabled: isPackingRecordingActiveOnLocation(
                flag,
                isPackingLineActive,
              ),
            }),
          )
          return
        }
        if (!active) {
          dispatch(
            setPackingRecordingOnCheckQty({
              isFeatureFlagActiveOnLocation: active,
              isPackingRecordingEnabled: isPackingRecordingActiveOnLocation(
                active,
                isPackingLineActive,
              ),
            }),
          )
          return
        }

        // eslint-disable-next-line consistent-return
        return getFeatureFlagByLocationID(locationId)
      })
      .then((responseFlagOnLocationId) => {
        const isPackingRecordingActive =
          responseFlagOnLocationId?.data.data.features?.packing_recording || false
        dispatch(
          setPackingRecordingOnCheckQty({
            isFeatureFlagActiveOnLocation: isPackingRecordingActive,
            isPackingRecordingEnabled: isPackingRecordingActiveOnLocation(
              isPackingRecordingActive,
              isPackingLineActive,
            ),
          }),
        )
      })
      .catch((err) => {
        callErrorMsg(err)
      })
  }
