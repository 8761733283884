import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { callErrorMsg } from 'helpers/errorMsg'
import {
  getPwpData,
  getPwpType,
  getPwpProduct,
  postPwpCreate,
  putPwpEdit,
  getPwpDetail,
  putPwpEditStatus,
  postPwpBulkUpload,
  getPwpCommercialInternalSkp,
} from 'utils/apiList/promoPwp'
import { toastSuccess } from 'utils/toast'
import {
  fetchTargetedUser,
  removeTargetedUser,
  fetchSearchPWPProductTargeted,
  fetchPWPPriority,
  updatePWPPriority,
} from 'redux/pwp'

export const enumPwpRules = {
  CUSTOMER_BARU: 'CUSTOMER_BARU',
  MINIMUM_SPEND: 'MINIMUM_SPEND',
}

export const enumProductListType = {
  SKU_RELATED: 'skuRelated',
  PRODUCTS: 'products',
}

export const enumProductOnHubStatus = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
}

export const INITIAL_RULES = [
  {
    key: 'category',
    items: [],
    is_exclude: false,
    isChecked: false,
  },
]

const initialState = {
  params: {
    search: '',
    status: '',
    startDate: 0,
    endDate: 0,
    sort: 'created_at',
    direction: 'DESC',
    pageSize: 10,
    pageIndex: 0,
  },
  pagination: {
    pageSize: 10,
    currentItem: 0,
    currentPage: 0,
    totalData: 0,
    last: false,
  },
  dropdownStatus: [
    { id: 'ACTIVE', name: 'Aktif' },
    { id: 'INACTIVE', name: 'Tidak Aktif' },
  ],
  dropdownType: [],
  paramsProductList: {
    search: '',
    selectedIds: '',
    pageSize: 20,
    pageIndex: 0,
  },
  productList: [],
  productListPagination: {
    pageSize: 10,
    currentItem: 0,
    currentPage: 0,
    totalData: 0,
    last: false,
  },
  form: {
    pwpName: '',
    startDate: '',
    startTime: '',
    endDate: '',
    endTime: '',
    isAllDay: false,
    limitCampaign: 0,
    limitPerDay: 0,
    minimumSpend: 0,
    description: '',
    skuRelated: [],
    products: [],
    productForCreatePwp: [], // product selected for state not skurelated
    rules: INITIAL_RULES,
    status: 'ACTIVE',
    skp: {
      id: {
        id: 0,
        name: '',
      },
      name: '',
    },
  },
  detail: null,
  isLoading: false,
  isLoadingForm: false,
  isSubmitForm: false,
  isLoadingProductList: false,
  isLoadingBulkUpload: false,
  isRuleActive: false,
  isTargetedUser: false,
  isShowModalTargetedUser: false,
  isSkpLoadedOnEdit: false,
  data: [],
  targetedUserList: {
    data: [],
    query: {
      pageIndex: 0,
      pageSize: 20,
      totalElements: 0,
    },
  },
  isShowPWPPriority: false,
  pwpPrioritySearchProduct: {
    data: [],
    query: {
      search: '',
    },
    selected: {
      code: '',
      priority: 0,
      id: 0,
      skuNumber: '',
      uniqueKey: '',
      pwpProductId: 0,
    },
  },
  pwpPriority: [],
  skps: {},
  skpsSearch: {},
}

const normalizeVoucherRulesData = (rules) => {
  return rules.map((rule) => {
    if (rule.key === 'category') {
      const { items } = rule

      // transform attributes item to shape as CategoryType
      const transformedItems = items.map((item) => ({
        category_id: item.id,
        category_name: item.name,
      }))

      return {
        ...rule,
        items: transformedItems,
      }
    }
  })
}

const promoPwp = createSlice({
  name: 'promoPwp',
  initialState,
  reducers: {
    setIsShowModalTargetedUser: (state, action) => {
      state.isShowModalTargetedUser = action.payload
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setIsLoadingForm: (state, action) => {
      state.isLoadingForm = action.payload
    },
    setIsSubmitForm: (state, action) => {
      state.isSubmitForm = action.payload
    },
    setIsLoadingProductList: (state, action) => {
      state.isLoadingProductList = action.payload
    },
    setIsLoadingBulkUpload: (state, action) => {
      state.isLoadingBulkUpload = action.payload
    },
    setIsRuleActive: (state, action) => {
      state.isRuleActive = action.payload
    },
    setIsTargetedUser: (state, action) => {
      state.isTargetedUser = action.payload
    },
    setData: (state, action) => {
      state.data = action.payload
    },
    setDropdownType: (state, action) => {
      state.dropdownType = action.payload
    },
    setPagination: (state, action) => {
      state.pagination = action.payload
    },
    setDetail: (state, action) => {
      state.detail = action.payload
    },
    setProductListPagination: (state, action) => {
      state.productListPagination = action.payload
    },
    setParamsProductList: (state, action) => {
      state.paramsProductList = action.payload
    },
    setParams: (state, action) => {
      state.params = action.payload
    },
    setProductList: (state, action) => {
      state.productList = action.payload
    },
    setForm: (state, action) => {
      state.form = { ...state.form, ...action.payload }
    },
    setFormLocationHub: (state, action) => {
      const { indexHub, indexProduct, name, value } = action.payload
      const numValue = name === 'status' ? value : Number(value ?? 0)
      const newProductForCreatePwp = [...state.form.productForCreatePwp]
      newProductForCreatePwp[indexProduct].pwpProductQuota[indexHub][name] = numValue
      state.form = {
        ...state.form,
        productForCreatePwp: newProductForCreatePwp,
      }
    },
    setFormProduct: (state, action) => {
      const { indexProduct, name, value } = action.payload
      const numValue = Number(value)
      const newProductForCreatePwp = [...state.form.productForCreatePwp]
      newProductForCreatePwp[indexProduct][name] = numValue
      state.form = {
        ...state.form,
        productForCreatePwp: newProductForCreatePwp,
      }
    },
    setFormProductForCreatePwp: (state, action) => {
      const newProducts = state.form.products.map((i) => {
        const selected = state.form.productForCreatePwp.find((s) => s.productId === i.productId)
        return selected ? selected : i
      })

      state.form = {
        ...state.form,
        productForCreatePwp: [...newProducts],
      }
    },
    setFormRules: (state, action) => {
      state.form = {
        ...state.form,
        rules: [action.payload],
      }
    },
    setSkps: (state, action) => {
      state.skps = action.payload
    },
    setSkpsSearch: (state, action) => {
      state.skpsSearch = action.payload
      state.form.skp = initialState.form.skp
    },
    setSkpValue: (state, action) => {
      state.form.skp = action.payload
    },
    setIsSkpLoadedOnEdit: (state, action) => {
      state.isSkpLoadedOnEdit = action.payload
    },
    resetStateForm: (state) => {
      Object.assign(state, {
        detail: initialState.detail,
        dropdownType: initialState.dropdownType,
        paramsProductList: initialState.paramsProductList,
        productList: initialState.productList,
        productListPagination: initialState.productListPagination,
        form: initialState.form,
        isLoadingProductList: initialState.isLoadingProductList,
        isLoadingBulkUpload: initialState.isLoadingBulkUpload,
        isLoadingForm: initialState.isLoadingForm,
        isSubmitForm: initialState.isSubmitForm,
        isRuleActive: initialState.isRuleActive,
      })
    },
    reset: (state, _) => {
      Object.assign(state, { ...initialState })
    },
    setQueryTargetedUser: (state, { payload: { name, value } }) => {
      state.targetedUserList.query[name] = value
    },
    setIsShowPWPPriority: (state, { payload: { value } }) => {
      state.isShowPWPPriority = value
    },
    setSelectedPWPPrioritySearchProduct: (state, { payload: { value } }) => {
      state.pwpPrioritySearchProduct.selected = value
    },
    addPWPPriority: (state) => {
      state.pwpPriority.push(state.pwpPrioritySearchProduct.selected)
      state.pwpPrioritySearchProduct.selected = initialState.pwpPrioritySearchProduct.selected
    },
    removePWPPriority: (state, { payload: { value } }) => {
      state.pwpPriority = state.pwpPriority.filter((el) => el.uniqueKey !== value.uniqueKey)
    },
    setPWPPriority: (state, { payload: { value } }) => {
      state.pwpPriority = value
    },
    resetPWPPriority: (state) => {
      state.pwpPriority = initialState.pwpPriority
      state.pwpPrioritySearchProduct = initialState.pwpPrioritySearchProduct
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchTargetedUser.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchTargetedUser.fulfilled, (state, action) => {
        const { data, pagination } = action.payload
        state.isLoading = false
        state.targetedUserList.query.totalElements = pagination.totalElements
        state.targetedUserList.data = data.list
      })
      .addCase(fetchTargetedUser.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(removeTargetedUser.pending, (state) => {
        state.isLoading = true
      })
      .addCase(removeTargetedUser.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(removeTargetedUser.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchSearchPWPProductTargeted.fulfilled, (state, action) => {
        state.pwpPrioritySearchProduct.data = action.payload.data.map((el) => {
          return { ...el, name: el.fullName }
        })
      })

      .addCase(fetchPWPPriority.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchPWPPriority.fulfilled, (state, action) => {
        state.pwpPriority = action.payload.data.map((el) => {
          return {
            code: el.title,
            priority: el.order,
            id: el.id,
            skuNumber: el.skuNumber ? el.skuNumber : '',
            uniqueKey: window.crypto.randomUUID(),
            pwpProductId: el.pwpProductId,
          }
        })
        state.isLoading = false
      })
      .addCase(fetchPWPPriority.rejected, (state) => {
        state.isLoading = false
      })

      .addCase(updatePWPPriority.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updatePWPPriority.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(updatePWPPriority.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export const {
  reset,
  resetStateForm,
  setPagination,
  setIsLoading,
  setIsLoadingForm,
  setIsLoadingBulkUpload,
  setIsRuleActive,
  setFormRules,
  setData,
  setDropdownType,
  setForm,
  setParams,
  setProductList,
  setProductListPagination,
  setIsLoadingProductList,
  setParamsProductList,
  setFormLocationHub,
  setFormProduct,
  setFormProductForCreatePwp,
  setDetail,
  setIsSubmitForm,
  setIsTargetedUser,
  setIsShowModalTargetedUser,
  setQueryTargetedUser,
  setIsShowPWPPriority,
  setSelectedPWPPrioritySearchProduct,
  addPWPPriority,
  setPWPPriority,
  resetPWPPriority,
  removePWPPriority,
  setSkps,
  setSkpsSearch,
  setSkpValue,
  setIsSkpLoadedOnEdit,
} = promoPwp.actions

export const getData = createAsyncThunk(
  'promoPwp/getData',
  async (params, { dispatch, rejectWithValue }) => {
    dispatch(setIsLoading(true))
    try {
      dispatch(setPagination(initialState.pagination))

      const {
        data: {
          data,
          pagination: { pageSize, pageIndex, totalPages, totalElements, numberOfElements },
        },
      } = await getPwpData(params)
      dispatch(setData(data.list))
      dispatch(
        setPagination({
          pageSize,
          currentItem: numberOfElements,
          currentPage: pageIndex,
          totalData: totalElements,
          last: pageIndex === totalPages,
        }),
      )
    } catch (error) {
      dispatch(setData(initialState.data))
      dispatch(setPagination(initialState.pagination))
      callErrorMsg(error, 'Gagal Load Data')
      return rejectWithValue(error)
    } finally {
      dispatch(setIsLoading(false))
    }
  },
)

export const getDetail = createAsyncThunk(
  'promoPwp/getDetail',
  async ({ id, cb }, { dispatch, rejectWithValue, getState }) => {
    dispatch(setIsLoadingForm(true))
    try {
      const {
        data: {
          data: { pwp_detail },
        },
      } = await getPwpDetail(id)

      const { dropdownType } = getState().promoPwp

      const rules =
        pwp_detail?.rules?.length > 0 ? normalizeVoucherRulesData(pwp_detail?.rules) : INITIAL_RULES

      dispatch(setDetail(pwp_detail))
      dispatch(
        setForm({
          pwpName: pwp_detail.campaign_name,
          startDate: pwp_detail.start_date,
          startTime: pwp_detail.start_time,
          endDate: pwp_detail.end_date,
          endTime: pwp_detail.end_time,
          isAllDay: pwp_detail.is_all_day,
          limitCampaign: pwp_detail.limit_campaign,
          limitPerDay: pwp_detail.limit_per_day,
          minimumSpend: pwp_detail.minimum_spend,
          description: pwp_detail.description,
          status: pwp_detail.status,
          productForCreatePwp: pwp_detail.pwp_products.map((p) => ({
            productId: p.product_id,
            productName: p.product_name,
            sku: p.sku,
            discount: p.discount,
            limit: p.limit,
            pwpProductQuota: p.pwp_product_quota.map((i) => ({
              locationId: i.location_hub_id,
              locationName: i.location_name,
              quotaCampaign: i.quota_campaign ?? 0,
              quotaPerDay: i.quota_per_day ?? 0,
              stock: i.stock,
              status: i.status === 'ACTIVE' ? 'ACTIVE' : 'INACTIVE',
              price: i.price,
            })),
          })),
          products: pwp_detail.pwp_products.map((p) => ({
            productId: p.product_id,
            productName: p.product_name,
            sku: p.sku,
            discount: p.discount,
            limit: p.limit,
            pwpProductQuota: p.pwp_product_quota.map((i) => ({
              locationId: i.location_hub_id,
              locationName: i.location_name,
              quotaCampaign: i.quota_campaign ?? 0,
              quotaPerDay: i.quota_per_day ?? 0,
              stock: 0,
              status: !i.status ? 'INACTIVE' : 'ACTIVE',
              price: i.price,
            })),
          })),
          rules: rules,
          skp: {
            id: { id: pwp_detail.skp.skp_id, name: pwp_detail.skp.skp_name },
            name: pwp_detail.skp.skp_name,
          },
        }),
      )

      dispatch(setIsRuleActive(pwp_detail?.rules?.length > 0))
      dispatch(setIsTargetedUser(pwp_detail?.is_targeted))

      dispatch(
        setDropdownType([
          ...dropdownType.map((i) => ({
            ...i,
            checked:
              (i.key === enumPwpRules.CUSTOMER_BARU && pwp_detail.is_new_user) ||
              (i.key === enumPwpRules.MINIMUM_SPEND && pwp_detail.minimum_spend > 0)
                ? // todo for sku related
                  // (i.key === enumPwpRules.SKU_RELATED)
                  true
                : false,
          })),
        ]),
      )
    } catch (error) {
      callErrorMsg(error, 'Gagal Load Data Detail')
      dispatch(resetStateForm())
      cb && cb()
      return rejectWithValue(error)
    } finally {
      dispatch(setIsLoadingForm(false))
    }
  },
)

export const getType = createAsyncThunk(
  'promoPwp/getType',
  async (_, { dispatch, rejectWithValue, getState }) => {
    try {
      const { detail } = getState().promoPwp
      const {
        data: { data },
      } = await getPwpType()
      dispatch(
        setDropdownType(
          data.map((i) => ({
            checked: i.value === enumPwpRules.CUSTOMER_BARU && detail ? detail.isNewUser : false,
            childs: [],
            key: i.value,
            name: i.label,
          })),
        ),
      )
      return null
    } catch (error) {
      dispatch(setDropdownType(initialState.dropdownType))
      callErrorMsg(error, 'Gagal Load Data Type')
      return rejectWithValue(error)
    }
  },
)

export const getProduct = createAsyncThunk(
  'promoPwp/getProduct',
  async (params, { dispatch, rejectWithValue }) => {
    dispatch(setIsLoadingProductList(true))
    try {
      const {
        data: { data },
      } = await getPwpProduct(params)

      const {
        numberOfElements,
        totalElements,
        totalPages,
        size: pageSize,
        number: pageIndex,
        content,
      } = data

      dispatch(setProductList(content))
      dispatch(
        setProductListPagination({
          pageSize,
          currentItem: numberOfElements,
          currentPage: pageIndex,
          totalData: totalElements,
          last: pageIndex === totalPages,
        }),
      )
    } catch (error) {
      dispatch(setProductList(initialState.productList))
      dispatch(setProductListPagination(initialState.productListPagination))
      callErrorMsg(error, 'Gagal Load Data Product')
      return rejectWithValue(error)
    } finally {
      dispatch(setIsLoadingProductList(false))
    }
  },
)

export const bulkUpload = createAsyncThunk(
  'promoPwp/bulkUpload',
  async (params, { dispatch, rejectWithValue, getState }) => {
    dispatch(setIsLoadingBulkUpload(true))
    const { form } = getState().promoPwp
    try {
      const {
        data: {
          data: { content },
        },
      } = await postPwpBulkUpload(params)

      dispatch(
        setForm({
          ...form,
          products: [],
          productForCreatePwp: [],
        }),
      )

      setTimeout(() => {
        const products = content.map((product) => ({
          ...product,
          pwpProductQuota: [...product.details],
        }))

        dispatch(
          setForm({
            ...form,
            products: products,
            productForCreatePwp: products,
          }),
        )
      }, 500)
    } catch (error) {
      dispatch(setForm({ ...form, products: [], productForCreatePwp: [] }))
      callErrorMsg(error, 'Gagal melakukan Upload Sekaligus')
      return rejectWithValue(error)
    } finally {
      dispatch(setIsLoadingBulkUpload(false))
    }
  },
)

export const createPwp = createAsyncThunk(
  'promoPwp/createPwp',
  async ({ payload, cb }, { dispatch, rejectWithValue, getState }) => {
    dispatch(setIsLoadingForm(true))
    dispatch(setIsSubmitForm(true))
    const { form } = getState().promoPwp
    try {
      const err = checkFormValidation(form)
      if (err) throw { response: { data: { message: err } } }
      await postPwpCreate(payload)
      toastSuccess('Berhasil melakukan tambah data PWP')
      dispatch(resetStateForm())
      cb && cb()
    } catch (error) {
      callErrorMsg(error, 'Gagal melakukan tambah data PWP')
      return rejectWithValue(error)
    } finally {
      dispatch(setIsLoadingForm(false))
    }
  },
)

export const getCommercialInternalSkp = createAsyncThunk(
  'promoPwp/getCommercialInternalSkp',
  async (params = {}, { dispatch, rejectWithValue, getState }) => {
    try {
      const {
        data: { data },
      } = await getPwpCommercialInternalSkp(params)

      const { skps } = getState().promoPwp

      const newSkpsUnlisted = {}
      const skpSearch = {}

      data.forEach((el) => {
        const value = { id: el.id, name: el.skp_no }
        if (!skps[el.skp_no]) {
          newSkpsUnlisted[el.skp_no] = value
        }

        if (params.skp_no !== '') {
          skpSearch[el.skp_no] = value
        }
      })

      dispatch(setSkps({ ...newSkpsUnlisted, ...skps }))
      dispatch(setSkpsSearch(skpSearch))
      return null
    } catch (error) {
      callErrorMsg(error, 'Gagal Load Data Type')
      return rejectWithValue(error)
    }
  },
)

export const getDetailCommercialInternalSkp = createAsyncThunk(
  'promoPwp/getDetailCommercialInternalSkp',
  async (params = {}, { dispatch, rejectWithValue, getState }) => {
    try {
      const {
        skps,
        form: { skp },
      } = getState().promoPwp

      const entriesSksp = Object.entries(skps).find((el) => el[1].id === skp.id.id)

      if (!entriesSksp) {
        const {
          data: { data },
        } = await getPwpCommercialInternalSkp({
          ...params,
          campaign_name: skp.name,
          status: 'Approved',
        })

        const getDetailDataSelected = data.find((el) => el.id === skp.id.id)

        if (getDetailDataSelected) {
          dispatch(
            setSkps({
              [getDetailDataSelected.skp_no]: {
                id: getDetailDataSelected.id,
                name: getDetailDataSelected.skp_no,
              },
              ...skps,
            }),
          )
        }
      }
      dispatch(setIsSkpLoadedOnEdit(true))
      return null
    } catch (error) {
      callErrorMsg(error, 'Gagal Load Data Type')
      return rejectWithValue(error)
    }
  },
)

export const getMinPriceProductPwpOnActiveHubs = (productPwp) => {
  const productOnactivedHubs = [...productPwp.pwpProductQuota].filter(
    (i) => i?.status === enumProductOnHubStatus.ACTIVE,
  )

  return Math.min(...productOnactivedHubs.map((i) => i.price))
}

const checkFormValidation = (form) => {
  const errMessage = {
    discount100Percent: '',
    discountGtPrice: '',
    limitGtQuotaCampaign: '',
    limitGtQuota: '',
    quotaPerDayGtQuotaCampaign: '',
    quotaPerDayGtStockHub: '',
    quotaCampaignGtStockHub: '',
  }

  if (form.limitPerDay > form.limitCampaign) {
    errMessage.limitPerDayGtLimitCampaign =
      'Limit redeem per hari tidak boleh lebih besar dari limit redeem per campaign.'
  }

  form.productForCreatePwp.forEach((p) => {
    if (p.limit > 0 && p.limit > form.limitCampaign) {
      errMessage.limitPerDayGtLimitCampaign =
        'Limit penukaran produk tidak boleh lebih besar dari limit redeem per campaign.'
    }

    const minPrice = getMinPriceProductPwpOnActiveHubs(p)

    if (p.discount > 0) {
      if (minPrice < p.discount) {
        errMessage.discountGtPrice = 'Harga diskon tidak boleh melebihi harga normal'
      }
      if (minPrice === p.discount) {
        errMessage.discount100Percent = 'Harga diskon tidak boleh 100%'
      }
    }

    p.pwpProductQuota.forEach((q) => {
      if (q.quotaPerDay > 0 && q.quotaPerDay > q.quotaCampaign) {
        errMessage.quotaPerDayGtQuotaCampaign = 'Quota per hari melebihi quota campaign'
      }
    })
  })

  const messages = Object.keys(errMessage).filter((i) => errMessage[i])

  return messages.length > 0 ? (
    <ol style={{ margin: 10 }}>
      {messages.map((i, idx) => (
        <li key={`errMessagePwpForm-${idx}`}>{errMessage[i]}</li>
      ))}
    </ol>
  ) : null
}

export const updatePwp = createAsyncThunk(
  'promoPwp/updatePwp',
  async ({ id, payload, cb }, { dispatch, rejectWithValue, getState }) => {
    dispatch(setIsLoadingForm(true))
    dispatch(setIsSubmitForm(true))

    const { form } = getState().promoPwp

    try {
      const err = checkFormValidation(form)
      if (err) throw { response: { data: { message: err } } }
      await putPwpEdit(id, payload)
      toastSuccess('Berhasil melakukan edit data PWP')
      dispatch(resetStateForm())
      cb && cb()
    } catch (error) {
      callErrorMsg(error, 'Gagal melakukan edit data PWP')
      return rejectWithValue(error)
    } finally {
      dispatch(setIsLoadingForm(false))
    }
  },
)

export const updateStatusPwp = createAsyncThunk(
  'promoPwp/updateStatusPwp',
  async ({ id, payload }, { dispatch, rejectWithValue, getState }) => {
    dispatch(setIsLoadingForm(true))
    const { params } = getState().promoPwp
    try {
      await putPwpEditStatus(id, payload)
      toastSuccess('Berhasil melakukan update status PWP')
      dispatch(getData(params))
    } catch (error) {
      callErrorMsg(error, 'Gagal melakukan update status PWP')
      return rejectWithValue(error)
    } finally {
      dispatch(setIsLoadingForm(false))
    }
  },
)

export default promoPwp.reducer
