import Cookies from 'js-cookie'

const cookiesTokenName = 'zvdgxvzb'

export const getCookies = (key: string) => Cookies.get(key)

export const setCookies = (key: string, payload: string) => {
  Cookies.set(key, payload)
}

export const removeCookies = (key: string) => {
  Cookies.remove(key)
}

export const setTokenCookies = (payload: string) => {
  setCookies(cookiesTokenName, payload)
}

export const getTokenCookies = () => getCookies(cookiesTokenName)

export const removeTokenCookies = () => removeCookies(cookiesTokenName)
