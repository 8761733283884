export const getNeededAttributeFromQueryObj = (queryObj) => {
  let newQueryObj = {};
  const listKeys = Object.keys(queryObj);
  for (let key of listKeys) {
    if (queryObj[key] !== null) {
      newQueryObj = { ...newQueryObj, [key]: queryObj[key] };
    }
  }
  return newQueryObj;
};
