import { memo } from 'react'
import styled from 'styled-components'
import { SystemIcon, Button } from 'components'
const PaginationCursor = ({ data, handleChangePage, className }) => {
  const { first, last } = data
  return (
    <Container className={className}>
      <>
        <ButtonWrapper>
          {/*Hide previous button if first is true*/}
          {!first && (
            <Button
              variant="wrapper"
              onClick={() => {
                if (first && last) return false
                /** if the last is true then we can not go next. only if last is false we allow to paginate */
                handleChangePage('prev')
              }}
            >
              <PagiButton type="prev">
                <SystemIcon iconName="dropdown" />
              </PagiButton>
            </Button>
          )}
          {!last && (
            <Button
              variant="wrapper"
              onClick={() => {
                /** User can not go next if first is true and last is true */
                if (first && last) return false
                /** if the last is true then we can not go next. only if last is false we allow to paginate */
                handleChangePage('next')
              }}
            >
              <PagiButton>
                <SystemIcon iconName="dropdown" />
              </PagiButton>
            </Button>
          )}
        </ButtonWrapper>
      </>
    </Container>
  )
}

PaginationCursor.defaultProps = {
  data: {},
  handleChangePage: () => {},
}

export default memo(PaginationCursor)

const Container = styled.div`
  display: flex;
  align-items: center;
`

const ButtonWrapper = styled.div`
  margin-left: 16px;
  display: flex;
  gap: 8px;
`

const PagiButton = styled.div`
  cursor: pointer;
  width: fit-content;
  height: fit-content;
  padding: 9px 10px;
  background-color: ${({ theme: { colors } }) => colors.white};
  border-radius: 6px;
  box-shadow: 2px 4px 20px 0px rgba(229, 236, 240, 1);
  transform: ${({ type }) => (type === 'prev' ? 'rotate(90deg)' : 'rotate(-90deg)')};
`
