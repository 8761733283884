import { ActionReducerMapBuilder, createSlice, type PayloadAction } from '@reduxjs/toolkit'

import type {
  GetDetailPageIDResponseType,
  GetPagePermissionOptionsResponseType,
} from 'features/Account/@types/adminAccessPageIDList'
import { withLoadingReducer } from 'utils/reducerHandler'
import {
  fetchGetPermissionOptionsPageID,
  fetchGetDetailPageID,
  fetchPostPageID,
  fetchPutPageID,
  SLICE_NAME,
} from './thunk'

export type AdminAccessAddEditPageIDSliceType = {
  isLoading: boolean
  constants: {
    permissionOptions: GetPagePermissionOptionsResponseType['permission_option']
  }
  pageName: string
  isMainMenu: boolean
  permission: {
    id?: number
    name: string
    customName: string
    elements: {
      id?: number
      elementId?: string
      uniqueKey: string
      name: string
    }[]
    endpoints: {
      id?: number
      uniqueKey: string
      method: string
      endpoint: string
      error?: string
    }[]
    uniqueKey: string
    showChild: boolean
  }[]
  detail: Nullable<GetDetailPageIDResponseType>
}

const elementsInit = {
  uniqueKey: window.crypto.randomUUID(),
  name: '',
}

const endpointsInit = {
  uniqueKey: window.crypto.randomUUID(),
  method: '',
  endpoint: '',
}

const permissionsInit = {
  uniqueKey: window.crypto.randomUUID(),
  showChild: true,
  name: '',
  customName: '',
  elements: [elementsInit],
  endpoints: [endpointsInit],
}

const initialState: AdminAccessAddEditPageIDSliceType = {
  isLoading: false,
  constants: {
    permissionOptions: [],
  },
  pageName: '',
  isMainMenu: false,
  permission: [permissionsInit],
  detail: null,
}

const adminAccessAddEditPageIDSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset: () => initialState,
    setPageName: (state, action: PayloadAction<string>) => {
      state.pageName = action.payload
    },
    setIsMainMenu: (state, action: PayloadAction<boolean>) => {
      state.isMainMenu = action.payload
    },
    setShowChildPermission: (
      state,
      action: PayloadAction<{
        permissionIndex: number
        isShow: boolean
      }>,
    ) => {
      const { permissionIndex, isShow } = action.payload
      state.permission[permissionIndex].showChild = isShow
    },
    setAddPermission: (state) => {
      state.permission.push(permissionsInit)
    },
    setAddElement: (
      state,
      action: PayloadAction<{
        permissionIndex: number
      }>,
    ) => {
      const { permissionIndex } = action.payload

      state.permission[permissionIndex].elements.push(elementsInit)
    },
    setAddEndpoint: (
      state,
      action: PayloadAction<{
        permissionIndex: number
      }>,
    ) => {
      const { permissionIndex } = action.payload

      state.permission[permissionIndex].endpoints.push(endpointsInit)
    },
    setDeletePermission: (
      state,
      action: PayloadAction<{
        permissionIndex: number
      }>,
    ) => {
      const { permissionIndex } = action.payload

      state.permission = state.permission.filter((_, index) => index !== permissionIndex)
    },
    setDeleteElement: (
      state,
      action: PayloadAction<{
        permissionIndex: number
        elementIndex: number
      }>,
    ) => {
      const { permissionIndex, elementIndex } = action.payload

      state.permission[permissionIndex].elements = state.permission[
        permissionIndex
      ].elements.filter((_, index) => index !== elementIndex)
    },
    setDeleteEndpoint: (
      state,
      action: PayloadAction<{
        permissionIndex: number
        endpointIndex: number
      }>,
    ) => {
      const { permissionIndex, endpointIndex } = action.payload

      state.permission[permissionIndex].endpoints = state.permission[
        permissionIndex
      ].endpoints.filter((_, index) => index !== endpointIndex)

      if (state.permission[permissionIndex].endpoints[endpointIndex]?.error) {
        state.permission[permissionIndex].endpoints[endpointIndex].error = ''
      }
    },
    setChangePermission: (
      state,
      action: PayloadAction<{
        key: 'name' | 'customName'
        value: never
        permissionIndex: number
      }>,
    ) => {
      const { key, value, permissionIndex } = action.payload

      state.permission[permissionIndex][key] = value
    },
    setChangeElement: (
      state,
      action: PayloadAction<{
        permissionIndex: number
        elementIndex: number
        value: never
      }>,
    ) => {
      const { permissionIndex, elementIndex, value } = action.payload

      state.permission[permissionIndex].elements[elementIndex].name = value
    },
    setChangeEndpoint: (
      state,
      action: PayloadAction<{
        permissionIndex: number
        endpointIndex: number
        key: 'method' | 'endpoint'
        value: never
      }>,
    ) => {
      const { permissionIndex, endpointIndex, key, value } = action.payload

      state.permission[permissionIndex].endpoints[endpointIndex][key] = value
    },
    setErrorEndpoint: (
      state,
      action: PayloadAction<{
        permissionIndex: number
        endpointIndex: number
        error: string
      }>,
    ) => {
      const { permissionIndex, endpointIndex, error } = action.payload

      state.permission[permissionIndex].endpoints[endpointIndex].error = error
    },
  },
  extraReducers: withLoadingReducer(
    (builder: ActionReducerMapBuilder<AdminAccessAddEditPageIDSliceType>) => {
      builder
        .addCase(fetchGetPermissionOptionsPageID.fulfilled, (state, action) => {
          state.constants.permissionOptions = action.payload.permission_option
        })
        .addCase(fetchGetDetailPageID.fulfilled, (state, action) => {
          const { page, permissions } = action.payload

          state.detail = action.payload

          state.pageName = page.name
          state.isMainMenu = page.is_main_menu
          state.permission = permissions.map((item) => ({
            id: item.id,
            customName: item.custom_name,
            name: item.name,
            elements: item.elements.map((child) => ({
              id: child.id,
              elementId: child.element_id,
              name: child.name,
              uniqueKey: window.crypto.randomUUID(),
            })),
            endpoints: item.endpoints.map((child) => ({
              id: child.id,
              endpoint: child.endpoint,
              method: child.method,
              uniqueKey: window.crypto.randomUUID(),
            })),
            showChild: true,
            uniqueKey: window.crypto.randomUUID(),
          }))
        })
    },
    [fetchGetDetailPageID, fetchPostPageID, fetchPutPageID],
  ),
})

export const {
  reset,
  setPageName,
  setIsMainMenu,
  setShowChildPermission,
  setAddElement,
  setAddEndpoint,
  setAddPermission,
  setDeleteElement,
  setDeleteEndpoint,
  setDeletePermission,
  setChangePermission,
  setChangeElement,
  setChangeEndpoint,
  setErrorEndpoint,
} = adminAccessAddEditPageIDSlice.actions

export default adminAccessAddEditPageIDSlice.reducer
