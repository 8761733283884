import { AxiosRequestConfig, AxiosProgressEvent } from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { callErrorMsg } from 'helpers/errorMsg'
import { uploadStatus } from 'features/Rack/utils/constant'
import {
  getLocationList,
  getRackList,
  getRackDetailByLocationId,
  getRacksExport,
  GetRackDetailByLocationIdParamsType,
  putActivateDeactivateRack,
  postRackImport,
  deleteRack,
  getRackCsvTemplate,
} from 'features/Rack/services/rackList'
import { toastSuccess } from 'utils/toast'
import {
  setRack,
  setRackDetail,
  setLocation,
  setIsLoading,
  setModalBulkUpload,
  resetModalBulkUpload,
  resetModalDelete,
  SLICE_NAME,
} from './slice'

export const fetchLocationList = createAsyncThunk<void>(
  `${SLICE_NAME}/fetchLocationList`,
  async (_, { dispatch, getState }) => {
    const {
      rack: {
        location: { queryParams },
      },
    } = getState() as StoreStateType
    try {
      const { data } = await getLocationList(queryParams)
      dispatch(setLocation({ listData: data.content }))
    } catch (err) {
      callErrorMsg(err)
    }
  },
)

export const fetchRackList = createAsyncThunk<void>(
  `${SLICE_NAME}/fetchRackList`,
  async (_, { dispatch, getState }) => {
    const {
      rack: { pageIndex },
      location: { selectedLocation },
    } = (getState() as StoreStateType).rack

    const queryParams = {
      pageIndex,
      locationName: selectedLocation?.location_name,
    }
    try {
      const {
        data: { data },
      } = await getRackList(queryParams)
      dispatch(setRack({ listData: data.content }))
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

type ExportRackDetailParamsType = {
  locationId: number
  params?: GetRackDetailByLocationIdParamsType
}

export const exportRackDetail = createAsyncThunk<void, ExportRackDetailParamsType>(
  `${SLICE_NAME}/exportRackDetail`,
  async (exportParam, { dispatch }) => {
    const { locationId, params } = exportParam
    dispatch(setIsLoading(true))
    try {
      const { data } = await getRacksExport(locationId, params)
      const dataCsv = `data:text/csv;charset=utf-8,${data}`
      const encodedUri = encodeURI(dataCsv)
      const link = document.createElement('a')
      link.setAttribute('href', encodedUri)
      link.setAttribute('download', `${new Date()}-racks.csv`)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      dispatch(setIsLoading(false))
    } catch (error) {
      dispatch(setIsLoading(false))
      callErrorMsg(error)
    }
  },
)

export const fetchRackDetailByLocationId = createAsyncThunk<void, number>(
  `${SLICE_NAME}/fetchRackDetailByLocationId`,
  async (locationId, { dispatch, getState }) => {
    const {
      rackDetail: { params },
    } = (getState() as StoreStateType).rack

    try {
      const {
        data: { data },
      } = await getRackDetailByLocationId(locationId, params)
      const newList = data.racks.content.filter((item) => item !== null)
      dispatch(
        setRackDetail({
          location: data.location,
          listData: newList,
          numberOfElements: data.racks.numberOfElements,
        }),
      )
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const activateDeactivateRack = createAsyncThunk<void, number>(
  `${SLICE_NAME}/activateDeactivateRack`,
  async (rackId, { dispatch, getState }) => {
    const {
      rackDetail: { location },
    } = (getState() as StoreStateType).rack
    try {
      await putActivateDeactivateRack(rackId)
      dispatch(fetchRackDetailByLocationId(location.location_id))
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

const getTemplate = (
  statusResponse: string,
  data: { urlDownload: string; rackTotal: number; rackFailed: number; rackSuccess: number },
) => {
  let status = ''

  if (statusResponse === 'success') status = uploadStatus.ON_UPLOAD_SUCCESS
  else if (statusResponse === 'failed') status = uploadStatus.ON_UPLOAD_FAILED
  else if (statusResponse === 'partially success') status = uploadStatus.ON_UPLOAD_SUCCESS_PARTIAL

  return {
    status,
    url_download: data.urlDownload,
    data_count: {
      total_data: data.rackTotal,
      failed_data: data.rackFailed,
      success_data: data.rackSuccess,
    },
  }
}

export const actDeleteRack = createAsyncThunk<void>(
  `${SLICE_NAME}/actDeleteRack`,
  async (_, { dispatch, getState }) => {
    const {
      rackDetail: { location },
      modalDelete,
    } = (getState() as StoreStateType).rack
    try {
      await deleteRack(location.location_id, { id: modalDelete.idToDelete.toString() })
      dispatch(resetModalDelete())
      toastSuccess('Rak berhasil dihapus')
      dispatch(fetchRackDetailByLocationId(location.location_id))
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const actRackImport =
  (dataToSubmit: FormData, setProgress: (value: string) => void) =>
  async (dispatch: StoreDispatchType, getState: () => StoreStateType) => {
    const {
      modalBulkUpload,
      rackDetail: { location },
    } = getState().rack
    const config: AxiosRequestConfig = {
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent: AxiosProgressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / (progressEvent?.total || 0),
        )
        setProgress(percentCompleted === 100 ? 'Upload is done' : `Uploading ${percentCompleted}%`)
      },
    }

    try {
      const {
        data: { data },
      } = await postRackImport(dataToSubmit, config)

      const status = data.status.toLowerCase()
      dispatch(
        setModalBulkUpload({
          fileCSV: { ...modalBulkUpload.fileCSV, ...getTemplate(status, data) },
        }),
      )

      if (status === 'success') {
        dispatch(resetModalBulkUpload())
        dispatch(fetchRackDetailByLocationId(location.location_id))
      }
    } catch (err) {
      callErrorMsg(err)
      dispatch(
        setModalBulkUpload({
          fileCSV: { ...modalBulkUpload.fileCSV, status: uploadStatus.ON_CHOOSE_FILE },
        }),
      )
    }
  }

export const fetchRackCsvTemplate = createAsyncThunk<void>(
  `${SLICE_NAME}/fetchRackCsvTemplate`,
  async (_, { dispatch }) => {
    try {
      const {
        data: { data },
      } = await getRackCsvTemplate()
      dispatch(setModalBulkUpload({ templateUrl: data.url }))
    } catch (error) {
      callErrorMsg(error)
    }
  },
)
