import apiServices from 'config/apiServiceApiGateway'
import type {
  GetWatchTowerRuleV3ResponseType,
  PutWatchtowerStatusRequestType,
  GetWatchTowerRuleV3DetailResponseType,
  GetWatchTowerRuleV3DetailRequestType,
  PostWatchtowerRuleRequestType,
  GetWatchTowerRuleV3RequestType,
  PutWatchtowerRuleRequestType,
} from 'features/Fraud/@types/watchtowerRule'

export const getWatchTowerRuleV3 = ({ params }: GetWatchTowerRuleV3RequestType) =>
  apiServices.Get<GetWatchTowerRuleV3ResponseType>({
    url: `/fraud/internal/v2/watchtower/rule`,
    params,
  })

export const putWatchtowerStatus = ({ id, payload }: PutWatchtowerStatusRequestType) =>
  apiServices.Patch({
    url: `/fraud/internal/v2/watchtower/rule/${id}/status`,
    data: payload,
  })

export const getWatchTowerRuleV3Detail = ({ id }: GetWatchTowerRuleV3DetailRequestType) =>
  apiServices.Get<GetWatchTowerRuleV3DetailResponseType>({
    url: `/fraud/internal/v2/watchtower/rule/${id}`,
  })

export const postWatchtowerRules = ({ payload }: PostWatchtowerRuleRequestType) =>
  apiServices.Post({
    url: `/fraud/internal/v2/watchtower/rule`,
    data: payload,
  })

export const putWatchtowerRule = ({ id, payload }: PutWatchtowerRuleRequestType) =>
  apiServices.Put({
    url: `/fraud/internal/v2/watchtower/rule/${id}`,
    data: payload,
  })
